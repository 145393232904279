import { MiniBook } from '@shared/domain/orderBook';
import api from '@shared/services/api';
import { gateioItemToBookItem } from '@shared/domain/gateio';

export type TickerResponse = {
  symbol: CurrencyPair,
  price: CurrencyAmount,
};

export const getTicker = (
  base: Currency,
  counter: Currency,
): Promise<TickerResponse> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent(`https://api.gateio.ws/api/v4/spot/tickers?currency_pair=${base}_${counter}`)}`)
    .then(([{ currency_pair, last }]) => ({
      symbol: currency_pair,
      price: last,
    }))
);

export const getOrderBook = ({
  base,
  counter,
}: {
  base: Currency,
  counter: Currency,
}): Promise<MiniBook> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent(`https://api.gateio.ws/api/v4/spot/order_book?currency_pair=${base}_${counter}&limit=20`)}`).then((book) => ({
    asks: book.asks.map(gateioItemToBookItem),
    bids: book.bids.map(gateioItemToBookItem),
  }))
);
