import { BinanceBookOrder, binanceItemToBookItem } from '@shared/domain/binance';
import { BinanceClient } from './binanceClient';

export type UpdateId = number;

export type StreamWrapper<T> = {
  stream: string,
  data: T,
};

export type TickerMessage = {
  s: string; // Pair
  e: string, // Event type
  c: string, // Close price
};

export type DepthMessage = {
  asks: BinanceBookOrder[],
  bids: BinanceBookOrder[],
  lastUpdateId: UpdateId
};

export type Message = StreamWrapper<DepthMessage> | StreamWrapper<TickerMessage>;

export class BinanceSpotClient extends BinanceClient<Message> {
  constructor() {
    super({ url: 'wss://stream.binance.com:9443/stream' });
  }

  handleMessage(message: Message) {
    super.handleMessage(message);

    if ('id' in message) {
      // Subscription success messages
      return;
    }

    if (message.stream?.includes('miniTicker')) {
      const { data } = message as StreamWrapper<TickerMessage>;
      // Ticker message
      this.socket.subscriptions[message.stream]?.callbacks.map(
        (subscription) => subscription(Number(data.c)),
      );
      return;
    }

    if (message.stream.includes('depth')) {
      const { data } = message as StreamWrapper<DepthMessage>;
      // Book depth snapshot messages
      this.socket.subscriptions[message.stream]?.callbacks.map((subscription) => subscription({
        asks: data.asks.map(binanceItemToBookItem),
        bids: data.bids.map(binanceItemToBookItem),
      }));
    }
  }
}
