import * as React from 'react';
import { ExchangeAccount, accountColor } from '@shared/domain/account';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

type Align = 'right' | 'bottom';

const StyledAccountType = styled('span')<{ align: Align }>(({ theme, align }) => ({
  display: align === 'right' ? 'inline' : 'block',
  marginLeft: align === 'right' ? theme.spacing(0.5) : 0,
}));

interface ExchangeAccountProps {
  value: ExchangeAccount,
  align?: Align,
}

const ExchangeAccountLabel: React.FC<ExchangeAccountProps & TypographyProps> = ({
  value,
  align,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Typography
      variant="inherit"
      color={accountColor[value]}
      {...props}
    >
      {t(`shared.exchangeName.${value}`)}
      <StyledAccountType align={align!}>
        {t(`shared.accountType.${value}`)}
      </StyledAccountType>
    </Typography>
  );
};

ExchangeAccountLabel.defaultProps = {
  align: 'right',
};

export default ExchangeAccountLabel;
