import i18n from 'i18next';

export interface ErrorReason {
  property: string,
  code: string,
  options?: any[],
  detail?: string,
}

export interface FormattedErrorReason extends ErrorReason {
  message: string;
}

export class FormattedErrorReason {
  constructor(reason: ErrorReason) {
    this.property = reason.property;
    this.code = reason.code;
    this.options = reason.options;
    this.detail = reason.detail;
    this.message = i18n.t(reason.code, {
      property: reason.property,
      options: reason.options,
      defaultValue: reason.detail,
    });
  }
}

export default FormattedErrorReason;
