import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import getAppVersion from '@shared/services/getAppVersion';

const Footer: React.FC = () => {
  const version = getAppVersion();

  return version ? (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Typography color="grey.700" variant="body2">
        {version}
      </Typography>
    </Box>
  ) : null;
};

export default Footer;
