import { useDebounce } from 'use-debounce';
import {
  calculateCounter,
  calculatePriceMean,
  getWizardAccounts,
  WizardData,
  WizardState,
  WizardStep,
} from '@client/domain/requestWizard';
import costValidation from '@client/adapters/validation/requestCostValidationAdapter';
import useRequestWizardStorage from '@client/adapters/store/requestWizardStoreAdapters';
import { useCostStorage } from '@client/adapters/store/requestsStoreAdapters';
import { useRequestsClient } from '@client/adapters/client/requestsClientAdapters';
import { useAccountsStorage } from '@client/adapters/store/profileStoreAdapters';
import { useCurrencyTicker } from '@shared/application/useCases/currencyTicker';

import {
  CurrencyTickerClientService,
  RequestsClientService,
  RequestWizardStorageService,
  CostStorageService,
} from '../ports';

export default function useRequestWizard() {
  const wizardStorage: RequestWizardStorageService = useRequestWizardStorage();
  const requestsClient: RequestsClientService = useRequestsClient();

  async function saveWizard(
    step: WizardStep,
    requestUpdates: WizardState[WizardStep],
  ): Promise<void> {
    const data = wizardStorage.edit(step, requestUpdates);
    await requestsClient.save(data);
    wizardStorage.stop();
  }

  return {
    data: wizardStorage.data,
    step: wizardStorage.step,
    start: wizardStorage.start,
    edit: wizardStorage.edit,
    stop: wizardStorage.stop,
    setStep: wizardStorage.setStep,
    save: saveWizard,
    isSaving: requestsClient.isSaving,
  };
}

export function useWizardAccounts() {
  const accountsStorage = useAccountsStorage();
  const accounts = accountsStorage.accounts && getWizardAccounts(accountsStorage.accounts);

  return {
    accounts,
    isLoading: accountsStorage.isLoading,
  };
}

export function useCounterValue(data: Partial<WizardData>) {
  const { ticker }: CurrencyTickerClientService = useCurrencyTicker({
    base: data.baseCurrency,
    counter: data.counterCurrency,
    common: data.commonCurrency,
    exchangeAccount: data.exchangeAccount,
    side: data.side,
  });

  return calculateCounter(data, ticker);
}

export function useCost(data: Partial<WizardData>) {
  const { ticker }: CurrencyTickerClientService = useCurrencyTicker({
    base: data.baseCurrency,
    counter: data.counterCurrency,
    common: data.commonCurrency,
    exchangeAccount: data.exchangeAccount,
    side: data.side,
  });
  const [debouncedVariables] = useDebounce({
    exchangeAccount: data.exchangeAccount,
    side: data.side,
    baseCurrency: data.baseCurrency,
    counterCurrency: data.counterCurrency,
    commonCurrency: data.commonCurrency,
    requestedBase: data.requestedBase,
    price: calculatePriceMean(data, ticker),
  }, 1000, { maxWait: 2000 });

  const isValid = costValidation.isValidSync(debouncedVariables);
  const storage: CostStorageService = useCostStorage(debouncedVariables, { enabled: isValid });

  return {
    cost: storage.cost,
    isLoading: storage.isLoading,
    error: storage.error,
  };
}
