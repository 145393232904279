import api from '@shared/services/api';
import { RequestSide } from '@client/domain/request';
import { ExchangeAccount } from '@shared/domain/account';
import { CurrencyType } from '@client/domain/currency';

export type CurrenciesResponse = {
  data: Currency[]
};

// eslint-disable-next-line import/prefer-default-export
export const getCurrencies = ({
  type,
  side,
  exchangeAccount,
  base,
  counter,
  triangulation,
}: {
  type: CurrencyType,
  side: RequestSide,
  exchangeAccount: ExchangeAccount,
  base?: Currency,
  counter?: Currency,
  triangulation?: boolean,
}): Promise<CurrenciesResponse> => {
  const searchParams = new URLSearchParams({
    side,
    exchange_account: exchangeAccount,
  });

  if (base) searchParams.append('base', base);
  if (counter) searchParams.append('counter', counter);
  if (triangulation) searchParams.append('triangulation', triangulation.toString());

  return api.get(`v1/requests/currencies/${type}?${searchParams.toString()}`);
};
