import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CurrencyIcon from '@shared/components/CurrencyIcon';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';
import getRandomInt from '@shared/utils/random';
import {
  WizardData,
  WizardState,
  WizardStep,
  triangulationIsAllowed,
} from '@client/domain/requestWizard';
import { Currencies, CurrencyType, ClientCurrency } from '@client/domain/currency';
import useCurrencySearch from '@client/application/useCases/currencySearch';

type InputTypes = CurrencyType.Base | CurrencyType.Counter;

interface RowDataProps {
  items: ClientCurrency[],
  onSelect: (currency?: Currency) => void,
}

export function renderRow({
  data,
  index,
  style,
}: ListChildComponentProps<RowDataProps>) {
  const { items, onSelect } = data;
  const { currency } = items[index];
  const handleItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { value } = event.currentTarget.dataset;
    onSelect(value);
  };

  return (
    <ListItemButton
      key={currency}
      style={style}
      sx={{ p: 1 }}
      onClick={handleItemClick}
      data-value={currency}
    >
      <ListItemIcon
        sx={{ minWidth: 36 }}
      >
        <CurrencyIcon
          currency={currency}
          sx={{
            width: 24,
            height: 24,
          }}
        />
      </ListItemIcon>
      <ListItemText primary={currency} primaryTypographyProps={{ fontSize: '0.875rem' }} />
    </ListItemButton>
  );
}

export const CurrenciesList: React.FC<{
  currencies: Currencies,
  onSelect: (currency?: Currency) => void
}> = ({
  currencies,
  onSelect,
}) => (
  <AutoSizer>
    {({ height, width }) => (
      <FixedSizeList
        height={height}
        width={width}
        itemSize={40}
        itemCount={currencies.total}
        itemData={{
          items: currencies.data,
          onSelect,
        }}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    )}
  </AutoSizer>
);

interface CurrencyItemSkeletonProps {
  rows: number,
}

export const CurrencyItemSkeleton: React.FC<CurrencyItemSkeletonProps> = ({ rows }) => (
  <List sx={{ p: 0 }}>
    {[...Array(rows)].map((row, rowIndex) => (
      <ListItemButton
        // eslint-disable-next-line react/no-array-index-key
        key={rowIndex}
        sx={{ p: 1 }}
      >
        <ListItemIcon sx={{ minWidth: 36 }}>
          <Skeleton variant="circular" width={24} height={24} />
        </ListItemIcon>
        <Skeleton width={getRandomInt(30, 50)} />
      </ListItemButton>
    ))}
  </List>
);

const inputNames = {
  // Use 'as' for react-hook-form fields names
  [CurrencyType.Base]: 'baseCurrency' as 'baseCurrency',
  [CurrencyType.Counter]: 'counterCurrency' as 'counterCurrency',
};

const oppositeInputs = {
  [CurrencyType.Base]: CurrencyType.Counter,
  [CurrencyType.Counter]: CurrencyType.Base,
};

const SelectCurrencyPair: React.FC<{
  request: Partial<WizardData>,
  onEdit: (updates: Partial<WizardData>) => void,
  inputToClear?: InputTypes,
  setInputToClear: (inputToClear: InputTypes) => void,
}> = ({
  request,
  onEdit,
  inputToClear,
  setInputToClear,
}) => {
  let noDataMessage: string;
  const {
    control,
    setFocus,
    watch,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<WizardState[WizardStep.Pair]>({
    defaultValues: {
      baseCurrency: request.baseCurrency || '',
      counterCurrency: request.counterCurrency || '',
      isTriangulation: request.isTriangulation || false,
    },
  });
  const { baseCurrency, counterCurrency, isTriangulation } = watch();
  const [selectedInput, setSelectedInput] = useState<InputTypes>(CurrencyType.Base);
  const oppositeInput = oppositeInputs[selectedInput] as InputTypes;
  const selectedInputName = inputNames[selectedInput];
  const oppositeInputName = inputNames[oppositeInput];
  const { currencies, isLoading } = useCurrencySearch({
    exchangeAccount: request.exchangeAccount,
    side: request.side,
    type: selectedInput,
    base: baseCurrency,
    counter: counterCurrency,
    triangulation: isTriangulation,
  });

  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSelectCurrency = (currency?: Currency) => {
    setValue(selectedInputName, currency);

    if (!getValues(oppositeInputName)) {
      setFocus(oppositeInputName);
    } else {
      handleSubmit(onEdit)();
    }
  };

  if (getValues(selectedInputName)) {
    noDataMessage = t('requestWizard.pair.notFound');
  } else if (getValues(oppositeInputName)) {
    noDataMessage = t('requestWizard.pair.invalidCurrency', { field: oppositeInput });
  } else {
    noDataMessage = t('requestWizard.pair.noData');
  }

  useEffect(() => {
    if (inputToClear) {
      setFocus(inputNames[inputToClear]);
      setValue(inputNames[inputToClear], '');
    }
  }, [inputToClear]);

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      <Box
        sx={{
          px: 2,
          display: 'grid',
          gap: `${theme.spacing(1)} ${theme.spacing(2)}`,
          gridTemplateColumns: '1fr 1fr',
          gridTemplateAreas: '"title title"',
        }}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onEdit)}
      >
        <Typography
          sx={{
            gridArea: 'title',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          variant="h6"
        >
          {t('requestWizard.pair.title')}
          {triangulationIsAllowed(request) && (
            <FormControlLabel
              sx={{ mr: 0 }}
              control={(
                <Controller
                  render={({
                    field: {
                      onChange,
                      value,
                      ...field
                    },
                  }) => (
                    <Switch
                      {...field}
                      size="small"
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        setFocus(inputNames[selectedInput]);
                      }}
                    />
                  )}
                  name="isTriangulation"
                  control={control}
                />
              )}
              label={t('requestWizard.pair.triangulation')}
            />
          )}
        </Typography>
        <Controller
          render={({ field: { onChange, ref, ...field } }) => (
            <TextField
              InputLabelProps={{ required: false }}
              label={t('shared.requestCurrency.base')}
              variant="filled"
              onFocus={() => {
                setSelectedInput(CurrencyType.Base);
                // Clear input and save previously selected input
                setInputToClear(CurrencyType.Base);
              }}
              onChange={(e) => onChange(e.target.value.toUpperCase())}
              inputRef={ref}
              {...field}
            />
          )}
          name={inputNames[CurrencyType.Base]}
          control={control}
        />
        <Controller
          render={({ field: { onChange, ref, ...field } }) => (
            <TextField
              InputLabelProps={{ required: false }}
              label={t('shared.requestCurrency.counter')}
              variant="filled"
              onFocus={() => {
                setSelectedInput(CurrencyType.Counter);
                // Clear input and save previously selected input
                setInputToClear(CurrencyType.Counter);
              }}
              onChange={(e) => onChange(e.target.value.toUpperCase())}
              inputRef={ref}
              {...field}
            />
          )}
          name={inputNames[CurrencyType.Counter]}
          control={control}
        />
      </Box>
      <DialogContent
        sx={{
          py: 1,
          px: 2,
          // Set size of the content for AutoSizer
          minHeight: fullScreen ? 'calc(100vh - 172px)' : 300,
        }}
      >
        <NonFlickeringLoader
          key={selectedInput}
          isLoading={isLoading}
          loadingElement={(
            <CurrencyItemSkeleton rows={5} />
          )}
          noData={!currencies?.total}
          noDataElement={(
            <Box p={3}>
              <Typography align="center" variant="subtitle1" component="div" color="text.secondary">
                {noDataMessage}
              </Typography>
            </Box>
          )}
        >
          {currencies && (
            <CurrenciesList currencies={currencies} onSelect={handleSelectCurrency} />
          )}
        </NonFlickeringLoader>
      </DialogContent>
    </>
  );
};

SelectCurrencyPair.defaultProps = {
  inputToClear: undefined,
};

export default SelectCurrencyPair;
