import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransferClientService } from '@client/application/ports';
import { transfer } from '@client/services/api/transfer';

// eslint-disable-next-line import/prefer-default-export
export function useTransferClient(): TransferClientService {
  const queryClient = useQueryClient();

  const transferMutation = useMutation(transfer, {
    onSuccess: () => {
      queryClient.invalidateQueries(['balances']);
      queryClient.invalidateQueries(['request', 'cost']);
    },
  });

  return {
    makeTransfer: transferMutation.mutateAsync,
    isLoading: transferMutation.isLoading,
  };
}
