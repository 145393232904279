import * as React from 'react';
import Typography from '@mui/material/Typography';
import format from '@shared/i18n/date/format';

type Align = 'right' | 'bottom';

interface DateComponentProps {
  date: DateString,
  align?: Align,
}

const DateComponent: React.FC<DateComponentProps> = ({ date, align }) => (
  <>
    <Typography
      component="span"
      variant="inherit"
      mr={align === 'right' ? 1 : 0}
    >
      {format(new Date(date), { dateOnly: true })}
    </Typography>
    <Typography
      color="text.secondary"
      component="span"
      variant="inherit"
      sx={{ display: align === 'right' ? 'inline-block' : 'block' }}
    >
      {format(new Date(date), { timeOnly: true })}
    </Typography>
  </>
);

DateComponent.defaultProps = {
  align: 'right',
};

export default DateComponent;
