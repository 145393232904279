import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { useRequestData } from '@client/application/useCases/request';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';
import ReceivingDataError from '@shared/components/ReceivingDataError';
import RequestDetails, { RequestDetailsSkeleton } from '@shared/components/RequestDetails';
import CancelRequest from '@shared/components/CancelRequest';

const RequestPage = () => {
  const { uuid } = useParams<{ uuid: Uuid }>();
  const { request, isLoading, error } = useRequestData(uuid!);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  if (error) {
    return (
      <ReceivingDataError>
        {error.message}
      </ReceivingDataError>
    );
  }

  return (
    <Container disableGutters maxWidth="md" sx={{ p: 2 }}>
      <NonFlickeringLoader
        isLoading={isLoading}
        loadingElement={(
          <RequestDetailsSkeleton />
        )}
      >
        {request && (
          <RequestDetails
            request={request}
            cancelRequest={() => setShowDialog(true)}
          />
        )}
      </NonFlickeringLoader>
      {request && (
        <CancelRequest
          isOpen={showDialog}
          onCloseModal={() => setShowDialog(false)}
          request={request}
        />
      )}
    </Container>
  );
};

export default RequestPage;
