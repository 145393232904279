import { RequestWizardStorageService } from '@client/application/ports';
import { useRequestWizardState } from '@client/services/context/requestWizard';
import { defaultWizardState, WizardStep } from '@client/domain/requestWizard';

export default function useRequestWizardStorage(): RequestWizardStorageService {
  const {
    data,
    setData,
    step,
    setStep,
    setDefaultState,
  } = useRequestWizardState();

  return {
    data,
    step,
    start: (initStep, defaultState) => {
      setDefaultState({
        ...defaultWizardState,
        ...defaultState,
      });
      setData(WizardStep.Side, undefined);
      setStep(initStep || WizardStep.Side);
    },
    edit: setData,
    setStep,
    stop: () => setStep(undefined),
  };
}
