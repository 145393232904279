import * as React from 'react';

import UnauthenticatedRoute from '@shared/router/UnauthenticatedRoute';
import { useAuth } from '@client/application/useCases/auth';

interface Props {
  children: React.ReactNode;
}

const UnauthenticatedRouteWithAuth: React.FC<Props> = ({ children }) => {
  const auth = useAuth();

  return (
    <UnauthenticatedRoute isAuthenticated={auth.isAuthenticated}>
      {children}
    </UnauthenticatedRoute>
  );
};

export default UnauthenticatedRouteWithAuth;
