import enUSLocale from 'date-fns/locale/en-US';
import ukUALocale from 'date-fns/locale/uk';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import Language from '@shared/domain/language';

export type FormatDistanceToNowStrictLocalizedProps = {
  date: Date | number,
  options?: {
    addSuffix?: boolean
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
    roundingMethod?: 'floor' | 'ceil' | 'round'
    locale?: Locale
  },
  locale?: Language,
};

const formatDistanceToNowStrictLocalized = ({
  date,
  options,
  locale = Language.EnUs,
}: FormatDistanceToNowStrictLocalizedProps) => formatDistanceToNowStrict(
  date,
  {
    ...options,
    locale: {
      ...({
        [Language.EnUs]: enUSLocale,
        [Language.UkUa]: ukUALocale,
      }[locale]),
      ...options?.locale,
    },
  },
);

export default formatDistanceToNowStrictLocalized;
