import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

const AdditionalOptions: React.FC = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{t('requestWizard.total.options.title')}</FormLabel>
      <Controller
        control={control}
        name="isIceberg"
        render={({ field: { onChange, onBlur, value } }) => (
          <FormControlLabel
            control={
              <Switch checked={value} onChange={(e) => onChange(e.target.checked)} name="isIceberg" />
            }
            label={t('requestWizard.total.options.iceberg')}
            sx={{ my: 1 }}
            onBlur={onBlur}
          />
        )}
      />
    </FormControl>
  );
};

export default AdditionalOptions;
