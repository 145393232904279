import { ExchangeAccount } from '@shared/domain/account';

export type {
  Balance,
  Balances,
  TotalEquivalents,
} from '@shared/domain/balance';

export type BalancesFilters = {
  exchangeAccount?: ExchangeAccount,
};

export function getBalanceAccounts(accounts: ExchangeAccount[]) {
  return accounts.filter((account) => !process.env.HIDE_BALANCE_ACCOUNTS?.split(',').includes(account));
}

export const tickerCounters = ['USDT', 'USDC', 'USD'];

export function showTicker(currency?: Currency) {
  return currency && !currency.includes('USD');
}
