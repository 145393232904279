import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { WizardData } from '@client/domain/requestWizard';
import CurrencyTicker from '@client/components/CurrencyTicker';
import PriceInput from '../PriceInput';

const ScaledRequest: React.FC<{
  request: Partial<WizardData>,
  errors: FieldErrors<Record<'minPrice' | 'maxPrice', any>>,
}> = ({ request, errors }) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const minPrice = watch('minPrice');
  const maxPrice = watch('maxPrice');

  const handleTickerInit = useCallback((ticker: number) => {
    const tickerString = ticker.toString();

    if (!minPrice) {
      setValue('minPrice', tickerString, { shouldValidate: true });
    }
    if (!maxPrice) {
      setValue('maxPrice', tickerString, { shouldValidate: true });
    }
  }, [minPrice, maxPrice]);

  const handleTickerClick = useCallback((ticker: number) => {
    const tickerString = ticker.toString();
    setValue('minPrice', tickerString, { shouldValidate: true });
    setValue('maxPrice', tickerString, { shouldValidate: true });
  }, []);

  if (!request.baseCurrency || !request.counterCurrency || !request.exchangeAccount) return null;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      <Controller
        defaultValue=""
        control={control}
        name="minPrice"
        render={({ field }) => (
          <PriceInput
            label={t('requestWizard.total.minPrice')}
            placeholder={t('requestWizard.total.minPricePlaceholder')}
            fontSize="1.5rem"
            currency={request.counterCurrency!}
            error={errors.minPrice}
            {...field}
          />
        )}
      />
      <Controller
        defaultValue=""
        control={control}
        name="maxPrice"
        render={({ field }) => (
          <PriceInput
            label={t('requestWizard.total.maxPrice')}
            placeholder={t('requestWizard.total.maxPricePlaceholder')}
            fontSize="1.5rem"
            currency={request.counterCurrency!}
            error={errors.maxPrice}
            {...field}
          />
        )}
      />
      <CurrencyTicker
        onInitiate={handleTickerInit}
        base={request.baseCurrency}
        counter={request.counterCurrency}
        exchangeAccount={request.exchangeAccount}
        onClick={handleTickerClick}
      />
    </>
  );
};

export default ScaledRequest;
