import * as React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import deadline from '@shared/utils/deadline';
import {
  DeadlineUrgency,
  getDeadlineUrgency,
  Request,
  deadlineEditAllowed,
} from '@shared/domain/request';

const deadlineTextColor = {
  [DeadlineUrgency.Regular]: 'inherit',
  [DeadlineUrgency.Warning]: 'warning.light',
  [DeadlineUrgency.Error]: 'error.light',
};

export const showDeadlineComponent = (request: Request) => (
  deadlineEditAllowed(request) || !!request.deadline
);

interface DeadlineComponentProps {
  request: Request,
  onEdit: () => void,
}

const DeadlineComponent: React.FC<DeadlineComponentProps> = ({
  request,
  onEdit,
}) => (
  <>
    {request.deadline && (
      <Typography component="span" variant="inherit" color={deadlineTextColor[getDeadlineUrgency(request)]}>
        {deadline(request)}
      </Typography>
    )}
    {deadlineEditAllowed(request) && (
      <IconButton sx={{ ml: 1 }} size="small" onClick={onEdit}>
        <EditIcon fontSize="small" />
      </IconButton>
    )}
  </>
);

export default DeadlineComponent;
