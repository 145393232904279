import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { orange } from '@mui/material/colors';

interface TotalBalancesProps {
  total: CurrencyAmount,
}

const TotalBalances: React.FC<TotalBalancesProps> = ({ total }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="caption" color="grey.600">
        {t('balances.total')}
      </Typography>
      <Typography variant="h4" align="center" sx={{ color: orange[100] }}>
        ₮
        {total}
      </Typography>
    </>
  );
};

export default TotalBalances;
