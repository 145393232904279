import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { AuthToken } from '@shared/domain/auth';
import { getToken, removeToken, setToken } from '@shared/services/auth';
import InternalError from '@shared/errors/InternalError';

type AuthContextValues = {
  token?: AuthToken;
  saveToken(token: AuthToken): void;
  removeToken(): void;
} | undefined;

export const AuthContext = createContext<AuthContextValues>(undefined);

export function useAuthState() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new InternalError('useAuthState must be used within a AuthStateProvider');
  }
  return context;
}
export const AuthStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [stateToken, setStateToken] = useState<AuthToken | undefined>(getToken() || undefined);

  const saveTokenCallback = useCallback((token: AuthToken) => {
    setToken(token);
    setStateToken(token);
  }, []);

  const removeTokenCallback = useCallback(() => {
    removeToken();
    setStateToken(undefined);
  }, []);

  const value = useMemo(() => ({
    token: stateToken,
    saveToken: saveTokenCallback,
    removeToken: removeTokenCallback,
  }), [stateToken, saveTokenCallback, removeTokenCallback]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
