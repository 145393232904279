import * as React from 'react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';

import parseRequestError from '@shared/services/parseRequestError';
import LoadingButton from '@shared/components/LoadingButton';
import { Credentials } from '@shared/domain/auth';
import logo from '@assets/img/logo-filled.png';

const loginValidation = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LogInWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

interface LogInFormProps {
  isLoading: boolean,
  title?: string,
  emailLabel?: string,
  passwordLabel?: string,
  submitLabel?: string,
  onSubmit: (credentials: Credentials) => Promise<void>
}

/* eslint-disable react/jsx-props-no-spreading */

const LogInForm: React.FC<LogInFormProps> = ({
  onSubmit,
  isLoading,
  title,
  emailLabel,
  passwordLabel,
  submitLabel,
}) => {
  // "general: void" needed for general errors with certain field name
  const {
    register,
    handleSubmit,
    setError,
    formState: {
      errors,
    },
  } = useForm<Credentials & { general: void }>({
    resolver: yupResolver(loginValidation),
  });

  const handleFormSubmit = useCallback((formData: Credentials) => (
    onSubmit(formData).catch((error) => parseRequestError({ error, setError, catchAuth: true }))
  ), [onSubmit]);

  return (
    <Container component="main" maxWidth="xs">
      <LogInWrapper>
        <Avatar alt="Axiomo" src={logo} />
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={emailLabel}
            type="email"
            autoComplete="email"
            autoFocus
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register('email', { required: true })}
          />
          <TextField
            margin="normal"
            fullWidth
            label={passwordLabel}
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password', { required: true })}
          />
          {errors.general && (
            <Alert severity="error">{errors.general.message}</Alert>
          )}
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ my: 2 }}
            loading={isLoading}
          >
            {submitLabel}
          </LoadingButton>
        </Box>
      </LogInWrapper>
    </Container>
  );
};

LogInForm.defaultProps = {
  title: 'Sign in',
  emailLabel: 'Email Address',
  passwordLabel: 'Password',
  submitLabel: 'Sign In',
};

export default LogInForm;
