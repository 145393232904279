/* eslint-disable @typescript-eslint/no-use-before-define */
import Decimal from 'decimal.js';
import { ExchangeAccount } from '@shared/domain/account';
import { RequestSide, RequestType } from '@client/domain/request';
import InternalError from '@shared/errors/InternalError';
import withPrecision from '@shared/utils/withPrecision';

export enum WizardStep {
  Side,
  Account,
  Pair,
  Common,
  Total,
}

type TotalBase = {
  requestedBase: CurrencyAmount,
  requestedCounter?: CurrencyAmount,
  comment?: string,
  deadline?: DateString,
  isIceberg?: boolean,
};

type TotalMarket = TotalBase & {
  type: RequestType.Market,
};

type TotalLimit = TotalBase & {
  type: RequestType.Limit,
  requestedPrice: CurrencyAmount,
};

type TotalScaled = TotalBase & {
  type: RequestType.Scaled,
  minPrice: CurrencyAmount,
  maxPrice: CurrencyAmount,
};

export interface WizardState {
  [WizardStep.Side]: Partial<{
    side: RequestSide,
  }>,
  [WizardStep.Account]: Partial<{
    exchangeAccount: ExchangeAccount,
  }>,
  [WizardStep.Pair]: Partial<{
    isTriangulation: boolean,
    baseCurrency: Currency,
    counterCurrency: Currency,
  }>,
  [WizardStep.Common]: Partial<{
    commonCurrency: Currency,
  }>,
  [WizardStep.Total]: Partial<TotalMarket | TotalLimit | TotalScaled>,
}

export type WizardData =
  WizardState[WizardStep.Side] &
  WizardState[WizardStep.Account] &
  WizardState[WizardStep.Pair] &
  WizardState[WizardStep.Common] &
  WizardState[WizardStep.Total];

export const stepsDependencies: { [key in WizardStep]: WizardStep[] } = {
  [WizardStep.Side]: [WizardStep.Account],
  [WizardStep.Account]: [WizardStep.Pair],
  [WizardStep.Pair]: [WizardStep.Common],
  [WizardStep.Common]: [WizardStep.Total],
  [WizardStep.Total]: [],
};

export const defaultWizardState: Partial<WizardState> = {
  [WizardStep.Total]: {
    type: RequestType.Limit,
    isIceberg: true,
  },
};

export const triangulationAccounts = [
  ExchangeAccount.BinanceSpot,
  ExchangeAccount.CoinbaseSpot,
  ExchangeAccount.CoinbasePrimeSpot,
  ExchangeAccount.GateioSpot,
  ExchangeAccount.BitfinexSpot,
];

export const wizardAccounts = [
  ExchangeAccount.BinanceSpot,
  ExchangeAccount.BinanceUSDM,
  ExchangeAccount.CoinbaseSpot,
  ExchangeAccount.CoinbasePrimeSpot,
  ExchangeAccount.GateioSpot,
  ExchangeAccount.BitfinexSpot,
];

export function getWizardAccounts(accounts: ExchangeAccount[]) {
  return accounts.filter((account) => !process.env.HIDE_WIZARD_ACCOUNTS?.split(',').includes(account) && wizardAccounts.includes(account));
}
export function updateState(
  state: Partial<WizardState>,
  step: WizardStep,
  data?: WizardState[WizardStep],
): Partial<WizardState> {
  const cleanSteps = stepsDependencies[step];
  const updatedStep = data ? {
    ...state[step],
    ...data,
  } : undefined;
  const updatedState: Partial<WizardState> = { ...state, [step]: updatedStep };
  return cleanSteps.reduce((stateToClen, clenStep) => (
    updateState(stateToClen, clenStep)
  ), updatedState);
}

export const getDataFromState = (
  state: Partial<WizardState>,
  defaultState?: Partial<WizardState>,
): WizardData => (
  Object.values(state).reduce(
    (data, stateValue) => ({ ...data, ...stateValue }),
    defaultState ? getDataFromState(defaultState) : {},
  )
);

export const calculatePriceMean = (state: Partial<WizardData>, ticker?: number) => {
  switch (state.type) {
    case RequestType.Market:
      return typeof ticker === 'number' ? ticker.toString() : undefined;
    case RequestType.Limit:
      return typeof state.requestedPrice === 'string' && state.requestedPrice ? state.requestedPrice : undefined;
    case RequestType.Scaled:
      return typeof state.minPrice === 'string' && state.minPrice && typeof state.maxPrice === 'string' && state.maxPrice
        ? Decimal.add(state.minPrice, state.maxPrice).div(2).toString()
        : undefined;
    default:
      throw new InternalError('Wrong request wizard type');
  }
};

export const calculateCounter = (state: Partial<WizardData>, ticker?: number) => {
  const meanPrice = calculatePriceMean(state, ticker);

  return (state.requestedBase && typeof meanPrice !== 'undefined')
    ? withPrecision(new Decimal(state.requestedBase).mul(meanPrice).toNumber(), 8) : undefined;
};

export const triangulationIsAllowed = (state: Partial<WizardData>) => (
  !!state.exchangeAccount && triangulationAccounts.includes(state.exchangeAccount)
);

export const typeIsDisabled = (type: RequestType, state: Partial<WizardData>) => {
  if (state.isTriangulation) {
    if (type === RequestType.Scaled) return true;
    if (type === RequestType.Market) return true;
  }
  return false;
};
