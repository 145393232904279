import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReceivingDataError from '@shared/components/ReceivingDataError';
import InfiniteRequestsTable from '@shared/components/InfiniteRequestsTable';
import useRequestsData from '@client/application/useCases/requests';
import useTotalBalances from '@client/application/useCases/totalBalances';

const RequestsSection: React.FC = () => {
  const { t } = useTranslation();
  const {
    requests,
    isLoading,
    loadMore,
    isLoadingMore,
    error,
  } = useRequestsData();
  const { totalBalances } = useTotalBalances();

  return (
    <>
      <Box sx={{ display: 'flex' }} p={2}>
        <Typography mr={1}>
          {t('requests.title')}
        </Typography>
        <Typography color="text.secondary">
          {requests?.total}
        </Typography>
      </Box>
      {error ? (
        <ReceivingDataError>
          {error.message}
        </ReceivingDataError>
      ) : (
        <InfiniteRequestsTable
          requests={requests}
          balances={totalBalances}
          isLoading={isLoading}
          loadMore={loadMore}
          isLoadingMore={isLoadingMore}
          noRequestsTitle={t('requests.noData.title')}
          noBalancesTitle={t('requests.noData.description')}
        />
      )}
    </>
  );
};

export default RequestsSection;
