import { useBalancesStorage } from '@client/adapters/store/balancesStoreAdapters';
import { useSettingsState } from '@client/services/context/settings';
import { BalancesStorageService } from '../ports';

export default function useBalances() {
  const { settings } = useSettingsState();
  const balancesStorage: BalancesStorageService = useBalancesStorage({
    hideSmallBalances: settings.hideSmallBalances,
    smallBalancesEquivalent: settings.smallBalancesEquivalent,
    equivalentCurrency: settings.equivalentCurrency,
  });

  return {
    balances: balancesStorage.balances,
    isLoading: balancesStorage.isLoading,
    error: balancesStorage.error,
  };
}
