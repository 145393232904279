import withPrecision from '@shared/utils/withPrecision';
import { Book, BookOrder } from '@shared/domain/orderBook';
import { RequestSide } from '@shared/domain/request';

export type Pair = {
  base?: Currency,
  counter?: Currency,
};

export const getTriangulationTicker = ({
  firstPair,
  secondPair,
  firstTicker,
  secondTicker,
}: {
  firstPair: Pair,
  secondPair: Pair,
  firstTicker?: number,
  secondTicker?: number,
}) => {
  let ticker: number;

  if (!firstTicker || !secondTicker) return undefined;

  if (firstPair.counter === secondPair.base) {
    ticker = firstTicker * secondTicker;
  } else if (firstPair.base === secondPair.counter) {
    ticker = 1 / (firstTicker * secondTicker);
  } else if (firstPair.counter === secondPair.counter) {
    ticker = firstTicker / secondTicker;
  } else if (firstPair.base === secondPair.base) {
    ticker = secondTicker / firstTicker;
  } else {
    return undefined;
  }

  return withPrecision(ticker, 8);
};

export const getTickerFromOrders = ({
  orders,
  requestedAmount = 0,
  requestedCost = 0,
  spentAmount = 0,
  spentCost = 0,
  approximation = false,
}: {
  orders: BookOrder[],
  requestedAmount?: number,
  requestedCost?: number,
  spentAmount?: number,
  spentCost?: number,
  approximation?: boolean,
}) : {
  approximation: boolean,
  ticker?: number,
} => {
  const result = { approximation, ticker: spentCost / spentAmount };
  const [topOrder, ...remainingOrders] = [...orders];

  let additionalAmount = 0;
  let additionalCost = 0;
  let newIterationIsApproximation = false;

  if (!topOrder) {
    return result;
  }

  if (requestedAmount) {
    if (requestedAmount <= spentAmount) return result;

    const leftAmount = requestedAmount - spentAmount;

    if (leftAmount <= topOrder.amount) {
      additionalAmount = leftAmount;
    } else if (remainingOrders.length) {
      additionalAmount = topOrder.amount;
    } else {
      additionalAmount = leftAmount;
      newIterationIsApproximation = true;
    }
    additionalCost = topOrder.price * additionalAmount;
  } else if (requestedCost) {
    if (requestedCost <= spentCost) return result;

    const leftCost = requestedCost - spentCost;

    if (leftCost <= topOrder.amount * topOrder.price) {
      additionalCost = leftCost;
    } else if (remainingOrders.length) {
      additionalCost = topOrder.amount * topOrder.price;
    } else {
      additionalCost = leftCost;
      newIterationIsApproximation = true;
    }

    additionalAmount = additionalCost / topOrder.price;
  } else {
    return {
      approximation,
      ticker: topOrder.price,
    };
  }

  return getTickerFromOrders({
    orders: remainingOrders,
    requestedAmount,
    requestedCost,
    spentAmount: spentAmount + additionalAmount,
    spentCost: spentCost + additionalCost,
    approximation: newIterationIsApproximation,
  });
};

export const getTickerFromBooks = ({
  firstPair,
  secondPair,
  firstBook,
  secondBook,
  commonCurrency,
  commonAmount,
  side,
}: {
  firstPair: Pair,
  secondPair: Pair,
  firstBook: Book,
  secondBook: Book,
  commonCurrency: Currency,
  commonAmount: number,
  side: RequestSide
}) => {
  const { ticker: firstTicker, approximation: firstTickerApproximation } = getTickerFromOrders(
    firstPair.base === commonCurrency ? {
      orders: side === RequestSide.Buy ? firstBook.bids : firstBook.asks,
      requestedAmount: commonAmount,
      requestedCost: undefined,
    } : {
      orders: side === RequestSide.Buy ? firstBook.asks : firstBook.bids,
      requestedAmount: undefined,
      requestedCost: commonAmount,
    },
  );

  const { ticker: secondTicker, approximation: secondTickerApproximation } = getTickerFromOrders(
    secondPair.base === commonCurrency ? {
      orders: side === RequestSide.Buy ? secondBook.asks : secondBook.bids,
      requestedAmount: commonAmount,
      requestedCost: undefined,
    } : {
      orders: side === RequestSide.Buy ? secondBook.bids : secondBook.asks,
      requestedAmount: undefined,
      requestedCost: commonAmount,
    },
  );

  const ticker = getTriangulationTicker({
    firstPair,
    secondPair,
    firstTicker,
    secondTicker,
  });

  return {
    ticker,
    approximation: firstTickerApproximation || secondTickerApproximation,
  };
};
