import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollTopProps {
  children: React.ReactElement;
}

const ScrollTop: React.FC<ScrollTopProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

export default ScrollTop;
