import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ReceivingDataError from '@shared/components/ReceivingDataError';
import useRequestsData from '@client/application/useCases/requests';
import InfiniteRequestsTable from '@shared/components/InfiniteRequestsTable';
import useTotalBalances from '@client/application/useCases/totalBalances';
import useRequestWizard from '@client/application/useCases/requestWizard';

const RequestsPage = () => {
  const { t } = useTranslation();
  const {
    requests,
    isLoading,
    error,
    loadMore,
    isLoadingMore,
  } = useRequestsData();
  const { totalBalances } = useTotalBalances();
  const { start } = useRequestWizard();

  if (error) {
    return (
      <ReceivingDataError>
        {error.message}
      </ReceivingDataError>
    );
  }

  return (
    <Container disableGutters maxWidth="md">
      <Box sx={{ display: 'flex' }} p={2}>
        <Typography mr={1}>
          {t('requests.title')}
        </Typography>
        <Typography color="text.secondary">
          {requests?.total}
        </Typography>
      </Box>
      <InfiniteRequestsTable
        requests={requests}
        balances={totalBalances}
        isLoading={isLoading}
        loadMore={loadMore}
        startWizard={start}
        isLoadingMore={isLoadingMore}
        noRequestsTitle={t('requests.noData.title')}
        noBalancesTitle={t('requests.noData.description')}
        createNewRequestTitle={t('requests.noData.action')}
      />
    </Container>
  );
};

export default RequestsPage;
