import * as React from 'react';
import { useState, useMemo } from 'react';
import Decimal from 'decimal.js';
import InternalError from '@shared/errors/InternalError';
import { changeSettings, initiateSettings, Settings } from '@client/domain/settings';
import { getHideSmallBalances, setHideSmallBalances } from '@client/services/store/settings';
import { BalanceEquivalents } from '@shared/domain/balance';

type ChangeSettingsCallback = (settings: Partial<Settings>) => void;
type ChangeHideSmallBalancesCallback = (value: boolean) => void;
type SettingsStateContextValues = {
  settings: Settings,
  changeSettings: ChangeSettingsCallback,
  changeHideSmallBalances: ChangeHideSmallBalancesCallback,
} | undefined;

const defaultState = initiateSettings({
  hideSmallBalances: getHideSmallBalances(),
});

const SettingsContext = React.createContext<SettingsStateContextValues>(undefined);

function useSettingsState() {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new InternalError('useSettingsState must be used within a SettingsStateProvider');
  }
  return context;
}

export function useEquivalent(equivalents?: BalanceEquivalents) {
  const { settings } = useSettingsState();
  const equivalent = equivalents?.[settings.equivalentCurrency];

  return !equivalent || new Decimal(equivalent).isZero() ? 'N/A' : `₮${equivalent}`;
}

interface Props {
  children: React.ReactNode;
}

const SettingsStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<Settings>(defaultState);
  const changeSettingsCallback: ChangeSettingsCallback = (settings) => {
    setState(changeSettings(state, settings));
  };
  const changeHideSmallBalancesCallback: ChangeHideSmallBalancesCallback = (value) => {
    setState(changeSettings(state, { hideSmallBalances: value }));
    setHideSmallBalances(value);
  };
  const value = useMemo(() => ({
    settings: state,
    changeSettings: changeSettingsCallback,
    changeHideSmallBalances: changeHideSmallBalancesCallback,
  }), [state, changeSettingsCallback, changeHideSmallBalancesCallback]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsStateProvider, useSettingsState };
