import { MiniBookOrder } from '@shared/domain/orderBook';

export type BinancePrice = string;
export type BinanceAmount = string;
export type BinanceBookOrder = [BinancePrice, BinanceAmount];

export const binanceItemToBookItem = (item: BinanceBookOrder): MiniBookOrder => ({
  price: parseFloat(item[0]),
  amount: parseFloat(item[1]),
});
