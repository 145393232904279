import { MiniBook, MiniBookOrder } from '@shared/domain/orderBook';

export enum DepthUpdateSide {
  Buy = 'buy',
  Sell = 'sell',
}

export type CoinbasePair = string;
export type CoinbasePrice = string;
export type CoinbaseAmount = string;
export type CoinbaseBookOrder = [CoinbasePrice, CoinbaseAmount];
export type CoinbaseBookUpdate = [DepthUpdateSide, CoinbasePrice, CoinbaseAmount];

export const coinbaseItemToBookItem = (item: CoinbaseBookOrder): MiniBookOrder => ({
  price: parseFloat(item[0]),
  amount: parseFloat(item[1]),
});

export const updateBook = (bookUpdate: CoinbaseBookUpdate, book: MiniBook) => {
  const { bids, asks } = book;
  const newItem = coinbaseItemToBookItem([bookUpdate[1], bookUpdate[2]]);
  let newBids = bids;
  let newAsks = asks;
  let arrayToUpdate;
  let compareFn;

  // is it bids or asks
  if (bookUpdate[0] === DepthUpdateSide.Buy) {
    newBids = [...newBids];
    arrayToUpdate = newBids;
    compareFn = (a: MiniBookOrder, b: MiniBookOrder) => b.price - a.price;
  } else {
    newAsks = [...newAsks];
    arrayToUpdate = newAsks;
    compareFn = (a: MiniBookOrder, b: MiniBookOrder) => a.price - b.price;
  }

  const index = arrayToUpdate.findIndex(({ price }) => price === newItem.price);

  if (newItem.amount === 0) {
    if (index !== -1) {
      // if amount 0 - remove item from the array
      arrayToUpdate.splice(index, 1);
    }
  } else if (index === -1) {
    // if the new item - add to the end and sort
    arrayToUpdate.push(newItem);
    arrayToUpdate.sort(compareFn);
  } else {
    // if item already exists - update it
    arrayToUpdate[index] = newItem;
  }

  return {
    bids: newBids,
    asks: newAsks,
  };
};

export const cutBook = (book: MiniBook, length?: number) => ({
  bids: book.bids.slice(0, length),
  asks: book.asks.slice(0, length),
});

export const skipUSDC = (currency: Currency) => (currency === 'USDC' ? 'USD' : currency);

export const getCoinbasePair = (base: Currency, counter: Currency) => `${base}-${skipUSDC(counter)}`;
