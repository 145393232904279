import useLoadersStorage from '@shared/services/loaders';
import useErrorStorage from '@shared/services/errors';
import NetworkError from '@shared/errors/NetworkError';

export interface ExecutionOptions {
  silent: boolean,
}

export default function useApiRequestStateStorage(initialLoading: boolean = false) {
  const { isLoading, startLoading, stopLoading } = useLoadersStorage(initialLoading);
  const { error, setError } = useErrorStorage();

  const execute = async <T>(
    makeRequest: () => Promise<T>,
    saveResponse: (response: T) => void,
    options?: ExecutionOptions,
  ) => {
    setError(null);
    if (!options?.silent) startLoading();

    try {
      const result = await makeRequest();
      saveResponse(result);
    } catch (e) {
      if (e instanceof NetworkError) {
        setError(e);
      } else {
        throw e;
      }
    } finally {
      if (!options?.silent) stopLoading();
    }
  };

  return {
    execute,
    isLoading,
    error,
  };
}
