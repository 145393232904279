import { QueryClient } from '@tanstack/react-query';
import {
  getCurrencySymbolMap,
  getPairsList,
  getTicker,
  getOrderBook,
} from '@shared/services/api/bitfinexSpot';
import { findPair } from '@shared/domain/bitfinex';

export const bitfinexQueryClient = new QueryClient();

export async function fetchPair(base: Currency, counter: Currency) {
  const currencySymbolMapPromise = bitfinexQueryClient.fetchQuery({
    queryKey: ['currency-map-symbol'],
    queryFn: getCurrencySymbolMap,
    staleTime: Infinity,
  });
  const currencyPairsListPromise = bitfinexQueryClient.fetchQuery({
    queryKey: ['pairs-list'],
    queryFn: getPairsList,
    staleTime: Infinity,
  });
  const [
    currencySymbolMap,
    currencyPairsList,
  ] = await Promise.all([currencySymbolMapPromise, currencyPairsListPromise]);

  const pair = findPair({
    base,
    counter,
    currencySymbolMap,
    currencyPairsList,
  });

  return `t${pair || base + counter}`;
}

export async function fetchTicker(base: Currency, counter: Currency) {
  const pair = await fetchPair(base, counter);

  return getTicker(pair);
}

export async function fetchOrderBook({ base, counter }: { base: Currency, counter: Currency }) {
  const pair = await fetchPair(base, counter);

  return getOrderBook(pair);
}
