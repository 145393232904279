import {
  SubscriptionOptions,
  SubscriptionCallback,
  SubscriptionError,
  SubscriptionStream,
  ExchangeSocketsClient,
} from '@shared/services/sockets/index';
import InternalError from '@shared/errors/InternalError';
import SocketClient from '@shared/services/sockets/socketClient';
import {
  GateioBookOrder,
  GateioPair,
  gateioItemToBookItem,
  getPair,
} from '@shared/domain/gateio';

export type ErrorDetails = {
  code: number,
  message: string,
};

export enum SendEvent {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export enum ChanelName {
  Ticker = 'spot.tickers',
  Depth = 'spot.order_book',
}

export enum ReceiveEvent {
  Update = 'update',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export type SendEventMessage = {
  event: SendEvent,
  channel: ChanelName,
  payload: string[],
};

export type SubscribeEventMessage = {
  event: ReceiveEvent.Subscribe,
  channel: ChanelName,
  payload?: string[],
  error?: ErrorDetails,
};

export type UnsubscribeEventMessage = {
  event: ReceiveEvent.Unsubscribe,
  channel: ChanelName,
  error?: ErrorDetails
};

export type TickerMessage = {
  event: ReceiveEvent.Update;
  channel: ChanelName.Ticker,
  result: {
    currency_pair: GateioPair,
    last: string,
  }
};

export type DepthMessage = {
  event: ReceiveEvent.Update;
  channel: ChanelName.Depth,
  result: {
    s: GateioPair,
    bids: GateioBookOrder[],
    asks: GateioBookOrder[],
  }
};

export type Message = (
    DepthMessage
    | TickerMessage
    | UnsubscribeEventMessage
    | SubscribeEventMessage
    | SendEventMessage
    );

export class GateioSpotClient implements ExchangeSocketsClient {
  protected socket: SocketClient<Message>;

  constructor({ url = 'wss://api.gateio.ws/ws/v4/' } = {}) {
    this.socket = new SocketClient<Message>({
      url,
      handleMessage: this.handleMessage.bind(this),
    });
  }

  private handleTicker(message: Message) {
    if (message.event !== ReceiveEvent.Update || message.channel !== ChanelName.Ticker) return;

    const key = `${ChanelName.Ticker}${message.result.currency_pair}`;

    this.socket.subscriptions[key]?.callbacks.map(
      (subscription) => subscription(Number(message.result.last)),
    );
  }

  private handleDepth(message: Message) {
    if (message.event !== ReceiveEvent.Update || message.channel !== ChanelName.Depth) return;

    const key = `${ChanelName.Depth}${message.result.s}`;

    this.socket.subscriptions[key]?.callbacks.map(
      (subscription) => subscription({
        asks: message.result.asks.map(gateioItemToBookItem),
        bids: message.result.bids.map(gateioItemToBookItem),
      }),
    );
  }

  private handleError(message: Message) {
    if (message.event !== ReceiveEvent.Subscribe || !message.error || !message.payload) return;

    const key = `${ChanelName.Ticker}${message.payload?.[0]}`;

    this.socket.errorCallback(key)(new Error(message.error.message));
  }

  private handleMessage(message: Message) {
    if (message.event === ReceiveEvent.Subscribe) {
      if (message.error) {
        this.handleError(message);
        return;
      }
      // Subscription success messages
      return;
    }
    if (message.event === ReceiveEvent.Unsubscribe) {
      // Unsubscription success messages
      return;
    }

    switch (message.channel) {
      case ChanelName.Ticker:
        this.handleTicker(message);
        break;
      case ChanelName.Depth:
        this.handleDepth(message);
        break;
      default:
        this.handleError(message);
    }
  }

  public subscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.subscribeToOrderBooks(callback, options, onError);
        break;
      case SubscriptionStream.Ticker:
        this.subscribeToTicker(callback, options, onError);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  public unsubscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.unsubscribeFromOrderBooks(callback, options);
        break;
      case SubscriptionStream.Ticker:
        this.unsubscribeFromTicker(callback, options);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  private subscribeToOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const gateioPair = getPair(base, counter);
    const key = `${ChanelName.Depth}${gateioPair}`;

    this.socket.subscribe(
      key,
      {
        event: SendEvent.Subscribe,
        channel: ChanelName.Depth,
        payload: [gateioPair, '20', '1000ms'],
      },
      callback,
      onError,
    );
  }

  private unsubscribeFromOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const gateioPair = getPair(base, counter);
    const key = `${ChanelName.Depth}${gateioPair}`;

    this.socket.unsubscribe(
      key,
      {
        event: SendEvent.Unsubscribe,
        channel: ChanelName.Depth,
        payload: [gateioPair, '20', '1000ms'],
      },
      callback,
    );
  }

  private subscribeToTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const gateioPair = getPair(base, counter);
    const key = `${ChanelName.Ticker}${gateioPair}`;

    this.socket.subscribe(
      key,
      {
        event: SendEvent.Subscribe,
        channel: ChanelName.Ticker,
        payload: [gateioPair],
      },
      callback,
      onError,
    );
  }

  private unsubscribeFromTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const gateioPair = getPair(base, counter);
    const key = `${ChanelName.Ticker}${gateioPair}`;

    this.socket.unsubscribe(
      key,
      {
        event: SendEvent.Unsubscribe,
        channel: ChanelName.Ticker,
        payload: [gateioPair],
      },
      callback,
    );
  }
}
