import api from '@shared/services/api';
import { Balance, TotalEquivalents } from '@client/domain/balance';

export interface ApiBalance extends Omit<Balance, 'currency' | 'icon'> {
  asset: Currency,
}

export type ApiBalances = {
  data: ApiBalance[];
};

export const getBalances = (): Promise<ApiBalances> => api.get('v1/balances');
export const getBalance = (currency: Currency): Promise<ApiBalance> => api.get(`v1/balances/${currency}`);
export const getTotalBalances = (): Promise<TotalEquivalents> => api.get('v1/balances/total-equivalent');
