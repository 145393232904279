import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material';
import { orange, red } from '@mui/material/colors';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DistanceToNow from '@shared/components/DistanceToNow';
import getRandomInt from '@shared/utils/random';
import Side from '@shared/components/RequestSide';
import Currency from '@shared/components/Currency';
import RequestPrice from '@shared/components/RequestPrice';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';
import CircularProgressWithLabel from '@shared/components/CircularProgress';
import { ChipStatus } from '@shared/components/RequestStatus';
import {
  Requests,
  SavedRequest,
  RequestStatus,
  DeadlineUrgency,
  getDeadlineUrgency,
} from '@shared/domain/request';
import ExchangeAccountLabel from '@shared/components/ExchangeAccount';

interface InfiniteRequestsTableSkeletonProps {
  rows: number,
  showClient?: boolean,
}

const rowColor = (request: SavedRequest) => {
  const urgency = getDeadlineUrgency(request);

  switch (true) {
    case urgency === DeadlineUrgency.Error:
      return alpha(red[500], 0.05);
    case urgency === DeadlineUrgency.Warning || !!request.warning:
      return alpha(orange[500], 0.05);
    default:
      return undefined;
  }
};

export const InfiniteRequestsTableSkeleton: React.FC<InfiniteRequestsTableSkeletonProps> = ({
  rows,
  showClient,
}) => (
  <List>
    {[...Array(rows)].map((row, index) => (
      <Fragment key={index /* eslint-disable-line react/no-array-index-key */}>
        <ListItemButton>
          <ListItemAvatar sx={{ minWidth: '54px' }}>
            <Skeleton sx={{ borderRadius: '15px' }} variant="rectangular" width={38} height={20} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={(
              <>
                {showClient ? <Skeleton width={getRandomInt(90, 120)} /> : null}
                <Skeleton width={getRandomInt(70, 90)} />
              </>
            )}
            secondary={(
              <Skeleton width={getRandomInt(70, 90)} />
            )}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Skeleton variant="text" width={getRandomInt(50, 100)} />
            <Skeleton variant="text" width={getRandomInt(30, 50)} />
            <Skeleton variant="text" width={getRandomInt(50, 70)} />
          </Box>
        </ListItemButton>
        {index < rows - 1 && <Divider component="li" />}
      </Fragment>
    ))}
  </List>
);

InfiniteRequestsTableSkeleton.defaultProps = {
  showClient: false,
};

interface InfiniteRequestsTableProps {
  noRequestsTitle: string,
  noBalancesTitle?: string,
  createNewRequestTitle?: string,
  requests?: Requests,
  balances?: CurrencyAmount,
  isLoading: boolean,
  loadMore: () => void,
  startWizard?: () => void,
  isLoadingMore: boolean,
  showClient?: boolean,
}

const InfiniteRequestsTable: React.FC<InfiniteRequestsTableProps> = ({
  noRequestsTitle,
  noBalancesTitle,
  createNewRequestTitle,
  requests,
  balances,
  isLoading,
  loadMore,
  startWizard,
  isLoadingMore,
  showClient,
}) => {
  const { ref, inView } = useInView({
    rootMargin: '100px', // Start loading 100px before end of page
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onOpen = useCallback((request: SavedRequest) => {
    navigate(`/requests/${request.uuid}`);
  }, [navigate]);
  const noBalances = !Number(balances);

  React.useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView]);

  return (
    <NonFlickeringLoader
      isLoading={isLoading}
      loadingElement={<InfiniteRequestsTableSkeleton rows={5} showClient={showClient} />}
      noData={!requests?.total}
      noDataElement={(
        <Box p={2}>
          <Typography align="center" variant="h5" component="h2" gutterBottom>
            {noRequestsTitle}
          </Typography>
          {createNewRequestTitle && startWizard && !noBalances ? (
            <Button sx={{ mt: 1 }} variant="outlined" fullWidth onClick={() => startWizard()}>
              {createNewRequestTitle}
            </Button>
          ) : null}
          {noBalancesTitle && noBalances ? (
            <Typography align="center" variant="subtitle1" component="h3" gutterBottom color="text.secondary">
              {noBalancesTitle}
            </Typography>
          ) : null}
        </Box>
      )}
    >
      <>
        <List disablePadding>
          {requests?.data.map((request, index) => (
            <Fragment key={request.uuid}>
              <ListItemButton
                onClick={() => onOpen(request)}
                sx={{ backgroundColor: rowColor(request) }}
              >
                <ListItemAvatar sx={{ minWidth: '54px' }}>
                  <Side value={request.side} label={t(`shared.requestSide.${request.side}`)} />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <>
                      {showClient ? request.client.name : null}
                      <Box>
                        <Currency amount={request.requestedBase} currency={`${request.baseCurrency} @`} />
                      </Box>
                    </>
                )}
                  secondary={(
                    <>
                      <Currency
                        amount={<RequestPrice request={request} />}
                        currency={request.counterCurrency}
                      />
                      {
                      request.commonCurrency && (
                        <Typography
                          variant="body2"
                          color="grey.400"
                        >
                          via&nbsp;
                          {request.commonCurrency}
                        </Typography>
                      )
                    }
                    </>
                )}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <ExchangeAccountLabel
                    value={request.exchangeAccount}
                    sx={{
                      mb: 1,
                      fontSize: '0.75rem',
                    }}
                  />
                  {
                  request.status === RequestStatus.InProgress ? (
                    <CircularProgressWithLabel value={request.progress} />
                  ) : (
                    <ChipStatus value={request.status} />
                  )
                }
                  <Typography color="text.secondary" variant="body1" sx={{ fontSize: '0.75rem', mt: '6px' }}>
                    #
                    {request.number}
                    <Typography component="span" color="grey.600" mx={0.5}>|</Typography>
                    <DistanceToNow
                      date={new Date(request.createdAt)}
                      options={{ addSuffix: true }}
                    />
                  </Typography>
                </Box>
              </ListItemButton>
              {index < requests.data.length - 1 && <Divider component="li" />}
            </Fragment>
          ))}
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'center' }} ref={ref}>
          {isLoadingMore ? (
            <CircularProgress size={24} sx={{ mb: 2 }} />
          ) : null}
        </Box>
      </>
    </NonFlickeringLoader>
  );
};

InfiniteRequestsTable.defaultProps = {
  noBalancesTitle: undefined,
  requests: undefined,
  balances: undefined,
  createNewRequestTitle: undefined,
  startWizard: undefined,
  showClient: false,
};

export default InfiniteRequestsTable;
