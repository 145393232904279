import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';

export const generateId = () => Math.random().toString(16).substring(2, 11);

export const log = (
  error: unknown,
  options?: { level: SeverityLevel },
) => {
  const id = generateId();
  Sentry.captureException(error, {
    tags: { error_id: id },
    ...options,
  });
  return id;
};

export const logFatal = (error: unknown) => log(error, { level: 'fatal' });
export const logError = (error: unknown) => log(error, { level: 'error' });
export const logWarning = (error: unknown) => log(error, { level: 'warning' });
