import { LoadProfileClientService, LoadAccountsClientService } from '@client/application/ports';
import { getProfile, getAccounts } from '@client/services/api/profile';

export function useLoadProfileClient(): LoadProfileClientService {
  return {
    load: getProfile,
  };
}

export function useLoadAccountsClient(): LoadAccountsClientService {
  return {
    load: getAccounts,
  };
}
