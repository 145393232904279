import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button, { ButtonProps } from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface LoadingButtonProps {
  loading: boolean,
}

const TransparentTextButton = styled(Button, {
  shouldForwardProp: (props) => props !== 'loading',
})<{ loading: boolean; }>(({ loading }) => (
  loading ? {
    color: 'transparent',
    '&.Mui-disabled': {
      color: 'transparent',
    },
  } : {}
));

const LoaderContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  visibility: 'visible',
  display: 'flex',
  left: '50%',
  transform: 'translate(-50%)',
  color: theme.palette.action.disabled,
}));

const LoadingButton: React.FC<LoadingButtonProps & ButtonProps> = ({
  disabled,
  loading,
  children,
  ...props
}) => (
  // span wrapper required to fix this issue https://github.com/facebook/react/issues/11538
  /* eslint-disable react/jsx-props-no-spreading */
  <TransparentTextButton
    disabled={disabled || loading}
    loading={loading}
    {...props}
  >
    {loading && (
      <LoaderContainer>
        <CircularProgress size={24} color="inherit" />
      </LoaderContainer>
    )}
    <span>{children}</span>
  </TransparentTextButton>
);

export default LoadingButton;
