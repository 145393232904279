import * as React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import '@fontsource/roboto';

import { useSettingsState } from '@client/services/context/settings';
import { Theme } from '@client/domain/settings';

interface Props {
  children: React.ReactNode;
}

const ThemeContext: React.FC<Props> = ({ children }) => {
  const { settings } = useSettingsState();
  const theme = createTheme({
    palette: {
      mode: settings.theme,
      ...(settings.theme === Theme.Dark
        ? {
          background: {
            paper: grey[900],
            default: grey[900],
          },
        }
        : {}),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeContext;
