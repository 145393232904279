import * as React from 'react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ResetPasswordForm from '@shared/components/ResetPasswordForm';
import { useResetPassword } from '@client/application/useCases/auth';
import { Password, ResetPasswordToken } from '@client/domain/auth';

const PasswordPage = () => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: ResetPasswordToken }>();
  const {
    resetPassword,
    isUpdated,
    isLoading,
    error,
  } = useResetPassword();
  const onSubmit = useCallback(
    ({ password }: { password: Password }) => {
      resetPassword({
        password,
        token: token!,
      });
    },
    [resetPassword, token],
  );

  return (
    <ResetPasswordForm
      error={error?.message}
      isLoading={isLoading}
      showSuccessMessage={isUpdated}
      onSubmit={onSubmit}
      title={t('password.newPassword')}
      successMessage={t('password.successMessage')}
      goToLoginLabel={t('password.goToLogin')}
      passwordLabel={t('password.password')}
      repeatPasswordLabel={t('password.repeatPassword')}
      submitLabel={t('password.submit')}
      validationTitles={[
        t('password.validation.minChars', { count: 8 }),
        t('password.validation.minNumbers', { count: 1 }),
        t('password.validation.minUpperCases', { count: 1 }),
      ]}
    />
  );
};

export default PasswordPage;
