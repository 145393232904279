import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import { Container } from '@mui/material';
import { WizardData, WizardState, WizardStep } from '@client/domain/requestWizard';
import useCurrencySearch from '@client/application/useCases/currencySearch';
import { CurrencyType } from '@client/domain/currency';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';
import {
  CurrenciesList,
  CurrencyItemSkeleton,
} from '@client/components/RequestWizard/SelectCurrencyPair';

const SelectCommonSide: React.FC<{
  request: Partial<WizardData>,
  isOpen: boolean,
  onClose: () => void,
  onSelect: (updates: Partial<WizardData>) => void
}> = ({
  request,
  isOpen,
  onClose,
  onSelect,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<WizardState[WizardStep.Common]>({
    defaultValues: {
      commonCurrency: '',
    },
  });
  const { commonCurrency } = watch();
  const { t } = useTranslation();
  const { currencies, isLoading } = useCurrencySearch({
    exchangeAccount: request.exchangeAccount,
    side: request.side,
    type: CurrencyType.Common,
    base: request.baseCurrency,
    counter: request.counterCurrency,
    common: commonCurrency,
    triangulation: true,
  });

  const handleSelectCurrency = (currency?: Currency) => {
    setValue('commonCurrency', currency);
    handleSubmit(onSelect)();
  };

  let containerHeight = currencies && currencies.total ? currencies.total * 40 : 80;

  if (containerHeight > 360) {
    containerHeight = 300;
  }

  useEffect(() => {
    if (isOpen) reset();
  }, [isOpen]);

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="bottom"
      sx={{ zIndex: 1300 }}
    >
      <Container
        maxWidth="md"
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSelect)}
      >
        <Controller
          render={({ field: { onChange, ref, ...field } }) => (
            <TextField
              autoFocus
              InputLabelProps={{ required: false }}
              label={t('requestWizard.common.title')}
              variant="standard"
              size="small"
              onChange={(e) => onChange(e.target.value.toUpperCase())}
              inputRef={ref}
              sx={{ my: 1 }}
              fullWidth
              {...field}
            />
          )}
          name="commonCurrency"
          control={control}
          rules={{
            validate: (value) => !!currencies?.data.find(({ currency }) => currency === value),
          }}
        />
      </Container>
      <Container maxWidth="md" sx={{ height: containerHeight, mb: 2 }}>
        <NonFlickeringLoader
          isLoading={isLoading}
          loadingElement={(
            <CurrencyItemSkeleton rows={2} />
          )}
          noData={!currencies?.total}
          noDataElement={(
            <Box p={3}>
              <Typography align="center" variant="subtitle1" component="div" color="text.secondary">
                {t(commonCurrency ? 'requestWizard.common.notFound' : 'requestWizard.common.noData')}
              </Typography>
            </Box>
          )}
        >
          {currencies && (
            <CurrenciesList currencies={currencies} onSelect={handleSelectCurrency} />
          )}
        </NonFlickeringLoader>
      </Container>
    </Drawer>
  );
};

export default SelectCommonSide;
