import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import deepMerge from '@shared/utils/deepMerge';
import * as sharedEnUS from '@shared/i18n/translations/enUS.json';
import * as sharedUkUA from '@shared/i18n/translations/ukUA.json';
import * as enUS from './translations/enUS.json';
import * as ukUA from './translations/ukUA.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': deepMerge(sharedEnUS, enUS),
      'uk-UA': deepMerge(sharedUkUA, ukUA),
    },
    fallbackLng: 'en-US',
  });

i18n.services.formatter?.add('lowercase', (value) => value.toLowerCase());

export default i18n;
