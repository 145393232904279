import { Credentials, ResetPasswordForm } from '@client/domain/auth';
import useAuthClient from '@client/adapters/client/authClientAdapters';
import useAuthStorage from '@client/adapters/store/authStoreAdapters';
import { ApiRequestStateStorageService } from '@client/application/ports';
import useApiRequestStateStorage from '@shared/services/apiRequest';
import { AuthStorageService, AuthClientService } from '../ports';

export function useAuth() {
  const storage: AuthStorageService = useAuthStorage();
  const authClient: AuthClientService = useAuthClient();

  async function logIn(credentials: Credentials): Promise<void> {
    const token = await authClient.logIn(credentials);
    storage.saveToken(token);
  }

  async function logOut(): Promise<void> {
    await authClient.logOut();
    storage.removeToken();
  }

  return {
    isAuthenticated: !!storage.token,
    logIn,
    isLoggingIn: authClient.isLoggingIn,
    logOut,
    isLoggingOut: authClient.isLoggingOut,
  };
}

export function useResetPassword() {
  const {
    execute,
    isLoading,
    error,
  } : ApiRequestStateStorageService = useApiRequestStateStorage();

  const storage: AuthStorageService = useAuthStorage();
  const authClient: AuthClientService = useAuthClient();

  async function resetPassword(resetPasswordForm: ResetPasswordForm): Promise<void> {
    execute(() => authClient.resetPassword(resetPasswordForm), storage.setPasswordIsUpdated);
  }

  return {
    isUpdated: storage.isPasswordUpdated,
    isLoading,
    error,
    resetPassword,
  };
}
