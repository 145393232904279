import * as React from 'react';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogTitleWithCloseProps extends DialogTitleProps {
  onClose: () => void
}

const DialogTitleWithClose: React.FC<DialogTitleWithCloseProps> = ({
  children,
  onClose,
  ...other
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DialogTitle sx={{ paddingRight: 7 }} {...other}>
    {children}
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
);

export default DialogTitleWithClose;
