import Decimal from 'decimal.js';

// Use it if the value from input starts with "."
// For example in the request wizard base amount ".0001" - it will cause parsing error on back end
export default function getDecimalString(value: any) {
  if (typeof value === 'string' || typeof value === 'number') {
    return new Decimal(value).toString();
  }
  return value;
}
