import { MiniBook, MiniBookOrder } from '@shared/domain/orderBook';
import withPrecision from '@shared/utils/withPrecision';

export type BitfinexChanelId = string;
export type BitfinexPair = string;
export type BitfinexPrecision = 'P0' | 'P1' | 'P2' | 'P3' | 'P4';
export type BitfinexCount = number;
export type BitfinexPrice = number;
export type BitfinexAmount = number;
export type BitfinexBookOrder = [BitfinexPrice, BitfinexCount, BitfinexAmount];
export type BitfinexTicker = [number, number, number, number, number, number, BitfinexPrice];
export type CurrencySymbolMap = [Currency, Currency][];
export type CurrencyPairsList = CurrencyPair[];

export const bitfinexItemToBookItem = (item: BitfinexBookOrder): MiniBookOrder => ({
  price: item[0],
  amount: withPrecision(Math.abs(item[2]), 8),
});

export const insertDelta = ({ bids, asks }: MiniBook, delta: BitfinexBookOrder) => {
  const [price, count, amount] = delta;
  let newBids = bids;
  let newAsks = asks;
  let arrayToUpdate;
  let compareFn;

  // is it bids or asks
  if (amount > 0) {
    newBids = [...newBids];
    arrayToUpdate = newBids;
    compareFn = (a: MiniBookOrder, b: MiniBookOrder) => b.price - a.price;
  } else {
    newAsks = [...newAsks];
    arrayToUpdate = newAsks;
    compareFn = (a: MiniBookOrder, b: MiniBookOrder) => a.price - b.price;
  }

  const index = arrayToUpdate.findIndex((item) => item.price === price);

  if (index === -1) {
    // if the new item - add to the end and sort
    arrayToUpdate.push(bitfinexItemToBookItem(delta));
    arrayToUpdate.sort(compareFn);
  } else if (count === 0) {
    // if count 0 - remove item from the array
    arrayToUpdate.splice(index, 1);
  } else {
    // if item already exists - update it
    arrayToUpdate[index] = bitfinexItemToBookItem(delta);
  }

  return {
    bids: newBids,
    asks: newAsks,
  };
};
export const getSnapshot = (snapshot: BitfinexBookOrder[]) => {
  const items = snapshot.map(bitfinexItemToBookItem);

  return ({
    bids: items.slice(0, items.length / 2),
    asks: items.slice(-items.length / 2),
  });
};

export const cutBook = (book: MiniBook, length?: number) => ({
  bids: book.bids.slice(0, length),
  asks: book.asks.slice(0, length),
});

export const getPossibleSymbol = (
  currency: Currency,
  currencySymbolMap: CurrencySymbolMap,
) => [
  currency,
  ...currencySymbolMap
    .filter(([, cur]) => cur.toUpperCase() === currency)
    .map(([symbol]) => symbol),
];

export const findPair = ({
  base,
  counter,
  currencySymbolMap,
  currencyPairsList,
}: {
  base: Currency,
  counter: Currency,
  currencySymbolMap: CurrencySymbolMap,
  currencyPairsList: CurrencyPairsList,
}) => {
  const possibleBaseSymbols = getPossibleSymbol(base, currencySymbolMap);
  const possibleCounterSymbols = getPossibleSymbol(counter, currencySymbolMap);

  return currencyPairsList.find((validPair) => (
    possibleBaseSymbols.some((symbol) => validPair.startsWith(symbol))
    && possibleCounterSymbols.some((symbol) => validPair.endsWith(symbol))
  ));
};
