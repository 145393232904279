import * as yup from 'yup';
import transformCurrencyAmount from '@shared/validation/currencyAmountToNumber';
import { ExchangeAccount } from '@shared/domain/account';
import { RequestSide } from '@client/domain/request';

const validation = yup.object({
  exchangeAccount: yup.string()
    .oneOf(Object.values(ExchangeAccount))
    .required(),
  side: yup.string()
    .oneOf([RequestSide.Buy, RequestSide.Sell])
    .required(),
  baseCurrency: yup.string()
    .required(),
  counterCurrency: yup.string()
    .required(),
  requestedBase: yup.number()
    .transform(transformCurrencyAmount)
    .moreThan(0),
  price: yup.number()
    .moreThan(0)
    .required(),
});

export default validation;
