import * as React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = ({ to }: { to?: string | number }) => (
  <IconButton component={Link} to={to as any}>
    <ArrowBackIcon sx={{ color: 'grey.400' }} />
  </IconButton>
);

BackButton.defaultProps = {
  to: -1,
};

export default BackButton;
