import * as React from 'react';
import Typography from '@mui/material/Typography';
import isEmpty from '@shared/utils/isEmpty';
import { TypographyVariant } from '@mui/material';

type Align = 'right' | 'bottom';

interface CurrencyProps {
  amount: any,
  currency: Currency,
  align?: Align,
  color?: string,
  isMarket?: boolean,
  variant?: TypographyVariant,
  currencyVariant?: TypographyVariant,
}

const Currency: React.FC<CurrencyProps> = ({
  amount,
  currency,
  align,
  color,
  isMarket,
  variant,
  currencyVariant,
}) => (
  !isEmpty(amount) ? (
    <>
      <Typography
        variant={variant}
        sx={{ display: 'inline-block', overflowWrap: 'anywhere' }}
        mr={align === 'right' ? 1 : 0}
        color={color}
      >
        {isMarket ? 'MARKET' : amount}
      </Typography>
      <Typography
        variant={align === 'right' ? variant : currencyVariant}
        sx={{ display: align === 'right' ? 'inline-block' : 'block' }}
        color="text.secondary"
      >
        {currency}
      </Typography>
    </>
  ) : null
);

Currency.defaultProps = {
  align: 'right',
  color: undefined,
  isMarket: false,
  variant: 'body2',
  currencyVariant: 'caption',
};

export default Currency;
