export default function createThrottle(fn: Function, ms: number) {
  let isCalled = false;

  return function throttle(this: any, ...args: any[]) {
    if (!isCalled) {
      fn.apply(this, args);
      isCalled = true;
      setTimeout(() => {
        isCalled = false;
      }, ms);
    }
  };
}
