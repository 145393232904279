import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from '@shared/hooks/usePrevious';
import { ExchangeAccount } from '@shared/domain/account';
import Ticker from '@shared/components/Ticker';
import { useCurrencyTicker } from '@shared/application/useCases/currencyTicker';
import { RequestSide } from '@shared/domain/request';

interface CurrencyTickerProps {
  onInitiate?: (initValue: number) => void,
  onClick?: (value: number) => void,
  base: Currency,
  counter: Currency,
  common?: Currency,
  exchangeAccount: ExchangeAccount,
  side?: RequestSide,
}

export const CurrencyTicker: React.FC<CurrencyTickerProps> = ({
  onInitiate, onClick, base, counter, common, exchangeAccount, side,
}) => {
  const { t } = useTranslation();
  const { ticker, isLoading, error } = useCurrencyTicker({
    base,
    counter,
    common,
    exchangeAccount,
    side,
  });

  const previousTicker = usePrevious<number | undefined>(ticker);

  useEffect(() => {
    if (!previousTicker && ticker && onInitiate) {
      onInitiate(ticker);
    }
  }, [ticker, previousTicker, onInitiate]);

  return (
    <Ticker
      title={`${t('requestWizard.total.ticker.title')}: `}
      ticker={ticker}
      counter={counter}
      isLoading={isLoading}
      error={error && t('requestWizard.total.ticker.error')}
      onClick={onClick}
      align="right"
    />
  );
};

CurrencyTicker.defaultProps = {
  onInitiate: undefined,
  onClick: undefined,
  common: undefined,
  side: undefined,
};

export default CurrencyTicker;
