import { MiniBook } from '@shared/domain/orderBook';

import {
  SubscriptionOptions,
  SubscriptionCallback,
  SubscriptionError,
  SubscriptionStream,
  ExchangeSocketsClient,
} from '@shared/services/sockets/index';
import InternalError from '@shared/errors/InternalError';
import SocketClient from '@shared/services/sockets/socketClient';
import {
  CoinbaseBookOrder,
  CoinbaseBookUpdate,
  CoinbasePair,
  coinbaseItemToBookItem,
  updateBook,
  getCoinbasePair,
  cutBook,
} from '@shared/domain/coinbase';
import createThrottle from '@shared/utils/throttle';

export enum SendEvent {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export enum ChanelName {
  Ticker = 'ticker',
  Depth = 'level2_batch',
}

export enum ReceiveEvent {
  Subscriptions = 'subscriptions',
  Error = 'error',
  Ticker = 'ticker',
  DepthSnapshot = 'snapshot',
  DepthUpdate = 'l2update',
}

export type Chanel = ChanelName | {
  name: ChanelName,
  product_ids: CoinbasePair[]
};

export type SubscribeEventMessage = {
  type: SendEvent.Subscribe,
  channels: Chanel[],
  product_ids: CoinbasePair[]
};

export type UnsubscribeEventMessage = {
  type: SendEvent.Unsubscribe,
  channels: Chanel[],
  product_ids: CoinbasePair[]
};

export type SuccessEventMessage = {
  type: ReceiveEvent.Subscriptions,
  channels: Chanel[]
};

export type ErrorEventMessage = {
  type: ReceiveEvent.Error,
  message: string
};

export type TickerMessage = {
  type: ReceiveEvent.Ticker;
  product_id: string,
  price: string
};

export type DepthSnapshotMessage = {
  type: ReceiveEvent.DepthSnapshot;
  product_id: string,
  bids: CoinbaseBookOrder[],
  asks: CoinbaseBookOrder[],
};

export type DepthUpdateMessage = {
  type: ReceiveEvent.DepthUpdate;
  product_id: string,
  changes: CoinbaseBookUpdate[],
};

export type Message = (
    DepthUpdateMessage
    | DepthSnapshotMessage
    | TickerMessage
    | ErrorEventMessage
    | SuccessEventMessage
    | SubscribeEventMessage
    | UnsubscribeEventMessage
);

export class CoinbaseProClient implements ExchangeSocketsClient {
  protected socket: SocketClient<Message>;

  private books: { [key: string]: MiniBook } = {};

  private intervals: { [key: string]: Function } = {};

  constructor({ url = 'wss://ws-feed.exchange.coinbase.com' } = {}) {
    this.socket = new SocketClient<Message>({
      url,
      handleMessage: this.handleMessage.bind(this),
    });
  }

  private handleTicker(message: Message) {
    if (message.type !== ReceiveEvent.Ticker) return;

    const key = ChanelName.Ticker + message.product_id;

    if (!this.intervals[key]) {
      this.intervals[key] = createThrottle((price: string) => {
        this.socket.subscriptions[key]?.callbacks.map(
          (subscription) => subscription(Number(price)),
        );
      }, 1000);
    }

    this.intervals[key](message.price);
  }

  private handleDepthSnapshot(message: Message) {
    if (message.type !== ReceiveEvent.DepthSnapshot) return;

    const key = ChanelName.Depth + message.product_id;
    const { bids, asks } = message;

    this.books[key] = {
      asks: asks.map(coinbaseItemToBookItem),
      bids: bids.map(coinbaseItemToBookItem),
    };

    if (!this.intervals[key]) {
      this.intervals[key] = createThrottle(() => {
        this.socket.subscriptions[key]?.callbacks.map(
          (subscription) => subscription(this.books[key] && cutBook(this.books[key], 20)),
        );
      }, 1000);
    }

    this.intervals[key]();
  }

  private handleDepthUpdate(message: Message) {
    if (message.type !== ReceiveEvent.DepthUpdate) return;

    const key = ChanelName.Depth + message.product_id;
    const { changes } = message;

    this.books[key] = changes.reduce(
      (book, change) => book && updateBook(change, book),
      this.books[key],
    );

    if (!this.intervals[key]) {
      this.intervals[key] = createThrottle(() => {
        this.socket.subscriptions[key]?.callbacks.map(
          (subscription) => subscription(this.books[key] && cutBook(this.books[key], 20)),
        );
      }, 1000);
    }

    this.intervals[key]();
  }

  private handleMessage(message: Message) {
    if (message.type === ReceiveEvent.Subscriptions) {
      // Subscription success messages
      return;
    }

    switch (message.type) {
      case ReceiveEvent.Ticker:
        this.handleTicker(message);
        break;
      case ReceiveEvent.DepthSnapshot:
        this.handleDepthSnapshot(message);
        break;
      case ReceiveEvent.DepthUpdate:
        this.handleDepthUpdate(message);
        break;
      default:
    }
  }

  public subscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.subscribeToOrderBooks(callback, options, onError);
        break;
      case SubscriptionStream.Ticker:
        this.subscribeToTicker(callback, options, onError);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  public unsubscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.unsubscribeFromOrderBooks(callback, options);
        break;
      case SubscriptionStream.Ticker:
        this.unsubscribeFromTicker(callback, options);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  private subscribeToOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const coinbasePair = getCoinbasePair(base, counter);
    const key = `${ChanelName.Depth}${coinbasePair}`;

    this.socket.subscribe(
      key,
      {
        type: SendEvent.Subscribe,
        channels: [ChanelName.Depth],
        product_ids: [coinbasePair],
      },
      callback,
      onError,
    );
  }

  private unsubscribeFromOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const coinbasePair = getCoinbasePair(base, counter);
    const key = `${ChanelName.Depth}${coinbasePair}`;

    this.socket.unsubscribe(
      key,
      {
        type: SendEvent.Unsubscribe,
        channels: [ChanelName.Depth],
        product_ids: [coinbasePair],
      },
      callback,
    );
  }

  private subscribeToTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const coinbasePair = getCoinbasePair(base, counter);
    const key = `${ChanelName.Ticker}${coinbasePair}`;

    this.socket.subscribe(
      key,
      {
        type: SendEvent.Subscribe,
        channels: [ChanelName.Ticker],
        product_ids: [coinbasePair],
      },
      callback,
      onError,
    );
  }

  private unsubscribeFromTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const coinbasePair = getCoinbasePair(base, counter);
    const key = `${ChanelName.Ticker}${coinbasePair}`;

    this.socket.unsubscribe(
      key,
      {
        type: SendEvent.Unsubscribe,
        channels: [ChanelName.Ticker],
        product_ids: [coinbasePair],
      },
      callback,
    );
  }
}
