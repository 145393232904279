import { useQuery } from '@tanstack/react-query';
import { BalancesStorageService, BalanceStorageService } from '@client/application/ports';
import { useBalancesClient, useBalanceClient } from '@client/adapters/client/balancesClientAdapters';
import useFiltersStorage from '@client/adapters/store/filtersStoreAdapters';
import Decimal from 'decimal.js';
import { ExchangeAccount } from '@shared/domain/account';

export function useBalancesStorage({
  hideSmallBalances,
  smallBalancesEquivalent,
  equivalentCurrency,
}: {
  hideSmallBalances?: boolean,
  smallBalancesEquivalent?: number,
  equivalentCurrency?: Currency,
} = {}): BalancesStorageService {
  const { filterValues } = useFiltersStorage();
  const exchangeAccount = filterValues?.exchangeAccount as ExchangeAccount || 'all_accounts';
  const search = filterValues?.search;
  const client = useBalancesClient();
  const {
    isLoading,
    error,
    data: balances,
  } = useQuery(
    ['balances'],
    () => client.load(),
  );

  const filteredBalances = balances?.data.filter((balance) => {
    const accountBalance = balance[exchangeAccount];

    if (!balance.currency.toLowerCase().includes(search?.toLowerCase() || '')) {
      return false;
    }

    if (!accountBalance || new Decimal(accountBalance.amount).isZero()) {
      return false;
    }

    if (hideSmallBalances && equivalentCurrency && smallBalancesEquivalent) {
      const equivalent = accountBalance.equivalents[equivalentCurrency];
      const equivalentIsSmall = new Decimal(equivalent).abs().lessThan(smallBalancesEquivalent);

      return !equivalentIsSmall;
    }
    return true;
  }) || [];

  return {
    balances: {
      data: filteredBalances,
      total: filteredBalances.length,
    },
    isLoading,
    error: error as Error | undefined,
  };
}

export function useBalanceStorage(currency: Currency): BalanceStorageService {
  const client = useBalanceClient();
  const {
    isLoading,
    error,
    data,
  } = useQuery(
    ['balances', currency],
    () => client.load(currency),
  );

  return {
    balance: data,
    isLoading,
    error: error as Error | undefined,
  };
}
