import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

interface TotalBalancesProps {
  value: string,
  total?: number,
  onChange: (value: string) => void,
}

const SearchInput: React.FC<TotalBalancesProps> = ({ value, total, onChange }) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <TextField
      label={t('balances.search.label', { count: total })}
      variant="filled"
      fullWidth
      size="small"
      onChange={handleChange}
      value={value}
    />
  );
};

SearchInput.defaultProps = {
  total: 0,
};

export default SearchInput;
