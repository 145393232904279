import * as React from 'react';
import {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import LoadingButton from '@shared/components/LoadingButton';
import parseRequestError from '@shared/services/parseRequestError';
import { RequestSide, RequestType } from '@client/domain/request';
import { CurrencyType } from '@client/domain/currency';
import requestValidation from '@client/adapters/validation/requestValidationAdapters';
import { useCost } from '@client/application/useCases/requestWizard';
import {
  defaultWizardState,
  WizardData,
  WizardStep,
} from '@client/domain/requestWizard';
import CommentInput from './CommentInput';
import DeadlineInput from './DeadlineInput';
import LimitRequest from './LimitRequest';
import ScaledRequest from './ScaledRequest';
import MarketRequest from './MarketRequest';
import Amounts from './Amounts';

const SelectRequestTotal: React.FC<{
  request: Partial<WizardData>,
  onSubmit: (updates: Partial<WizardData>) => Promise<void>,
  isLoading: boolean,
  onGoBack: (input: CurrencyType) => void,
}> = ({
  request,
  onSubmit,
  isLoading,
  onGoBack,
}) => {
  const { t } = useTranslation();
  const [maxBase, setMaxBase] = useState<CurrencyAmount>();
  // "general: void" needed for general errors with certain field name
  const form = useForm<WizardData & { general: void }>({
    mode: 'onChange',
    context: { maxBase },
    resolver: yupResolver(requestValidation),
    defaultValues: defaultWizardState[WizardStep.Total],
  });
  const {
    formState,
    watch,
    setError,
    handleSubmit,
    register,
    trigger,
  } = form;
  const { errors, isValid } = formState;
  const formValues = watch();
  const { cost } = useCost(useMemo(() => ({ ...request, ...formValues }), [request, formValues]));

  useEffect(() => {
    setMaxBase(cost?.max.toString());
  }, [cost?.max]);

  useEffect(() => {
    if (formState.isDirty) trigger();
  }, [maxBase]);

  const handleFormSubmit = useCallback((updates: Partial<WizardData>) => (
    onSubmit(updates).catch((error) => parseRequestError({ error, setError }))
  ), [onSubmit]);

  if (!request.baseCurrency || !request.counterCurrency) return null;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      <DialogContent sx={{ py: 0, px: 2 }}>
        <form
          id="total-form"
          autoComplete="off"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <FormProvider {...form}>
            <Amounts request={request} onGoBack={onGoBack} />
            {formValues.type === RequestType.Limit && (
              <LimitRequest request={request} errors={errors} />
            )}
            {formValues.type === RequestType.Scaled && (
              <ScaledRequest request={request} errors={errors} />
            )}
            {formValues.type === RequestType.Market && (
              <MarketRequest request={request} />
            )}
            <DeadlineInput />
            <CommentInput {...register('comment')} />
          </FormProvider>
        </form>
        {errors.general && <Alert severity="error" sx={{ mt: 2 }}>{errors.general.message}</Alert>}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          disabled={!isValid}
          color={
            request.side === RequestSide.Buy
              ? 'success'
              : 'error'
          }
          form="total-form"
          fullWidth
          variant="contained"
          type="submit"
          loading={isLoading}
        >
          {
            request.side === RequestSide.Buy
              ? t('requestWizard.total.buy.title')
              : t('requestWizard.total.sell.title')
          }
          &nbsp;
          {request.baseCurrency}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default SelectRequestTotal;
