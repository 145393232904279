import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Footer from '@shared/components/Footer';
import { useAuth } from '@client/application/useCases/auth';
import ProfileData from './ProfileData';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { logOut } = useAuth();

  return (
    <Container maxWidth="md" sx={{ p: 2 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <ProfileData />
        <Button
          fullWidth
          variant="contained"
          onClick={logOut}
        >
          {t('profile.logout')}
        </Button>
      </Paper>
      <Footer />
    </Container>
  );
};

export default ProfilePage;
