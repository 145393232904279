import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@mui/material/Drawer';
import { Container } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { RequestSide } from '@client/domain/request';
import { WizardData } from '@client/domain/requestWizard';

const SelectRequestSide: React.FC<{
  isOpen: boolean,
  onClose: () => void,
  onSelect: (updates: Partial<WizardData>) => void
}> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="bottom"
    >
      <Container disableGutters maxWidth="md">
        <List
          subheader={(
            <ListSubheader
              sx={{
                // The default backgroundColor is black for dark mode.
                // transparent allows to use background color of Paper.
                backgroundColor: 'transparent',
              }}
              color="inherit"
            >
              {t('requestWizard.side.title')}
            </ListSubheader>
          )}
        >
          <ListItemButton onClick={() => onSelect({ side: RequestSide.Buy })}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ArrowCircleUpIcon sx={{ color: 'success.light', height: 32, width: 32 }} />
            </ListItemIcon>
            <ListItemText primary="Buy" primaryTypographyProps={{ variant: 'h5' }} />
          </ListItemButton>
          <ListItemButton onClick={() => onSelect({ side: RequestSide.Sell })}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ArrowCircleDownIcon sx={{ color: 'error.light', height: 32, width: 32 }} />
            </ListItemIcon>
            <ListItemText primary="Sell" primaryTypographyProps={{ variant: 'h5' }} />
          </ListItemButton>
        </List>
      </Container>
    </Drawer>
  );
};

export default SelectRequestSide;
