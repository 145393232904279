import * as React from 'react';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import endOfDay from 'date-fns/endOfDay';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import { styled } from '@mui/material/styles';
import parseRequestError from '@shared/services/parseRequestError';
import LoadingButton from '@shared/components/LoadingButton';
import { DeadlineForm } from '@shared/domain/request';

interface DeadlineFormProps {
  onSubmit: (data: DeadlineForm) => Promise<void>,
  onCancel: () => void,
  isLoading: boolean,
  defaultValue?: string,
  deadlineLabel?: string,
  submitLabel?: string,
  cancelLabel?: string,
  clearLabel?: string,
}

const DatePickerWrapper = styled(Box)({
  '.MuiPickerStaticWrapper-root': {
    backgroundColor: 'transparent',
  },
});

/* eslint-disable react/jsx-props-no-spreading */
const DeadlineFormComponent: React.FC<DeadlineFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  defaultValue,
  deadlineLabel,
  submitLabel,
  cancelLabel,
  clearLabel,
}) => {
  const { t } = useTranslation();
  const [datePickerError, setDatePickerError] = useState<string | null>(null);
  // "general: void" needed for general errors with certain field name
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: {
      errors,
    },
  } = useForm<DeadlineForm & { general: void }>({
    defaultValues: {
      deadline: defaultValue || null,
    },
  });

  const handleFormSubmit = useCallback(({ deadline }: DeadlineForm) => (
    onSubmit({
      deadline: deadline ? endOfDay(new Date(deadline)).toISOString() : undefined,
    })
      .then(() => onCancel())
      .catch((error) => parseRequestError({ error, setError }))
  ), [onSubmit, onCancel]);

  return (
    <Dialog open>
      <DatePickerWrapper
        component="form"
        id="deadline-form"
        onSubmit={(event: React.FormEvent<HTMLDivElement>) => {
          event.stopPropagation();
          return handleSubmit(handleFormSubmit)(event);
        }}
      >
        <Controller
          render={({ field: { ref, onChange, ...field } }) => (
            <StaticDatePicker
              toolbarTitle={deadlineLabel}
              disableHighlightToday
              disablePast
              mask="__-__-____"
              inputFormat="dd-MM-yyyy"
              inputRef={ref}
              renderInput={(params) => <TextField {...params} />}
              onChange={onChange}
              onError={(reason) => setDatePickerError(reason)}
              {...field}
            />
          )}
          name="deadline"
          control={control}
        />
        {datePickerError && <Alert severity="error" sx={{ mx: 3 }}>{t(`invalid.${datePickerError}`)}</Alert>}
        {errors.deadline && <Alert severity="error" sx={{ mx: 3 }}>{errors.deadline.message}</Alert>}
        {errors.general && <Alert severity="error" sx={{ mx: 3 }}>{errors.general.message}</Alert>}
      </DatePickerWrapper>
      <DialogActions>
        <Button onClick={() => setValue('deadline', null)} sx={{ mr: 'auto' }}>
          {clearLabel}
        </Button>
        <Button onClick={onCancel}>
          {cancelLabel}
        </Button>
        <LoadingButton
          form="deadline-form"
          type="submit"
          loading={isLoading}
          variant="outlined"
          disabled={!!datePickerError}
        >
          {submitLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeadlineFormComponent.defaultProps = {
  defaultValue: undefined,
  deadlineLabel: 'Deadline',
  cancelLabel: 'Cancel',
  submitLabel: 'Save',
  clearLabel: 'Clear',
};

export default DeadlineFormComponent;
