import * as React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

export type SideVariants = 'inline' | 'outlined';

export const sideColors = {
  buy: 'success.light',
  sell: 'error.light',
};

interface SideProps {
  variant?: SideVariants,
  value: 'buy' | 'sell',
  label?: string
}

const Side: React.FC<SideProps> = ({
  value,
  label,
  variant,
}) => (variant === 'inline' ? (
  <Typography
    sx={{ textTransform: 'uppercase' }}
    color={sideColors[value]}
  >
    {label || value}
  </Typography>
) : (
  <Chip
    size="small"
    color="primary"
    sx={{ backgroundColor: sideColors[value] }}
    label={label || value}
  />
));

Side.defaultProps = {
  label: undefined,
  variant: 'outlined',
};

export default Side;
