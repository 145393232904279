import * as React from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BalanceItem, { BalanceItemSkeleton } from '@client/components/BalanceItem';
import { Balances } from '@client/domain/balance';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';

const ScrollableContainer = styled(Box)<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  overflow: 'auto',
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: fullWidth ? '100%' : '75% auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr auto 1fr',
    gridAutoFlow: 'unset',
    gridAutoColumns: 'unset',
  },
}));

interface BalancesTableSkeletonProps {
  columns: number,
  rows: number
}

export const BalancesTableSkeleton: React.FC<BalancesTableSkeletonProps> = ({ columns, rows }) => (
  <ScrollableContainer fullWidth={columns === 1}>
    {[...Array(columns)].map((col, colIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={colIndex}>
        <List disablePadding>
          {[...Array(rows)].map((row, rowIndex) => (
            <BalanceItemSkeleton
              // eslint-disable-next-line react/no-array-index-key
              key={rowIndex}
            />
          ))}
        </List>
        {colIndex < columns - 1 && (<Divider orientation="vertical" />)}
      </Fragment>
    ))}
  </ScrollableContainer>
);

interface BalancesTableProps {
  balances?: Balances,
  isLoading: boolean
}

const BalancesTable: React.FC<BalancesTableProps> = ({ balances, isLoading }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const rows = largeScreen && balances ? Math.ceil(balances.total / 2) : 5;
  const columns = balances && rows ? Math.ceil(balances.total / rows) : 0;
  const { t } = useTranslation();

  return (
    <NonFlickeringLoader
      isLoading={isLoading}
      loadingElement={<BalancesTableSkeleton columns={2} rows={5} />}
      noData={!balances?.total}
      noDataElement={(
        <Box px={2}>
          <Paper sx={{ padding: 4 }}>
            <Typography align="center" variant="h5" component="h2" gutterBottom>
              {t('balances.noData.title')}
            </Typography>
            <Typography align="center" variant="subtitle1" component="h3" gutterBottom color="text.secondary">
              {t('balances.noData.description')}
            </Typography>
          </Paper>
        </Box>
      )}
    >
      <ScrollableContainer fullWidth={columns === 1}>
        {[...Array(columns)].map((col, colIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={colIndex}>
            <List disablePadding>
              {balances
                ?.data
                .slice(colIndex * rows, (colIndex + 1) * rows)
                .map((balance) => (
                  <BalanceItem
                    balance={balance}
                    key={balance.currency}
                    hideAvailable
                    hidePositions
                  />
                ))}
            </List>
            {colIndex < columns - 1 && (<Divider orientation="vertical" />)}
          </Fragment>
        ))}
      </ScrollableContainer>
    </NonFlickeringLoader>
  );
};

BalancesTable.defaultProps = {
  balances: undefined,
};

export default BalancesTable;
