import { Workbox } from 'workbox-window';

export default function registerServiceWorker() {
  // Check if browser supports SW
  // Register SW only for prod
  if ('serviceWorker' in navigator && process.env.ENVIRONMENT !== 'dev') {
    const wb = new Workbox('/service-worker.js');
    wb.register();
  }

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    window.location.reload();
  });
}
