import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Credentials } from '@client/domain/auth';
import { AuthClientService } from '@client/application/ports';
import { logIn, resetPassword } from '@client/services/api/auth';

export default function useAuthClient(): AuthClientService {
  const queryClient = useQueryClient();
  const logInMutation = useMutation(logIn);

  return {
    async logIn(credentials: Credentials) {
      const { token } = await logInMutation.mutateAsync(credentials);

      return token;
    },
    isLoggingIn: logInMutation.isLoading,
    logOut() {
      queryClient.clear();
      return Promise.resolve();
    },
    isLoggingOut: false,
    resetPassword,
  };
}
