import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import parseRequestError from '@shared/services/parseRequestError';
import LoadingButton from '@shared/components/LoadingButton';
import { ExchangeAccount } from '@shared/domain/account';
import { useWithdrawableAmount, useTransfer } from '@client/application/useCases/transfer';
import { Transfer } from '@client/domain/transfer';

export const WithdrawableSkeleton: React.FC = () => (
  <Skeleton sx={{ display: 'inline-block' }} width={70} height={24} />
);

const WithdrawableComponent: React.FC<{
  value?: string,
  isLoading: boolean,
  onClick: (value?: string) => void,
}> = ({
  value,
  isLoading,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="subtitle1"
      component="div"
      color="grey.500"
      fontSize="0.875rem"
      onClick={() => onClick(value)}
      sx={{ cursor: 'pointer' }}
    >
      {t('transfer.withdrawable')}
      :&nbsp;
      {isLoading ? (
        <WithdrawableSkeleton />
      ) : value}
    </Typography>
  );
};

WithdrawableComponent.defaultProps = {
  value: undefined,
};

const transferValidation = yup.object({
  asset: yup.string()
    .required(),
  amount: yup.number()
    .moreThan(0)
    .max(yup.ref('$withdrawable'))
    .required(),
  from: yup.string()
    .oneOf([ExchangeAccount.BinanceUSDM, ExchangeAccount.BinanceSpot])
    .required(),
  to: yup.string()
    .oneOf([ExchangeAccount.BinanceUSDM, ExchangeAccount.BinanceSpot])
    .notOneOf([yup.ref('from')])
    .required(),
});

const TransferDialog: React.FC<{
  isOpen: boolean,
  onClose: () => void,
  initialData?: Partial<Transfer>,
  disabledFields?: (keyof Transfer)[],
}> = ({
  isOpen,
  onClose,
  initialData,
  disabledFields,
}) => {
  const { t } = useTranslation();
  const { makeTransfer, isLoading } = useTransfer();
  const [withdrawableForValidation, setWithdrawableForValidation] = useState<CurrencyAmount>();
  const {
    control,
    setError,
    setValue,
    handleSubmit,
    formState,
    trigger,
    watch,
  } = useForm<Transfer & { general: void }>({
    mode: 'onChange',
    context: { withdrawable: withdrawableForValidation },
    resolver: yupResolver(transferValidation),
    defaultValues: initialData,
  });
  const { errors, isValid } = formState;
  const [asset, exchangeAccount] = watch(['asset', 'from']);
  const {
    withdrawable,
    isLoading: withdrawableIsLoading,
  } = useWithdrawableAmount({
    asset,
    exchangeAccount,
  });

  useEffect(() => {
    setWithdrawableForValidation(withdrawable);
  }, [withdrawable]);

  const handleFormSubmit = useCallback((formData: Transfer) => {
    makeTransfer({
      ...formData,
      amount: formData.amount.toString(),
    }).then(() => {
      onClose();
    }).catch((error) => {
      parseRequestError({ error, setError });
    });
  }, [makeTransfer, onClose]);

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Dialog open={isOpen} scroll="body">
      <DialogTitle>{t('transfer.title')}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          id="transfer-form"
          autoComplete="off"
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.stopPropagation();
            return handleSubmit(handleFormSubmit)(event);
          }}
          sx={{ display: 'grid', gap: 2 }}
        >
          <FormControl variant="filled" error={!!errors.from} disabled={disabledFields?.includes('from')}>
            <InputLabel id="from-account">{t('transfer.from')}</InputLabel>
            <Controller
              control={control}
              name="from"
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="from-account"
                  onChange={(e) => {
                    field.onChange(e);
                    trigger('to');
                  }}
                >
                  <MenuItem value={ExchangeAccount.BinanceSpot}>
                    {t('shared.exchangeAccount.binance_spot')}
                  </MenuItem>
                  <MenuItem value={ExchangeAccount.BinanceUSDM}>
                    {t('shared.exchangeAccount.binance_usdm')}
                  </MenuItem>
                </Select>
              )}
            />
            {errors.from && (
              <FormHelperText>{errors.from.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl variant="filled" error={!!errors.to} disabled={disabledFields?.includes('to')}>
            <InputLabel id="to-account">{t('transfer.to')}</InputLabel>
            <Controller
              control={control}
              name="to"
              render={({ field }) => (
                <Select
                  labelId="to-account"
                  {...field}
                >
                  <MenuItem value={ExchangeAccount.BinanceSpot}>
                    {t('shared.exchangeAccount.binance_spot')}
                  </MenuItem>
                  <MenuItem value={ExchangeAccount.BinanceUSDM}>
                    {t('shared.exchangeAccount.binance_usdm')}
                  </MenuItem>
                </Select>
              )}
            />
            {errors.to && (
              <FormHelperText>{errors.to.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl variant="filled" error={!!errors.asset} disabled={disabledFields?.includes('asset')}>
            <InputLabel id="asset">{t('transfer.coin')}</InputLabel>
            <Controller
              control={control}
              name="asset"
              render={({ field }) => (
                <Select
                  labelId="asset"
                  {...field}
                >
                  {initialData?.asset ? (
                    <MenuItem value={initialData.asset}>
                      {initialData.asset}
                    </MenuItem>
                  ) : null}
                </Select>
              )}
            />
            {errors.asset && (
              <FormHelperText>{errors.asset.message}</FormHelperText>
            )}
          </FormControl>
          <Controller
            defaultValue=""
            control={control}
            name="amount"
            render={({ field }) => (
              <TextField
                autoFocus
                variant="filled"
                disabled={disabledFields?.includes('amount')}
                error={!!errors.amount}
                label={t('transfer.amount')}
                helperText={errors?.amount?.message}
                {...field}
              />
            )}
          />
          <WithdrawableComponent
            value={withdrawable}
            isLoading={withdrawableIsLoading}
            onClick={(value) => {
              if (value) {
                setValue('amount', value);
                trigger('amount');
              }
            }}
          />
          {errors.general && <Alert severity="error" sx={{ mt: 2 }}>{errors.general.message}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('shared.buttons.cancel')}
        </Button>
        <LoadingButton
          disabled={!isValid}
          form="transfer-form"
          type="submit"
          loading={isLoading}
        >
          {t('shared.buttons.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

TransferDialog.defaultProps = {
  initialData: undefined,
  disabledFields: undefined,
};

export default TransferDialog;
