import * as React from 'react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import parseRequestError from '@shared/services/parseRequestError';
import LoadingButton from '@shared/components/LoadingButton';
import { CommentForm } from '@shared/domain/request';

interface CommentFormProps {
  onSubmit: (data: CommentForm) => Promise<void>,
  onCancel: () => void,
  isLoading: boolean,
  defaultValue?: string,
  commentLabel?: string,
  submitLabel?: string,
  cancelLabel?: string,
}

/* eslint-disable react/jsx-props-no-spreading */

const CommentFormComponent: React.FC<CommentFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  defaultValue,
  commentLabel,
  submitLabel,
  cancelLabel,
}) => {
  // "general: void" needed for general errors with certain field name
  const {
    register,
    handleSubmit,
    setError,
    formState: {
      errors,
    },
  } = useForm<CommentForm & { general: void }>({
    defaultValues: {
      comment: defaultValue,
    },
  });

  const handleFormSubmit = useCallback((formData: CommentForm) => (
    onSubmit(formData)
      .then(() => onCancel())
      .catch((error) => parseRequestError({ error, setError }))
  ), [onSubmit, onCancel]);

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <TextField
        margin="normal"
        multiline
        fullWidth
        id="comment"
        label={commentLabel}
        autoFocus
        error={!!errors.comment}
        helperText={errors.comment?.message}
        {...register('comment')}
      />
      {errors.general && (
        <Alert severity="error">{errors.general.message}</Alert>
      )}
      <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onCancel}>
          {cancelLabel}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          {submitLabel}
        </LoadingButton>
      </Box>
    </Box>
  );
};

CommentFormComponent.defaultProps = {
  defaultValue: undefined,
  commentLabel: 'Comment',
  cancelLabel: 'Cancel',
  submitLabel: 'Save comment',
};

export default CommentFormComponent;
