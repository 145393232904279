import * as React from 'react';

import enUSLocale from 'date-fns/locale/en-US';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import '@fontsource/roboto';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';
import '@shared/i18n/form';

import { AuthStateProvider } from '@shared/services/context/auth';
import ErrorBoundary from '@client/components/ErrorBoundary';
import { SettingsStateProvider } from './services/context/settings';
import Theme from './services/context/theme';
import Router from './router';

export const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthStateProvider>
      <SettingsStateProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUSLocale}>
          <Theme>
            <ErrorBoundary>
              <CssBaseline />
              <Router />
            </ErrorBoundary>
          </Theme>
        </LocalizationProvider>
      </SettingsStateProvider>
    </AuthStateProvider>
  </QueryClientProvider>
);

export default App;
