import * as React from 'react';
import { useRef, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import './main.css';

interface PullToRefreshProps {
  refreshingContent?: React.ReactNode;
  children: React.ReactNode;
  pullDownThreshold?: number;
  maxPullDownDistance?: number;
}

const RefreshingContent = () => (
  <CircularProgress size={24} sx={{ mt: 2 }} />
);

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  refreshingContent,
  children,
  pullDownThreshold = 67,
  maxPullDownDistance = 95,
}) => {
  const queryClient = useQueryClient();
  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const pullDownRef = useRef<HTMLDivElement>(null);
  let pullToRefreshThresholdBreached: boolean = false;
  let isDragging: boolean = false;
  let startY: number = 0;
  let currentY: number = 0;

  const initContainer = (): void => {
    requestAnimationFrame(() => {
      if (childrenRef.current) {
        childrenRef.current.style.transform = 'unset';
      }
      if (pullDownRef.current) {
        pullDownRef.current.style.opacity = '0';
      }

      if (pullToRefreshThresholdBreached) pullToRefreshThresholdBreached = false;
    });
  };

  const onTouchStart = (e: TouchEvent): void => {
    isDragging = false;

    if (e instanceof TouchEvent) {
      startY = e.touches[0].pageY;
    }

    currentY = startY;

    if (childrenRef.current!.getBoundingClientRect().top < 0) {
      return;
    }

    isDragging = true;
  };

  const onTouchMove = (e: TouchEvent): void => {
    if (!isDragging) {
      return;
    }

    if (e instanceof TouchEvent) {
      currentY = e.touches[0].pageY;
    }

    if (currentY < startY) {
      isDragging = false;
      return;
    }

    if (e.cancelable) {
      e.preventDefault();
    }

    const yDistanceMoved = Math.min((currentY - startY), maxPullDownDistance);

    if (yDistanceMoved >= pullDownThreshold) {
      isDragging = true;
      pullToRefreshThresholdBreached = true;
    }

    if (yDistanceMoved >= maxPullDownDistance) {
      return;
    }
    pullDownRef.current!.style.opacity = ((yDistanceMoved) / 65).toString();
    childrenRef.current!.style.transform = `translate(0px, ${yDistanceMoved}px)`;
    pullDownRef.current!.style.visibility = 'visible';
  };

  const onEnd = (): void => {
    isDragging = false;
    startY = 0;
    currentY = 0;

    if (!pullToRefreshThresholdBreached) {
      if (pullDownRef.current) pullDownRef.current.style.visibility = 'hidden';
      initContainer();
      return;
    }

    if (childrenRef.current) {
      childrenRef.current.style.transform = `translate(0px, ${pullDownThreshold}px)`;
    }
    queryClient.refetchQueries().then(initContainer).catch(initContainer);
  };

  useEffect(() => {
    const childrenEl = childrenRef.current;

    if (!childrenEl) return () => {};

    childrenEl.addEventListener('touchstart', onTouchStart, { passive: true });
    childrenEl.addEventListener('touchmove', onTouchMove, { passive: false });
    childrenEl.addEventListener('touchend', onEnd);

    return () => {
      childrenEl.removeEventListener('touchstart', onTouchStart);
      childrenEl.removeEventListener('touchmove', onTouchMove);
      childrenEl.removeEventListener('touchend', onEnd);
    };
  }, [
    children,
    pullDownThreshold,
    maxPullDownDistance,
  ]);

  return (
    <div className="ptr" ref={containerRef}>
      <div className="ptr__pull-down" ref={pullDownRef}>
        <div className="ptr__loader ptr__pull-down--loading">{refreshingContent}</div>
      </div>
      <div className="ptr__children" ref={childrenRef}>
        {children}
      </div>
    </div>
  );
};

PullToRefresh.defaultProps = {
  refreshingContent: <RefreshingContent />,
  pullDownThreshold: undefined,
  maxPullDownDistance: undefined,
};

export default PullToRefresh;
