import * as React from 'react';
import { RequestStatus } from '@shared/domain/request';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Chip, { ChipProps } from '@mui/material/Chip';

interface StatusProps {
  value: RequestStatus,
}

export const statusColors = {
  [RequestStatus.New]: 'warning.light',
  [RequestStatus.InProgress]: 'primary.main',
  [RequestStatus.Executed]: 'success.light',
  [RequestStatus.PendingCancellation]: 'error.light',
  [RequestStatus.Canceled]: 'error.light',
};

export const labels = {
  [RequestStatus.New]: 'new',
  [RequestStatus.InProgress]: 'in progress',
  [RequestStatus.Executed]: 'executed',
  [RequestStatus.PendingCancellation]: 'canceling',
  [RequestStatus.Canceled]: 'canceled',
};

export const TextStatus: React.FC<StatusProps & TypographyProps> = ({ value, ...props }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Typography
    sx={{ textTransform: 'uppercase' }}
    variant="body2"
    color={statusColors[value]}
    {...props}
  >
    {labels[value]}
  </Typography>
);

export const ChipStatus: React.FC<StatusProps & ChipProps> = ({ value, sx, ...props }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Chip
    size="small"
    color="primary"
    sx={{
      borderRadius: 1,
      textTransform: 'uppercase',
      backgroundColor: statusColors[value],
      ...sx,
    }}
    label={labels[value]}
    {...props}
  />
);
