import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@mui/material/Drawer';
import { Container } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { ExchangeAccount, accountColor, accountIcon } from '@shared/domain/account';
import { WizardData } from '@client/domain/requestWizard';
import { useWizardAccounts } from '@client/application/useCases/requestWizard';

export interface ExchangeAccountItemProps extends Partial<ListItemButtonProps> {
  account: ExchangeAccount,
  onSelectAccount: (updates: { exchangeAccount: ExchangeAccount }) => void
}

export const ExchangeAccountItem: React.FC<ExchangeAccountItemProps> = ({
  account,
  onSelectAccount,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <ListItemButton
      onClick={() => onSelectAccount(({ exchangeAccount: account }))}
      {...props}
    >
      <ListItemIcon sx={{ minWidth: 36, pb: '3px' }}>
        <img height="28" alt="exchange icon" src={accountIcon[account]} />
      </ListItemIcon>
      <Typography variant="h6">
        <Typography variant="h5" component="span" sx={{ color: accountColor[account], mr: 1 }}>
          {t(`shared.exchangeName.${account}`)}
        </Typography>
        {t(`shared.accountType.${account}`)}
      </Typography>
    </ListItemButton>
  );
};

const SelectExchangeAccount: React.FC<{
  isOpen: boolean,
  onClose: () => void,
  onSelect: (updates: Partial<WizardData>) => void
}> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const { t } = useTranslation();
  const { accounts, isLoading } = useWizardAccounts();

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="bottom"
    >
      <Container disableGutters maxWidth="md">
        <List
          subheader={(
            <ListSubheader
              sx={{
                // The default backgroundColor is black for dark mode.
                // transparent allows to use background color of Paper.
                backgroundColor: 'transparent',
                display: 'flex',
                alignItems: 'center',
              }}
              color="inherit"
            >
              {t('requestWizard.exchangeAccount.title')}
              {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}
            </ListSubheader>
          )}
        >
          {accounts?.map((account) => (
            <ExchangeAccountItem account={account} onSelectAccount={onSelect} />
          ))}
        </List>
      </Container>
    </Drawer>
  );
};

export default SelectExchangeAccount;
