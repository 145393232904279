import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import { ExchangeAccount } from '@shared/domain/account';
import { useBalanceAccounts } from '@client/application/useCases/balance';

export const SelectStyled = styled(Select)<SelectProps>({
  '&:before': {
    border: 0,
  },
  '&:after': {
    border: 0,
  },
  '&:not(.Mui-disabled):hover::before': {
    border: 0,
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },
});

export const AccountSelectSkeleton = () => (
  <Skeleton width={140} height={32} />
);

interface AccountSelectProps {
  value?: ExchangeAccount,
  onChange: (value?: ExchangeAccount) => void,
}

const AccountSelect: React.FC<AccountSelectProps> = ({ value, onChange }) => {
  const { accounts, isLoading } = useBalanceAccounts();
  const { t } = useTranslation();
  const handleSelectAccount = (event: SelectChangeEvent<unknown>) => {
    const { value: selectedValue } = event.target;
    onChange(selectedValue === 'all_accounts' ? undefined : selectedValue as ExchangeAccount);
  };

  return isLoading ? (
    <AccountSelectSkeleton />
  ) : (
    <SelectStyled
      variant="standard"
      value={value || 'all_accounts'}
      onChange={handleSelectAccount}
    >
      <MenuItem value="all_accounts">{t('balanceDetails.allAccounts')}</MenuItem>
      {accounts?.map((account) => (
        <MenuItem value={account} key={account}>
          {t(`shared.exchangeAccount.${account}`)}
        </MenuItem>
      ))}
    </SelectStyled>
  );
};

AccountSelect.defaultProps = {
  value: undefined,
};

export default AccountSelect;
