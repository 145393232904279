import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

const enum State {
  empty, // Don't show anything
  loader, // Show loader
  data, // Show data (or "No data" element if data is empty)
}
type NonFlickeringHookProps = {
  isLoading: boolean,
  emptyDelay?: number,
  loaderDelay?: number,
};

export const useNonFlickering = ({
  isLoading,
  emptyDelay = 300, // The delay before displaying loader
  loaderDelay = 1000, // Min time displaying loader
}: NonFlickeringHookProps) => {
  // Set to the react state the timeout during which loader is shown
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  // State of the loader
  const [state, setState] = useState(State.empty);
  // Ref to the current value of the isLoading prop
  const loadingRef = useRef<boolean>();
  // We need it to have access to current value of isLoading prop inside of the callback functions
  loadingRef.current = isLoading;

  const loaderFinished = () => {
    setTimer(undefined);
    if (!loadingRef.current) {
      setState(State.data);
    }
  };

  const emptyFinished = () => {
    if (loadingRef.current) {
      setState(State.loader);
      setTimer(setTimeout(loaderFinished, loaderDelay));
    } else {
      setState(State.data);
    }
  };

  // Entry point of the hook
  useEffect(() => {
    // If timer is set === loader is displaying then don't make any changes to state
    // Otherwise check the current value of isLoading
    if (!timer) {
      if (loadingRef.current) {
        setState(State.empty);
        setTimeout(emptyFinished, emptyDelay);
      } else {
        setState(State.data);
      }
    }
  }, [isLoading]);

  // Don't show data if isLoading === true
  // It happens if previous state is "data", but the loading happened again
  return isLoading && state === State.data ? State.empty : state;
};

interface NonFlickeringLoaderProps {
  isLoading?: boolean,
  loadingElement?: React.ReactElement,
  noData?: boolean,
  noDataElement?: React.ReactElement,
  children: React.ReactNode,
}

const NonFlickeringLoader: React.FC<NonFlickeringLoaderProps> = ({
  isLoading, loadingElement, noData, noDataElement, children,
}) => {
  const state = useNonFlickering({ isLoading: isLoading as boolean });

  if (state === State.empty) {
    return <div style={{ visibility: 'hidden' }}>{loadingElement}</div>;
  }

  if (state === State.loader) {
    return loadingElement || null;
  }

  if (state === State.data && noData) {
    return noDataElement || null;
  }

  return children as React.ReactElement || null;
};

NonFlickeringLoader.defaultProps = {
  isLoading: false,
  loadingElement: <div>Loading...</div>,
  noData: false,
  noDataElement: <div>No data</div>,
};

export default NonFlickeringLoader;
