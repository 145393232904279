import * as React from 'react';
import { RequestType, SavedRequest } from '@shared/domain/request';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type Align = 'right' | 'bottom';

export const typeTypographyColors = {
  [RequestType.Market]: 'success.light',
  [RequestType.Limit]: 'info.light',
  [RequestType.Scaled]: 'warning.light',
};

const StyledIceberg = styled('span')<{ align: Align }>(({ theme, align }) => ({
  color: theme.palette.grey[500],
  display: align === 'right' ? 'inline' : 'block',
  marginLeft: align === 'right' ? theme.spacing(1) : 0,
  fontSize: align === 'right' ? 'inherit' : '0.75rem',
}));

interface TypeProps {
  request: SavedRequest,
  align?: Align,
}

const Type: React.FC<TypeProps> = ({ request, align }) => (
  <Typography
    sx={{ textTransform: 'uppercase' }}
    variant="body2"
    color={typeTypographyColors[request.type]}
  >
    {request.type}
    {request.type === RequestType.Limit && request.isIceberg && (
      <StyledIceberg align={align!}>
        Iceberg
      </StyledIceberg>
    )}
  </Typography>
);

Type.defaultProps = {
  align: 'right',
};

export default Type;
