import { useCallback, useContext } from 'react';
import { FiltersStorageService } from '@operator/application/ports';
import { FiltersContext } from '@shared/services/context/filters';
import createDebounce from '@shared/utils/debounce';

export default function useFiltersStorage(debounceTime: number = 0): FiltersStorageService {
  const context = useContext(FiltersContext);

  if (!context) return {};

  const debouncedSetValues = useCallback(createDebounce(context.setValues, debounceTime), []);

  return {
    setValues: debounceTime ? debouncedSetValues : context.setValues,
    resetValues: context.resetValues,
    filterValues: context.filterValues,
  };
}
