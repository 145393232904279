import * as React from 'react';
import { useTranslation } from 'react-i18next';

import EventErrorBoundary from '@shared/components/ErrorBoundary/EventErrorBoundary';
import RenderingErrorBoundary from '@shared/components/ErrorBoundary/RenderingErrorBoundary';

import { useAuth } from '@client/application/useCases/auth';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const { t } = useTranslation();
  const { logOut } = useAuth();

  return (
    <EventErrorBoundary
      getMessage={(error) => t('shared.appErrorAlert.eventErrorMessage', { errorName: error.name })}
      logOut={logOut}
    >
      <RenderingErrorBoundary
        getMessage={(errorId) => t('shared.appErrorAlert.renderingErrorMessage', { errorId })}
        logOut={logOut}
      >
        {children}
      </RenderingErrorBoundary>
    </EventErrorBoundary>
  );
};

export default ErrorBoundary;
