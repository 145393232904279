import * as React from 'react';
import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getRandomInt from '@shared/utils/random';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { ExchangeAccount } from '@shared/domain/account';
import CurrencyIcon from '@shared/components/CurrencyIcon';
import isPositionLong from '@shared/utils/isPositionLong';
import useFiltersStorage from '@client/adapters/store/filtersStoreAdapters';
import { useEquivalent } from '@client/services/context/settings';
import { Balance } from '@client/domain/balance';

/* eslint-disable react/jsx-props-no-spreading */
const DetailsTypography: React.FC<TypographyProps> = (props) => (
  <Typography
    variant="inherit"
    component="span"
    color="grey.600"
    mr={0.5}
    {...props}
  />
);

export const BalanceItemSkeleton: React.FC = () => (
  <ListItemButton
    sx={{
      px: 2,
      py: 1,
    }}
    alignItems="flex-start"
    disableGutters
  >
    <Skeleton variant="circular" width={24} height={24} sx={{ mr: '16px' }} />
    <Skeleton width={getRandomInt(30, 50)} />
    <Box ml="auto">
      <Skeleton width={getRandomInt(30, 50)} sx={{ ml: 'auto' }} />
      <Skeleton width={getRandomInt(70, 100)} sx={{ ml: 'auto' }} height={17} />
    </Box>
  </ListItemButton>
);

interface BalanceItemProps {
  balance: Balance,
  hideAvailable?: boolean,
  hidePositions?: boolean,
}

const BalanceItem: React.FC<BalanceItemProps> = ({ balance, hideAvailable, hidePositions }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { filterValues } = useFiltersStorage();
  const exchangeAccount = filterValues?.exchangeAccount as ExchangeAccount || 'all_accounts';
  const accountBalance = balance[exchangeAccount];
  const navigate = useNavigate();
  const equivalent = useEquivalent(accountBalance?.equivalents);
  const onClick = useCallback(() => {
    navigate(`/balances/${balance.currency}${filterValues?.exchangeAccount ? `?exchangeAccount=${filterValues?.exchangeAccount}` : ''}`, {
      state: { from: location },
    });
  }, [balance, filterValues]);

  return (
    <ListItemButton
      onClick={onClick}
      key={balance.currency}
      disableGutters
      sx={{
        px: 2,
        py: 1,
        display: 'grid',
        gridTemplateColumns: '40px auto 1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"icon currency amount" "equivalent equivalent equivalent"',
      }}
    >
      <CurrencyIcon
        sx={{
          width: 24,
          height: 24,
          gridArea: 'icon',
        }}
        currency={balance.currency}
      />
      <Typography sx={{ gridArea: 'currency' }}>{balance.currency}</Typography>
      <Typography sx={{ gridArea: 'amount' }} align="right">{accountBalance?.amount}</Typography>
      <Typography
        sx={{ gridArea: 'equivalent' }}
        align="right"
        variant="body2"
        color="grey.400"
        fontSize="0.75rem"
      >
        {accountBalance?.position && !hidePositions && (
          isPositionLong(accountBalance.position) ? (
            <>
              <DetailsTypography color="success.light">
                {t('shared.position.long')}
              </DetailsTypography>
              {accountBalance.position}
              <DetailsTypography ml={0.5}>
                &bull;
              </DetailsTypography>
            </>
          ) : (
            <>
              <DetailsTypography color="error.light">
                {t('shared.position.short')}
              </DetailsTypography>
              {accountBalance.position}
              <DetailsTypography ml={0.5}>
                &bull;
              </DetailsTypography>
            </>
          )
        )}
        {accountBalance?.available && !hideAvailable && (
          <>
            <DetailsTypography>
              {t('balances.available')}
            </DetailsTypography>
            {accountBalance.available}
            <DetailsTypography ml={0.5}>
              &bull;
            </DetailsTypography>
          </>
        )}
        {equivalent}
      </Typography>
    </ListItemButton>
  );
};

BalanceItem.defaultProps = {
  hideAvailable: false,
  hidePositions: false,
};

export default BalanceItem;
