export const buildAssetUrl = (asset: string, url?: string, extension?: string) => `${url || '/'}${asset.toLowerCase()}${extension ? `.${extension}` : ''}`;

export const getAssetIcon = (asset: string) => buildAssetUrl(
  asset,
  process.env.ASSET_ICON_URL,
  process.env.ASSET_ICON_EXTENSION,
);

export const getFallbackIcon = (asset: string) => process.env.ASSET_FALLBACK_URL && buildAssetUrl(
  asset,
  process.env.ASSET_FALLBACK_URL,
  process.env.ASSET_FALLBACK_EXTENSION,
);
