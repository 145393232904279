import * as React from 'react';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';
import BalanceItem, { BalanceItemSkeleton } from '@client/components/BalanceItem';
import { Balances } from '@client/domain/balance';
import useFiltersStorage from '@client/adapters/store/filtersStoreAdapters';

interface BalancesListSkeletonProps {
  rows: number,
}

export const BalancesListSkeleton: React.FC<BalancesListSkeletonProps> = ({ rows }) => (
  <List>
    {[...Array(rows)].map((row, index) => (
      <BalanceItemSkeleton key={index /* eslint-disable-line react/no-array-index-key */} />
    ))}
  </List>
);

interface BalancesListProps {
  balances?: Balances,
  isLoading: boolean,
  isSearching: boolean,
}

const BalancesList: React.FC<BalancesListProps> = ({ balances, isLoading, isSearching }) => {
  const { t } = useTranslation();
  const { filterValues } = useFiltersStorage();

  return (
    <NonFlickeringLoader
      isLoading={isLoading}
      loadingElement={<BalancesListSkeleton rows={5} />}
      noData={!balances?.data.length}
      noDataElement={(
        isSearching ? (
          <Typography p={3} align="center" variant="subtitle1" component="div" color="text.secondary">
            {t('balances.notFound')}
          </Typography>
        ) : (
          <Paper sx={{ padding: 4 }}>
            <Typography align="center" variant="h5" component="h2" gutterBottom>
              {t('balances.noData.title')}
            </Typography>
            <Typography align="center" variant="subtitle1" component="h3" gutterBottom color="text.secondary">
              {filterValues?.exchangeAccount ? (
                t('balances.noExchangeData.description', { exchangeAccount: filterValues?.exchangeAccount })
              ) : (
                t('balances.noData.description')
              )}
            </Typography>
          </Paper>
        ))}
    >
      <List>
        {balances?.data.map((balance) => (
          <BalanceItem balance={balance} key={balance.currency} />
        ))}
      </List>
    </NonFlickeringLoader>
  );
};

BalancesList.defaultProps = {
  balances: undefined,
};

export default BalancesList;
