import * as React from 'react';
import { ExchangeAccount } from '@shared/domain/account';
import Ticker, { TickerSkeleton } from '@shared/components/Ticker';
import { useBalanceTicker } from '@client/application/useCases/balance';

interface CurrencyTickerProps {
  currency: Currency,
  exchangeAccount: ExchangeAccount,
}

const CurrencyTicker: React.FC<CurrencyTickerProps> = ({
  currency, exchangeAccount,
}) => {
  const {
    ticker,
    isLoading,
    error,
    counter,
  } = useBalanceTicker(
    currency,
    exchangeAccount,
  );

  if (error) {
    return <>N/A</>;
  }

  if (isLoading) {
    return <TickerSkeleton />;
  }

  return (
    <Ticker
      ticker={ticker}
      counter={counter}
      isLoading={isLoading}
      align="right"
      color="text.primary"
    />
  );
};

export default CurrencyTicker;
