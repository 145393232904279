import NetworkError from '@shared/errors/NetworkError';
import { ErrorReason } from '@shared/errors/ErrorReason';
import HttpCode from '@shared/constants/httpCode';

export default class ValidationError extends NetworkError {
  constructor(reasons?: ErrorReason[]) {
    super(HttpCode.UnprocessableEntity, reasons);
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ValidationError.prototype);
    this.name = 'ValidationError';
  }
}
