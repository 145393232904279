import { ExchangeAccount } from '@shared/domain/account';
import { useCurrencyTicker } from '@shared/application/useCases/currencyTicker';
import { useBalanceStorage } from '@client/adapters/store/balancesStoreAdapters';
import { useAccountsStorage } from '@client/adapters/store/profileStoreAdapters';
import { getBalanceAccounts, tickerCounters } from '@client/domain/balance';
import { BalanceStorageService } from '../ports';

// eslint-disable-next-line import/prefer-default-export
export function useBalanceData(currency: Currency) {
  const storage: BalanceStorageService = useBalanceStorage(currency);

  return {
    balance: storage.balance,
    isLoading: storage.isLoading,
    error: storage.error,
  };
}

export function useBalanceAccounts() {
  const accountsStorage = useAccountsStorage();
  const accounts = accountsStorage.accounts && getBalanceAccounts(accountsStorage.accounts);

  return {
    accounts,
    isLoading: accountsStorage.isLoading,
  };
}

export function useBalanceTicker(currency: Currency, exchangeAccount: ExchangeAccount) {
  return [
    ...tickerCounters.map((counter) => ({ base: currency, counter, revert: false })),
    ...tickerCounters.map((base) => ({ base, counter: currency, revert: true })),
  ].reduce((result, { base, counter, revert }) => {
    const currentDisabled = !!(result.ticker || result.isLoading);
    const { ticker, isLoading, error } = useCurrencyTicker({
      base,
      counter,
      exchangeAccount,
      disabled: currentDisabled,
      onError: () => {}, // Skip errors warnings
    });

    return currentDisabled ? result : {
      ticker: revert && ticker ? parseFloat((1 / ticker).toFixed(8)) : ticker,
      isLoading,
      error,
      counter: revert ? base : counter,
    };
  }, {
    ticker: undefined, isLoading: false, error: undefined, counter: undefined,
  } as {
    ticker?: number,
    isLoading: boolean,
    error?: Error,
    counter?: Currency
  });
}
