import {
  SubscriptionOptions,
  SubscriptionCallback,
  SubscriptionError,
  SubscriptionStream,
  ExchangeSocketsClient,
} from '@shared/services/sockets/index';
import InternalError from '@shared/errors/InternalError';
import SocketClient from './socketClient';

export type MessageId = number;
export type ErrorCode = number;

export enum Event {
  Subscribe = 'SUBSCRIBE',
  Unsubscribe = 'UNSUBSCRIBE',
  GetProperty = 'GET_PROPERTY',
}

export type SendEventMessage = {
  method: Event,
  params: string[],
  id: MessageId,
};

export type SuccessEventMessage = {
  result: any,
  id: MessageId
};

export type ErrorEventMessage = {
  code: ErrorCode,
  msg: string,
  id: MessageId
};

export type Message<T> = T | SendEventMessage | ErrorEventMessage | SuccessEventMessage;

const pingDelayMs = 5000;
const pongAwaitMs = 4000;

export abstract class BinanceClient <T> implements ExchangeSocketsClient {
  protected socket: SocketClient<Message<T>>;

  private pingInterval?: ReturnType<typeof setInterval>;

  private pongTimout?: ReturnType<typeof setTimeout>;

  protected constructor({ url }: { url: string } = { url: '' }) {
    this.socket = new SocketClient<Message<T>>({
      url,
      handleMessage: this.handleMessage.bind(this),
    });
  }

  private messageIds: MessageId[] = [];

  public subscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.subscribeToOrderBooks(callback, options, onError);
        break;
      case SubscriptionStream.Ticker:
        this.subscribeToTicker(callback, options, onError);
        break;
      default:
        throw new InternalError('Unknown stream');
    }

    if (!this.pingInterval) {
      this.pingInterval = setInterval(() => {
        if (this.pongTimout) {
          clearTimeout(this.pongTimout);
          delete this.pongTimout;
          this.socket?.reconnect();
        }
        this.socket?.ping({
          method: Event.GetProperty,
          params: ['combined'],
          id: this.createMessageId(),
        });
        this.pongTimout = setTimeout(() => {
          delete this.pongTimout;
          this.socket?.reconnect();
        }, pongAwaitMs);
      }, pingDelayMs);
    }
  }

  public unsubscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.unsubscribeFromOrderBooks(callback, options);
        break;
      case SubscriptionStream.Ticker:
        this.unsubscribeFromTicker(callback, options);
        break;
      default:
        throw new InternalError('Unknown stream');
    }

    if (!Object.keys(this.socket.subscriptions).length) {
      clearInterval(this.pingInterval);
      delete this.pingInterval;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleMessage(message: Message<T>) {
    if (this.pingInterval) {
      clearTimeout(this.pongTimout);
      delete this.pongTimout;
    }
  }

  private createMessageId() {
    const { messageIds } = this;
    const lastMessageId = messageIds[messageIds.length - 1];
    const newMessageId = lastMessageId ? lastMessageId + 1 : 1;

    this.messageIds.push(newMessageId);

    return newMessageId;
  }

  private subscribeToOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const key = `${base.toLowerCase()}${counter.toLowerCase()}@depth20`;

    this.socket.subscribe(
      key,
      {
        method: Event.Subscribe,
        params: [key],
        id: this.createMessageId(),
      },
      callback,
      onError,
    );
  }

  private unsubscribeFromOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const key = `${base.toLowerCase()}${counter.toLowerCase()}@depth20`;

    this.socket.unsubscribe(
      key,
      {
        method: Event.Unsubscribe,
        params: [key],
        id: this.createMessageId(),
      },
      callback,
    );
  }

  private subscribeToTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const key = `${base.toLowerCase()}${counter.toLowerCase()}@miniTicker`;

    this.socket.subscribe(
      key,
      {
        method: Event.Subscribe,
        params: [key],
        id: this.createMessageId(),
      },
      callback,
      onError,
    );
  }

  private unsubscribeFromTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const key = `${base.toLowerCase()}${counter.toLowerCase()}@miniTicker`;

    this.socket.unsubscribe(
      key,
      {
        method: Event.Unsubscribe,
        params: [key],
        id: this.createMessageId(),
      },
      callback,
    );
  }
}
