import { useQuery } from '@tanstack/react-query';
import { TotalBalancesStorageService } from '@client/application/ports';
import useTotalBalancesClient from '@client/adapters/client/totalBalancesClientAdapters';

export default function useTotalBalancesStorage(): TotalBalancesStorageService {
  const client = useTotalBalancesClient();
  const {
    isLoading,
    error,
    data,
  } = useQuery(
    ['balances', 'total'],
    () => client.load(),
  );

  return {
    total: data,
    isLoading,
    error: error as Error | undefined,
  };
}
