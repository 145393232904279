import { BalanceClientService, BalancesClientService } from '@client/application/ports';
import { getBalance, getBalances } from '@client/services/api/balances';

export function useBalancesClient(): BalancesClientService {
  return {
    async load() {
      const { data } = await getBalances();

      const balances = data.map(({ asset, ...balance }) => ({
        ...balance,
        currency: asset,
      }));

      return {
        total: balances.length,
        data: balances,
      };
    },
  };
}

export function useBalanceClient(): BalanceClientService {
  return {
    async load(currency) {
      const { asset, ...balance } = await getBalance(currency);

      return {
        ...balance,
        currency: asset,
      };
    },
  };
}
