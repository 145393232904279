import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useRequestWizard from '@client/application/useCases/requestWizard';

const SmallerBottomNavigationAction = styled(BottomNavigationAction)(() => ({
  minWidth: 51,
  paddingLeft: 4,
  paddingRight: 4,
}));

const Navigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { start } = useRequestWizard();

  return (
    <Paper
      elevation={4}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        // Add padding to prevent iPhone home bar overlapping
        // The same value in /src/modules/client/components/PageWrapper/index.tsx
        pb: 'env(safe-area-inset-bottom)',
      }}
    >
      <BottomNavigation
        sx={{
          // The default backgroundColor is black for dark mode.
          // transparent allows to use background color of Paper.
          backgroundColor: 'transparent',
        }}
        showLabels
        value={location.pathname}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
      >
        <SmallerBottomNavigationAction
          value="/"
          label={t('dashboard.title')}
          icon={<HomeIcon />}
        />
        <SmallerBottomNavigationAction
          value="/requests"
          label={t('requests.title')}
          icon={<RequestPageIcon />}
        />
        <Box
          sx={{
            flex: 1,
            minWidth: 51,
            maxWidth: 168,
          }}
          textAlign="center"
        >
          <IconButton
            onClick={() => start()}
            color="info"
            size="large"
            sx={{
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </Box>
        <SmallerBottomNavigationAction
          value="/balances"
          label={t('balances.title')}
          icon={<AccountBalanceWalletIcon />}
        />
        <SmallerBottomNavigationAction
          value="/profile"
          label={t('profile.title')}
          icon={<AccountCircleIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default Navigation;
