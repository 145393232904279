import { MiniBook } from '@shared/domain/orderBook';
import { binanceItemToBookItem } from '@shared/domain/binance';
import binanceApi from '@shared/services/api/binance';

export const binanceSpotApi = binanceApi.bind(null, 'https://api.binance.com');

export type TickerResponse = {
  symbol: CurrencyPair,
  price: CurrencyAmount,
};

export const getTicker = (
  base: Currency,
  counter: Currency,
): Promise<TickerResponse> => (
  binanceSpotApi(`api/v3/ticker/price?symbol=${base}${counter}`)
);

export const getOrderBook = ({
  base,
  counter,
  limit = 20,
}: {
  base: Currency,
  counter: Currency,
  limit?: number,
}): Promise<MiniBook> => (
  binanceSpotApi(`api/v3/depth?limit=${limit}&symbol=${base}${counter}`)
    .then((book) => ({
      asks: book.asks.map(binanceItemToBookItem),
      bids: book.bids.map(binanceItemToBookItem),
    }))
);
