export enum CurrencyType {
  Base = 'base',
  Counter = 'counter',
  Common = 'common',
}

export type Currencies<T = Currency> = {
  total: number,
  data: T[]
};
