import { AUTH_TOKEN_KEY } from '@shared/constants/auth';

export const setToken = (token: string) => {
  window.localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const removeToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const getToken = () => window.localStorage.getItem(AUTH_TOKEN_KEY);
