import { useQuery } from '@tanstack/react-query';
import { ExchangeAccount } from '@shared/domain/account';
import InternalError from '@shared/errors/InternalError';
import { CurrencyStorageService } from '@client/application/ports';
import useCurrenciesClient from '@client/adapters/client/currenciesClientAdapters';
import { CurrencyType } from '@client/domain/currency';
import { RequestSide } from '@client/domain/request';

export default function useCurrencyStorage({
  base,
  counter,
  common,
  side,
  type,
  exchangeAccount,
  triangulation,
}: {
  base?: Currency,
  counter?: Currency,
  common?: Currency,
  side?: RequestSide,
  type?: CurrencyType,
  exchangeAccount?: ExchangeAccount,
  triangulation?: boolean,
}): CurrencyStorageService {
  const client = useCurrenciesClient();
  const search = type ? {
    [CurrencyType.Base]: base,
    [CurrencyType.Counter]: counter,
    [CurrencyType.Common]: common,
  }[type] : undefined;
  const {
    isLoading,
    error,
    data,
  } = useQuery(
    [
      'currencies',
      exchangeAccount,
      type,
      side,
      base,
      counter,
      triangulation,
    ],
    () => {
      if (!exchangeAccount || !type || !side) throw new InternalError('Empty params do not allowed');
      return client.search({
        base,
        counter,
        side,
        type,
        exchangeAccount,
        triangulation,
      });
    },
    {
      enabled: !!(exchangeAccount && type && side),
    },
  );

  const filteredCurrencies = data?.data.filter(({ currency }) => (
    currency.toLowerCase().includes(search?.toLowerCase() || '')
  )) || [];

  return {
    currencies: {
      data: filteredCurrencies,
      total: filteredCurrencies.length,
    },
    isLoading,
    error: error as Error | undefined,
  };
}
