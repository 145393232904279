import {
  useCancelRequestClient,
  useAddRequestCommentClient,
  useEditRequestDeadlineClient,
} from '@client/adapters/client/requestsClientAdapters';
import { useRequestStorage } from '@client/adapters/store/requestsStoreAdapters';
import { CommentForm, DeadlineForm } from '@client/domain/request';
import {
  CancelRequestClientService,
  RequestStorageService,
  AddRequestCommentClientService,
  EditRequestDeadlineClientService,
} from '../ports';

export function useRequestData(uuid: Uuid) {
  const storage: RequestStorageService = useRequestStorage(uuid);

  return {
    request: storage.request,
    isLoading: storage.isLoading,
    error: storage.error,
  };
}

export function useCancelRequest(uuid: Uuid) {
  const client: CancelRequestClientService = useCancelRequestClient();

  async function cancelRequest(): Promise<void> {
    await client.cancel(uuid);
  }

  return {
    cancelRequest,
    isCanceling: client.isLoading,
  };
}

export function useAddRequestComment(uuid: Uuid) {
  const client: AddRequestCommentClientService = useAddRequestCommentClient();

  async function addComment(data: CommentForm): Promise<void> {
    await client.addComment({ uuid, data });
  }

  return {
    addComment,
    isLoading: client.isLoading,
  };
}

export function useEditRequestDeadline(uuid: Uuid) {
  const client: EditRequestDeadlineClientService = useEditRequestDeadlineClient();

  async function editDeadline(data: DeadlineForm): Promise<void> {
    await client.editDeadline({ uuid, data });
  }

  return {
    editDeadline,
    isLoading: client.isLoading,
  };
}
