import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import useProfile from '@client/application/useCases/profile';
import getRandomInt from '@shared/utils/random';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';

interface CurrencyTickerProps {
  label: (initValue: number) => void,
  value?: string,
}

const ProfileRow: React.FC<CurrencyTickerProps> = ({ label, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', pb: 2 }}>
    <Typography sx={{ fontWeight: 'bold' }}>
      <>
        {label}
        : &nbsp;
      </>
    </Typography>
    <Typography color="text.secondary">{value}</Typography>
  </Box>
);

ProfileRow.defaultProps = {
  value: '',
};

export const ProfileRowSkeleton = () => (
  <Box sx={{ display: 'flex', flexDirection: 'row', pb: 2 }}>
    <Skeleton variant="rectangular" width={getRandomInt(150, 200)} height={24} />
  </Box>
);

const ProfileData = () => {
  const { t } = useTranslation();
  const { profile, isLoading } = useProfile();

  return (
    <NonFlickeringLoader
      isLoading={isLoading}
      loadingElement={(
        <>
          <ProfileRowSkeleton />
          <ProfileRowSkeleton />
        </>
      )}
    >
      <ProfileRow label={t('profile.name')} value={profile?.name} />
      <ProfileRow label={t('profile.email')} value={profile?.email} />
    </NonFlickeringLoader>
  );
};

export default ProfileData;
