import api from '@shared/services/api';
import { Credentials, ResetPasswordForm, AuthToken } from '@client/domain/auth';

export type ApiResponse = {
  token: AuthToken,
};

export const logIn = (credentials: Credentials): Promise<ApiResponse> => api.post('v1/auth/token', {
  body: credentials,
});

export const resetPassword = (resetPasswordForm: ResetPasswordForm): Promise<void> => api.put('v1/auth/password', {
  body: resetPasswordForm,
});
