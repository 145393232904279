import * as React from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

const PriceInput = React.forwardRef<HTMLInputElement, Omit<InputBaseProps, 'error'> & {
  label?: string,
  fontSize?: string,
  currency: Currency,
  error?: any,
}>(({
  name,
  label,
  fontSize,
  currency,
  error,
  ...props
}, ref) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <FormControl sx={{ width: '100%', mb: 1 }} component="fieldset" error={!!error} variant="standard">
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <InputBase
      id={name}
      name={name}
      inputProps={{
        step: 'any',
        min: 0,
        sx: {
          textAlign: 'end',
          '::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
        },
      }}
      type="number"
      fullWidth
      sx={{ fontSize }}
      endAdornment={(
        <InputAdornment
          disableTypography
          position="end"
          sx={{ color: 'grey.500' }}
          // Focus input on adornment click
          style={{ pointerEvents: 'none' }}
        >
          {currency}
        </InputAdornment>
      )}
      ref={ref}
      {...props}
    />
    {error && (
      <FormHelperText sx={{ textAlign: 'right' }}>{error.message}</FormHelperText>
    )}
  </FormControl>
));

PriceInput.defaultProps = {
  label: 'Price',
  fontSize: '2rem',
  error: undefined,
};

export default PriceInput;
