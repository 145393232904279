import * as React from 'react';
import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LogInForm from '@shared/components/LogInForm';
import { Credentials } from '@shared/domain/auth';
import { useAuth } from '@client/application/useCases/auth';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation() as { state: { from?: string } };
  const { logIn, isLoggingIn } = useAuth();
  const onSubmit = useCallback(
    async (credentials: Credentials) => {
      await logIn(credentials);
      navigate(location.state?.from || '/', { replace: true });
    },
    [logIn, navigate, location],
  );

  return (
    <LogInForm
      onSubmit={onSubmit}
      isLoading={isLoggingIn}
      title={t('login.title')}
      emailLabel={t('login.email')}
      passwordLabel={t('login.password')}
      submitLabel={t('login.submit')}
    />
  );
};

export default LoginPage;
