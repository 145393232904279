import * as React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';

interface ReceivingDataErrorProps extends AlertProps {
  id?: string,
}

const ReceivingDataError: React.FC<ReceivingDataErrorProps> = ({
  children,
  id,
  ...props
}) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Alert severity="error" {...props}>
    {id ? `We have recorded the error ${id}` : null}
    {children || 'Unexpected error'}
  </Alert>
);

ReceivingDataError.defaultProps = {
  id: undefined,
};

export default ReceivingDataError;
