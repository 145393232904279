import { MiniBook } from '@shared/domain/orderBook';
import {
  cutBook,
  getSnapshot,
  CurrencySymbolMap,
  CurrencyPairsList,
} from '@shared/domain/bitfinex';
import api from '@shared/services/api';

export type TickerResponse = {
  price: CurrencyAmount,
};

export const getCurrencySymbolMap = (): Promise<CurrencySymbolMap> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent('https://api-pub.bitfinex.com/v2/conf/pub:map:currency:sym')}`)
    .then(([list]: [CurrencySymbolMap]) => list)
);

export const getPairsList = (): Promise<CurrencyPairsList> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent('https://api-pub.bitfinex.com/v2/conf/pub:list:pair:exchange')}`)
    .then(([list]: [CurrencyPairsList]) => list.filter((pair) => !pair.includes('TEST')))
);

export const getTicker = (pair: CurrencyPair): Promise<TickerResponse> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent(`https://api-pub.bitfinex.com/v2/ticker/${pair}`)}`)
    .then((ticker) => {
      if (ticker[0] === 'error') throw new Error(ticker[2]);
      return { price: ticker[6] };
    })
);

export const getOrderBook = (pair: CurrencyPair): Promise<MiniBook> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent(`https://api-pub.bitfinex.com/v2/book/${pair}/P1?len=25`)}`)
    .then((book) => {
      if (book[0] === 'error') throw new Error(book[2]);
      return cutBook(getSnapshot(book), 20);
    })
);
