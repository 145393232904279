import * as React from 'react';
import { WizardData } from '@client/domain/requestWizard';
import CurrencyTicker from '@client/components/CurrencyTicker';

const LimitRequest: React.FC<{ request: Partial<WizardData> }> = ({ request }) => {
  if (!request.baseCurrency || !request.counterCurrency || !request.exchangeAccount) return null;

  return (
    <CurrencyTicker
      base={request.baseCurrency}
      counter={request.counterCurrency}
      exchangeAccount={request.exchangeAccount}
    />
  );
};

export default LimitRequest;
