import * as React from 'react';
import { FieldError } from 'react-hook-form';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import CurrencyIcon from '@shared/components/CurrencyIcon';

const CurrencyAmountInput = React.forwardRef<HTMLInputElement, Omit<InputBaseProps, 'error'> & {
  calculated?: boolean,
  children?: React.ReactNode;
  label: string,
  currency: Currency,
  selectCurrency:() => void,
  error?: FieldError,
}>(({
      calculated,
      children,
      label,
      currency,
      selectCurrency,
      value,
      name,
      error,
      ...props
    }, ref) => (
      /* eslint-disable react/jsx-props-no-spreading */
      <FormControl sx={{ width: '100%', mb: 1 }} component="fieldset" error={!!error} variant="standard">
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 1,
          justifyContent: 'space-between',
        }}
        >
          <Chip
            clickable
            icon={(
              <CurrencyIcon
                currency={currency}
                sx={{ width: 20, height: 20 }}
              />
            )}
            label={currency}
            onClick={selectCurrency}
            deleteIcon={<ArrowDropDownIcon />}
            onDelete={selectCurrency}
            sx={{ my: '12px' }}
          />
          {
            children || (
              <InputBase
                id={name}
                name={name}
                disabled={calculated}
                inputProps={{
                  step: 'any',
                  min: 0,
                  sx: {
                    textAlign: 'end',
                    '::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                    },
                  },
                }}
                type={calculated ? 'text' : 'number'}
                value={(value && calculated ? `≈${value}` : value)}
                sx={{
                  fontSize: '2rem',
                  marginLeft: 1,
                }}
                ref={ref}
                {...props}
              />
            )
          }
        </Box>
        {error && (
          <FormHelperText sx={{ textAlign: 'right' }}>{error.message}</FormHelperText>
        )}
      </FormControl>
    ));

CurrencyAmountInput.defaultProps = {
  calculated: false,
  children: undefined,
  error: undefined,
};

export default CurrencyAmountInput;
