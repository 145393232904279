import * as React from 'react';
import {
  Dispatch,
  createContext,
  useCallback,
  useMemo,
} from 'react';
import isEmpty from '@shared/utils/isEmpty';
import { FilterValues } from '@shared/domain/filters';
import { useLocation, useSearchParams } from 'react-router-dom';

type FiltersContextValues = {
  filterValues: FilterValues,
  setValues: Dispatch<FilterValues>,
  resetValues: () => void,
} | undefined;

export const FiltersContext = createContext<FiltersContextValues>(undefined);

const defaultFilterValues: FilterValues = {};

export interface FiltersStateProviderProps {
  initValues?: FilterValues;
  keys?: string[];
  children: React.ReactNode;
}

export const FiltersStateProvider: React.FC<FiltersStateProviderProps> = ({
  children,
  keys,
  initValues,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const filterValues = { ...initValues };

  searchParams.forEach((value, key) => {
    if (keys?.includes(key)) {
      filterValues[key] = value;
    }
  });

  const setValues = useCallback((newValues: FilterValues) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    Object.entries(newValues).forEach(([key, value]) => {
      if (isEmpty(value)) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value);
      }
    });
    setSearchParams(newSearchParams, { replace: true, state: location.state });
  }, [setSearchParams]);
  const resetValues = useCallback(() => {
    setSearchParams(undefined, { replace: true, state: location.state });
  }, [setSearchParams]);
  const value = useMemo(() => ({
    filterValues,
    setValues,
    resetValues,
  }), [filterValues, setValues, resetValues]);

  return (
    <FiltersContext.Provider value={value}>
      {children}
    </FiltersContext.Provider>
  );
};

FiltersStateProvider.defaultProps = {
  initValues: defaultFilterValues,
  keys: undefined,
};
