import { amber, blue, teal } from '@mui/material/colors';
import binanceSpotIcon from '@assets/img/binance-spot.svg';
import binanceFuturesIcon from '@assets/img/binance-futures.svg';
import coinbaseSpotIcon from '@assets/img/coinbase-spot.svg';
import gateioSpotIcon from '@assets/img/gateio-spot.svg';
import bitfinexSpotIcon from '@assets/img/bitfinex-spot.svg';

export enum ExchangeAccount {
  BinanceSpot = 'binance_spot',
  BinanceUSDM = 'binance_usdm',
  BitfinexSpot = 'bitfinex_spot',
  BitfinexMargin = 'bitfinex_margin',
  CoinbaseSpot = 'coinbase_spot',
  CoinbasePrimeSpot = 'coinbase_prime_spot',
  GateioSpot = 'gateio_spot',
}

export const accountIcon = {
  [ExchangeAccount.BinanceSpot]: binanceSpotIcon,
  [ExchangeAccount.BinanceUSDM]: binanceFuturesIcon,
  [ExchangeAccount.CoinbaseSpot]: coinbaseSpotIcon,
  [ExchangeAccount.CoinbasePrimeSpot]: coinbaseSpotIcon,
  [ExchangeAccount.GateioSpot]: gateioSpotIcon,
  [ExchangeAccount.BitfinexSpot]: bitfinexSpotIcon,
  [ExchangeAccount.BitfinexMargin]: undefined,
};

export const accountColor = {
  [ExchangeAccount.BinanceSpot]: amber[200],
  [ExchangeAccount.BinanceUSDM]: amber[200],
  [ExchangeAccount.CoinbaseSpot]: blue.A100,
  [ExchangeAccount.CoinbasePrimeSpot]: blue.A100,
  [ExchangeAccount.GateioSpot]: blue.A200,
  [ExchangeAccount.BitfinexSpot]: teal[200],
  [ExchangeAccount.BitfinexMargin]: undefined,
};

export const spotAccounts = [
  ExchangeAccount.BinanceSpot,
  ExchangeAccount.BitfinexSpot,
  ExchangeAccount.CoinbaseSpot,
  ExchangeAccount.CoinbasePrimeSpot,
  ExchangeAccount.GateioSpot,
];
export const futuresAccounts = [ExchangeAccount.BinanceUSDM];

export const oppositeAccount = {
  [ExchangeAccount.BinanceUSDM]: ExchangeAccount.BinanceSpot,
  [ExchangeAccount.BinanceSpot]: ExchangeAccount.BinanceUSDM,
  [ExchangeAccount.BitfinexSpot]: ExchangeAccount.BitfinexMargin,
  [ExchangeAccount.BitfinexMargin]: ExchangeAccount.BitfinexSpot,
  [ExchangeAccount.CoinbaseSpot]: undefined,
  [ExchangeAccount.CoinbasePrimeSpot]: undefined,
  [ExchangeAccount.GateioSpot]: undefined,
};
