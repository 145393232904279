import { getToken } from '@shared/services/auth';
import HttpCode from '@shared/constants/httpCode';
import NetworkError from '@shared/errors/NetworkError';
import ValidationError from '@shared/errors/ValidationError';
import AuthorizationError from '@shared/errors/AuthorizationError';

export enum Method {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export const parseBody = (response: Response) => response.json().catch(() => undefined);

interface CustomConfig extends RequestInit {
  body: any;
}
const defaults = {
  baseURL: process.env.API_URL || '',
  headers: () => {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };
    const token = getToken();

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return headers;
  },
};

const api = (method: Method, endpoint: string, customConfig?: CustomConfig): Promise<any> => {
  const config = {
    method,
    ...customConfig,
    headers: {
      ...defaults.headers(),
      ...customConfig?.headers,
    },
  };

  if (customConfig?.body) {
    config.body = JSON.stringify(customConfig.body);
  }

  const url = `${defaults.baseURL}/${endpoint}`;

  return window
    .fetch(url, config)
    .then(async (response) => {
      const body = await parseBody(response);

      if (response.ok) {
        return body;
      }

      if (response.status === HttpCode.Unauthorized) {
        throw new AuthorizationError(body?.errors);
      }

      if (response.status === HttpCode.UnprocessableEntity) {
        throw new ValidationError(body?.errors);
      }

      throw new NetworkError(response.status, body?.errors, url);
    });
};

export default {
  get: api.bind(null, Method.Get),
  post: api.bind(null, Method.Post),
  put: api.bind(null, Method.Put),
  patch: api.bind(null, Method.Patch),
  delete: api.bind(null, Method.Delete),
};
