import { MiniBookOrder } from '@shared/domain/orderBook';

export type GateioPair = string;
export type GateioPrice = string;
export type GateioAmount = string;
export type GateioBookOrder = [GateioPrice, GateioAmount];

export const gateioItemToBookItem = (item: GateioBookOrder): MiniBookOrder => ({
  price: parseFloat(item[0]),
  amount: parseFloat(item[1]),
});

export const getPair = (base: Currency, counter: Currency): GateioPair => `${base}_${counter}`;
