import differenceInDays from 'date-fns/differenceInDays';
import { ExchangeAccount } from '@shared/domain/account';

export enum RequestSide {
  Buy = 'buy',
  Sell = 'sell',
}

export enum RequestType {
  Market = 'market',
  Limit = 'limit',
  Scaled = 'scaled',
}

export enum RequestStatus {
  New = 'new',
  InProgress = 'in_progress',
  Executed = 'executed',
  PendingCancellation = 'pending_cancellation',
  Canceled = 'canceled',
}

export type Comment = {
  text: string,
  editedBy?: {
    uuid: Uuid,
    name: string,
    role: string,
  },
  lastUpdated?: DateString,
};

export type RequestFee = {
  asset: Currency,
  amount: CurrencyAmount,
};

export type RequestFees = RequestFee[];

export type RequestBase = {
  side: RequestSide,
  status: RequestStatus,
  baseCurrency: Currency,
  requestedBase: CurrencyAmount,
  counterCurrency: Currency,
  comment?: Comment,
  exchangeAccount: ExchangeAccount,
  commonCurrency?: Currency,
  deadline?: DateString,
};

export type MarketRequest = RequestBase & {
  type: RequestType.Market,
  requestedPrice: CurrencyAmount,
};

export type LimitRequest = RequestBase & {
  type: RequestType.Limit,
  requestedPrice: CurrencyAmount,
  isIceberg: boolean,
};

export type ScaledRequest = RequestBase & {
  type: RequestType.Scaled,
  minPrice: CurrencyAmount,
  maxPrice: CurrencyAmount,
};

export type Request = MarketRequest | LimitRequest | ScaledRequest;

export type ReadOnlyRequestFields = {
  number: string,
  uuid: Uuid,
  progress: Progress,
  executedCounter: CurrencyAmount,
  executedBase: CurrencyAmount,
  createdAt: DateString,
  updatedAt: DateString,
  exchangeFees?: RequestFees,
  averagePrice?: CurrencyAmount,
  warning?: string,
  client: {
    name: string;
    uuid: Uuid;
  };
};

export type SavedMarketRequest = MarketRequest & ReadOnlyRequestFields;

export type SavedLimitRequest = LimitRequest & ReadOnlyRequestFields;

export type SavedScaledRequest = ScaledRequest & ReadOnlyRequestFields;

export type SavedRequest = SavedMarketRequest | SavedLimitRequest | SavedScaledRequest;

export type Requests<Type = SavedRequest> = {
  total: number,
  data: Type[]
};

export type CommentForm = {
  comment?: string,
};

export type DeadlineForm = {
  deadline?: DateString | null,
};

export const cancelAllowed = (request: Request) => (
  request.status === RequestStatus.New || request.status === RequestStatus.InProgress
);

export enum DeadlineUrgency {
  Regular,
  Warning,
  Error,

}

export const getDeadlineUrgency = (request: Request) => {
  const requestIsActive = (
    request.status === RequestStatus.New || request.status === RequestStatus.InProgress
  );

  if (!request.deadline || !requestIsActive) return DeadlineUrgency.Regular;

  const daysDiff = differenceInDays(new Date(request.deadline), new Date());

  if (daysDiff >= 2) return DeadlineUrgency.Regular;
  if (daysDiff === 1) return DeadlineUrgency.Warning;

  return DeadlineUrgency.Error;
};

export const deadlineEditAllowed = (request: Request) => (
  request.status === RequestStatus.New || request.status === RequestStatus.InProgress
);

export const showDeadlineInList = (request: Request) => (
  !!request.deadline
  && (request.status === RequestStatus.New || request.status === RequestStatus.InProgress)
);
