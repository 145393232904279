import { useState } from 'react';

export default function useLoadersStorage(initialValue: boolean = false) {
  const [isLoading, setLoading] = useState<boolean>(initialValue);

  return {
    isLoading,
    startLoading: () => setLoading(true),
    stopLoading: () => setLoading(false),
  };
}
