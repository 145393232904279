import * as React from 'react';

import Toolbar from '@mui/material/Toolbar';
import BottomNavigation from '@mui/material/BottomNavigation';
import Box from '@mui/material/Box';
import PullToRefresh from '@shared/components/PullToRefresh';
import { RequestWizardStateProvider } from '@client/services/context/requestWizard';
import Bar from './Bar';
import Navigation from './Navigation';
import RequestWizard from '../RequestWizard';

interface Props {
  children: React.ReactNode;
}

const Page: React.FC<Props> = ({ children }) => (
  <RequestWizardStateProvider>
    <Bar />
    <Box
      sx={{
        // Add padding to prevent iPhone home bar overlapping
        // The same value in /src/modules/client/components/PageWrapper/Navigation.tsx
        pb: 'env(safe-area-inset-bottom)',
      }}
    >
      <Toolbar />
      <PullToRefresh>
        {children}
      </PullToRefresh>
      <BottomNavigation />
    </Box>
    <Navigation />
    <RequestWizard />
  </RequestWizardStateProvider>
);

export default Page;
