import { MiniBook } from '@shared/domain/orderBook';

export type SubscriptionOptions = OrderBookOptions | TickerOptions;
export type OrderBookCallback = (book: MiniBook) => void;
export type TickerCallback = (ticker: number) => void;
export type SubscriptionCallback = OrderBookCallback | TickerCallback;
export type SubscriptionError = (err: unknown) => void;

export type OrderBookOptions = {
  base: Currency,
  counter: Currency,
  tick?: number,
};

export type TickerOptions = {
  base: Currency,
  counter: Currency,
  tick?: number,
};

export enum SubscriptionStream {
  OrderBook = 'orderBook',
  Ticker = 'ticker',
}

export interface ExchangeSocketsClient {
  subscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ): void,
  unsubscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ): void,
}
