import HttpCode from '@shared/constants/httpCode';
import FormattedErrorReason, { ErrorReason } from '@shared/errors/ErrorReason';

export default class NetworkError extends Error {
  reasons?: FormattedErrorReason[];

  url?: string;

  code: HttpCode;

  constructor(code: HttpCode, reasons?: ErrorReason[], url?: string) {
    super(`Network Error with code ${code}`);
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, NetworkError.prototype);
    this.name = 'NetworkError';
    this.reasons = reasons?.map((reason) => new FormattedErrorReason(reason));
    this.url = url;
    this.code = code;
  }
}
