import * as React from 'react';
import { createRoot } from 'react-dom/client';
import initSentry from '@shared/services/sentry';

import App from './App';
import registerServiceWorker from './serviceWorkerRegistration';

initSentry();

registerServiceWorker();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
