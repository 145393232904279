import * as React from 'react';

import AuthenticatedRoute from '@shared/router/AuthenticatedRoute';
import { useAuth } from '@client/application/useCases/auth';

interface Props {
  children: React.ReactNode;
}

const AuthenticatedRouteWithAuth: React.FC<Props> = ({ children }) => {
  const auth = useAuth();

  return (
    <AuthenticatedRoute isAuthenticated={auth.isAuthenticated}>
      {children}
    </AuthenticatedRoute>
  );
};

export default AuthenticatedRouteWithAuth;
