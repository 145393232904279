import Decimal from 'decimal.js';
import {
  useOrderBookStore,
  useOrderBookSubscription,
} from '@shared/adapters/store/orderBookStoreAdapters';
import {
  useCurrencyTickerClient,
  useCurrencyTickerSubscription,
} from '@shared/adapters/store/currencyTickerStoreAdapters';
import { Book } from '@shared/domain/orderBook';
import { ExchangeAccount } from '@shared/domain/account';
import { RequestSide } from '@shared/domain/request';
import { getTickerFromBooks, Pair } from '@shared/domain/ticker';
import { CurrencyTickerClientService, OrderBookStorageService } from '../ports';

export function pairIsValid(orderBook?: Book) {
  return !!(orderBook?.asks?.length || orderBook?.asks?.length);
}

// TODO get valid pairs for triangulation from back-end
export function useAvailableTriangulationPairs({
  base,
  counter,
  common,
  tick,
  exchangeAccount,
  disabled,
  onError = () => {},
}: {
  base?: Currency,
  counter?: Currency,
  common?: Currency,
  tick: number,
  exchangeAccount?: ExchangeAccount,
  disabled?: boolean,
  onError?: (err: unknown) => void,
}): [Pair | undefined, Pair | undefined, boolean] {
  useOrderBookSubscription({
    base,
    counter: common,
    tick,
    exchangeAccount,
    disabled,
  });
  useOrderBookSubscription({
    base: common,
    counter: base,
    tick,
    exchangeAccount,
    disabled,
  });
  useOrderBookSubscription({
    base: counter,
    counter: common,
    tick,
    exchangeAccount,
    disabled,
  });
  useOrderBookSubscription({
    base: common,
    counter,
    tick,
    exchangeAccount,
    disabled,
  });
  const baseCommonPair = useOrderBookStore({
    base,
    counter: common,
    exchangeAccount,
    disabled,
    onError,
  });
  const commonBasePair = useOrderBookStore({
    base: common,
    counter: base,
    exchangeAccount,
    disabled,
    onError,
  });
  const counterCommonPair = useOrderBookStore({
    base: counter,
    counter: common,
    exchangeAccount,
    disabled,
    onError,
  });
  const commonCounterPair = useOrderBookStore({
    base: common,
    counter,
    exchangeAccount,
    disabled,
    onError,
  });
  const baseCommon = pairIsValid(baseCommonPair.orderBook);
  const commonBase = pairIsValid(commonBasePair.orderBook);
  const counterCommon = pairIsValid(counterCommonPair.orderBook);
  const commonCounter = pairIsValid(commonCounterPair.orderBook);

  // Triangulation is not available if no pair with base and common currency
  if (!baseCommon && !commonBase) return [undefined, undefined, true];
  // Not available if no pair with counter and common currency
  if (!counterCommon && !commonCounter) return [undefined, undefined, true];

  return [{
    base: baseCommon ? base : common,
    counter: baseCommon ? common : base,
  }, {
    base: counterCommon ? counter : common,
    counter: counterCommon ? common : counter,
  }, (
    baseCommon
      ? baseCommonPair.isLoading
      : commonBasePair.isLoading
  ) || (
    counterCommon
      ? counterCommonPair.isLoading
      : commonCounterPair.isLoading
  )];
}

export function useDirectCurrencyTicker({
  base,
  counter,
  tick,
  exchangeAccount,
  disabled,
  onError,
}: {
  base?: Currency,
  counter?: Currency,
  tick: number,
  exchangeAccount?: ExchangeAccount,
  disabled?: boolean,
  onError?: (err: unknown) => void,
}) {
  useCurrencyTickerSubscription({
    base,
    counter,
    tick,
    exchangeAccount,
    disabled,
  });
  const client: CurrencyTickerClientService = useCurrencyTickerClient({
    base,
    counter,
    exchangeAccount,
    disabled,
    onError,
  });

  return {
    ticker: client.ticker,
    isLoading: client.isLoading,
    error: client.error,
  };
}

export function useCurrencyTickerFromBook({
  base,
  counter,
  common,
  commonAmount,
  tick,
  exchangeAccount,
  side,
  disabled,
  onError,
}: {
  base?: Currency,
  counter?: Currency,
  common?: Currency,
  commonAmount?: CurrencyAmount,
  tick: number,
  exchangeAccount?: ExchangeAccount,
  side?: RequestSide,
  disabled?: boolean,
  onError?: (err: unknown) => void,
}) {
  const [firstPair, secondPair, pairsLoading] = useAvailableTriangulationPairs({
    base,
    counter,
    common,
    tick,
    exchangeAccount,
    disabled,
    onError,
  });

  useOrderBookSubscription({
    base: firstPair?.base,
    counter: firstPair?.counter,
    tick,
    exchangeAccount,
    disabled,
  });
  useOrderBookSubscription({
    base: secondPair?.base,
    counter: secondPair?.counter,
    tick,
    exchangeAccount,
    disabled,
  });
  const firstPairStore: OrderBookStorageService = useOrderBookStore({
    base: firstPair?.base,
    counter: firstPair?.counter,
    exchangeAccount,
    disabled,
    onError,
  });

  const secondPairStore: OrderBookStorageService = useOrderBookStore({
    base: secondPair?.base,
    counter: secondPair?.counter,
    exchangeAccount,
    disabled,
    onError,
  });

  if (firstPairStore.error || secondPairStore.error) {
    return {
      ticker: undefined,
      approximation: false,
      isLoading: false,
      error: firstPairStore.error || secondPairStore.error,
    };
  }

  if (firstPairStore.isLoading || secondPairStore.isLoading || pairsLoading) {
    return {
      ticker: undefined,
      approximation: false,
      isLoading: true,
      error: undefined,
    };
  }

  if (
    !firstPairStore.orderBook
    || !secondPairStore.orderBook
    || !common
    || !side
    || !firstPair
    || !secondPair
  ) {
    return {
      ticker: undefined,
      approximation: false,
      isLoading: false,
      error: undefined,
    };
  }

  const { ticker, approximation } = getTickerFromBooks({
    firstPair,
    secondPair,
    firstBook: firstPairStore.orderBook,
    secondBook: secondPairStore.orderBook,
    commonCurrency: common,
    commonAmount: commonAmount ? new Decimal(commonAmount).toNumber() : 0,
    side,
  });

  return {
    ticker,
    approximation,
    isLoading: false,
    error: undefined,
  };
}

export function useCurrencyTicker({
  base,
  counter,
  common,
  commonAmount,
  tick = 1000,
  exchangeAccount,
  side,
  disabled,
  onError,
}: {
  base?: Currency,
  counter?: Currency,
  common?: Currency,
  commonAmount?: CurrencyAmount,
  tick?: number,
  exchangeAccount?: ExchangeAccount,
  side?: RequestSide,
  disabled?: boolean,
  onError?: (err: unknown) => void,
}) {
  const triangulationTicker = useCurrencyTickerFromBook({
    base,
    counter,
    common,
    commonAmount,
    tick,
    exchangeAccount,
    side,
    disabled,
    onError,
  });

  const directTicker = useDirectCurrencyTicker({
    base,
    counter,
    tick,
    exchangeAccount,
    disabled: !!common || disabled,
    onError,
  });

  return common ? triangulationTicker : {
    ...directTicker,
    approximation: false,
  };
}
