import * as React from 'react';
import { useState, useCallback } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { getAssetIcon, getFallbackIcon } from '@shared/services/getAssetIcon';

const StyledAvatar = styled(Avatar)({
  width: 30,
  height: 30,
});

interface CurrencyIconProps {
  currency: Currency,
}

const CurrencyIcon: React.FC<CurrencyIconProps & AvatarProps> = ({
  currency, ...props
}) => {
  const [url, setUrl] = useState(getAssetIcon(currency));

  const errorHandler = useCallback(() => {
    const fallbackIcon = getFallbackIcon(currency);
    if (fallbackIcon && url !== fallbackIcon) setUrl(fallbackIcon);
  }, [url]);
  return (
  /* eslint-disable react/jsx-props-no-spreading */
    <StyledAvatar
      alt={currency}
      src={url}
      imgProps={{ onError: errorHandler }}
      {...props}
    />
  );
};

export default CurrencyIcon;
