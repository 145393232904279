import * as React from 'react';
import useDistanceToNow from '@shared/hooks/useDistanceToNow';
import { FormatDistanceToNowStrictLocalizedProps } from '@shared/i18n/date/formatDistanceToNowStrict';

const DistanceToNow: React.FC<FormatDistanceToNowStrictLocalizedProps> = (
  args,
) => {
  const distance = useDistanceToNow(args);
  return (
    <>
      {distance}
    </>
  );
};

export default DistanceToNow;
