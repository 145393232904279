import api from '@shared/services/api';
import { ExchangeAccount } from '@shared/domain/account';
import removeEmpty from '@shared/utils/removeEmpty';
import {
  CommentForm,
  DeadlineForm,
  SavedRequest,
  RequestsFilters,
  RequestSide,
  RequestCost,
} from '@client/domain/request';
import { WizardData } from '@client/domain/requestWizard';

export const saveRequest = (request: Partial<WizardData>): Promise<SavedRequest> => api.post('v1/requests', {
  body: request,
});

export type GetTradingRequestsResponse = {
  data: SavedRequest[],
  total: number,
};

export const getRequests = ({
  filters,
  offset,
  limit,
}: {
  filters?: RequestsFilters,
  offset?: number,
  limit?: number,
} = {}): Promise<GetTradingRequestsResponse> => {
  const searchParams = new URLSearchParams();
  if (offset) searchParams.append('offset', offset.toString());
  if (limit) searchParams.append('limit', limit.toString());
  if (filters?.statuses) filters.statuses.forEach((status) => searchParams.append('statuses', status));
  if (filters?.currency) searchParams.append('currency', filters.currency);
  if (filters?.exchangeAccount) searchParams.append('exchange_account', filters.exchangeAccount);
  const searchFragment = searchParams.toString();

  return api.get(`v1/requests${searchFragment ? `?${searchFragment}` : ''}`);
};

export const getRequest = (uuid: Uuid): Promise<SavedRequest> => api.get(`v1/requests/${uuid}`);

export const cancelRequest = (uuid: Uuid): Promise<SavedRequest> => api.delete(`v1/requests/${uuid}`);

export const addRequestComment = ({ uuid, data }: { uuid: Uuid, data: CommentForm }): Promise<SavedRequest> => api.post(`v1/requests/${uuid}/comment`, {
  body: data,
});

export const editRequestDeadline = ({ uuid, data }: { uuid: Uuid, data: DeadlineForm }): Promise<SavedRequest> => api.post(`v1/requests/${uuid}/deadline`, {
  body: data,
});

export type RequestCostParams = {
  exchange_account: ExchangeAccount,
  side: RequestSide,
  base: Currency,
  counter: Currency,
  common?: Currency,
  price: CurrencyAmount,
  requested: CurrencyAmount,
};

export const getRequestCost = (request: RequestCostParams): Promise<RequestCost> => {
  const searchParams = new URLSearchParams(removeEmpty(request));

  return api.get(`v1/requests/cost?${searchParams.toString()}`);
};
