import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

const HideSmallBalances: React.FC<{
  value: boolean,
  onChange: (value: boolean) => void,
}> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const handleSwitchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  }, [onChange]);

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch size="small" checked={value} onChange={handleSwitchChange} />}
        label={<Typography color="grey.400" variant="subtitle2">{t('balances.hideSmall')}</Typography>}
      />
    </FormGroup>
  );
};

export default HideSmallBalances;
