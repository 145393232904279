import { useMemo } from 'react';
import { useQuery, useInfiniteQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  CostStorageService,
  RequestsStorageService,
  RequestStorageService,
} from '@client/application/ports';
import { useLoadRequestClient, useRequestsClient, useCostClient } from '@client/adapters/client/requestsClientAdapters';
import useFiltersStorage from '@client/adapters/store/filtersStoreAdapters';
import { ExchangeAccount } from '@shared/domain/account';
import { RequestCost, RequestSide, Requests } from '@client/domain/request';

export function useRequestsStorage(limit: number = 10): RequestsStorageService {
  const client = useRequestsClient();
  const { filterValues } = useFiltersStorage();
  const {
    isLoading,
    error,
    data,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['requests', filterValues],
    ({ pageParam: offset }) => client.load({ filters: filterValues, limit, offset }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const loadedTotal = allPages.reduce(
          (total, { data: pageData }) => total + pageData.length,
          0,
        );

        return lastPage.total > loadedTotal ? loadedTotal : undefined;
      },
    },
  );

  const requests = useMemo(
    () => data?.pages.reduce<Requests>(
      (accumulator, { data: pageData, total }) => ({
        data: [...accumulator.data, ...pageData],
        total,
      }),
      {
        data: [],
        total: 0,
      },
    ),
    [data],
  );

  return {
    requests,
    isLoading,
    error: error as Error | undefined,
    loadMore: fetchNextPage,
    isLoadingMore: isFetchingNextPage,
  };
}

export function useRequestStorage(uuid: Uuid): RequestStorageService {
  const client = useLoadRequestClient();
  const {
    isLoading,
    error,
    data,
  } = useQuery(
    ['request', uuid],
    () => client.load(uuid),
  );

  return {
    request: data,
    isLoading,
    error: error as Error | undefined,
  };
}

type CostStorageProps = {
  exchangeAccount?: ExchangeAccount,
  side?: RequestSide,
  baseCurrency?: Currency,
  counterCurrency?: Currency,
  commonCurrency?: Currency,
  price?: CurrencyAmount,
  requestedBase?: CurrencyAmount,
};

export function useCostStorage(
  props: CostStorageProps,
  options?: UseQueryOptions<RequestCost>,
): CostStorageService {
  const client = useCostClient();
  const {
    isLoading,
    error,
    data,
  } = useQuery<RequestCost>(
    ['request', 'cost', props],
    () => client.load(<{
      exchangeAccount: ExchangeAccount,
      side: RequestSide,
      baseCurrency: Currency,
      counterCurrency: Currency,
      commonCurrency?: Currency,
      price: CurrencyAmount,
      requestedBase?: CurrencyAmount,
    }>props),
    {
      keepPreviousData: true,
      retry: false,
      ...options,
    },
  );

  return {
    cost: data,
    isLoading,
    error: error as Error | undefined,
  };
}
