import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { FiltersStateProvider } from '@shared/services/context/filters';
import useTotalBalances from '@client/application/useCases/totalBalances';
import AccountSelect from '@client/components/AccountSelect';
import useFiltersStorage from '@client/adapters/store/filtersStoreAdapters';
import TotalBalances from './TotalBalances';
import Balances from './Balances';

const BalancesPage = () => {
  const { setValues, filterValues } = useFiltersStorage();
  const { totalBalances } = useTotalBalances();

  return (
    <Container disableGutters maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} pt={2} pb={2}>
        <AccountSelect
          value={filterValues?.exchangeAccount}
          onChange={(exchangeAccount) => setValues?.({ exchangeAccount })}
        />
        {totalBalances && (
          <TotalBalances total={totalBalances} />
        )}
      </Box>
      <Balances />
    </Container>
  );
};

const BalancesPageWithFilters = () => (
  <FiltersStateProvider initValues={{ exchangeAccount: undefined, search: '' }} keys={['exchangeAccount', 'search']}>
    <BalancesPage />
  </FiltersStateProvider>
);

export default BalancesPageWithFilters;
