import * as React from 'react';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import EventIcon from '@mui/icons-material/Event';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import format from '@shared/i18n/date/format';
import DeadlineForm from '@shared/components/DeadlineForm';
import { WizardData } from '@client/domain/requestWizard';

const DeadlineInput = (() => {
  const { control, formState } = useFormContext<WizardData>();
  const [showDeadlineForm, setShowDeadlineForm] = useState(false);
  const { t } = useTranslation();
  const { errors } = formState;
  const error = errors.deadline;

  return (
    <Controller
      control={control}
      name="deadline"
      render={({ field: { onChange, onBlur, value } }) => (
        <FormControl sx={{ width: '100%' }} component="fieldset" onBlur={onBlur} error={!!error}>
          <FormLabel>{t('requestWizard.total.deadline')}</FormLabel>
          <ButtonBase
            onClick={() => setShowDeadlineForm(true)}
            sx={{
              ml: 'auto',
              p: 1,
            }}
          >
            {value && (
              <Typography component="span" mr={1}>
                {format(new Date(value), { dateOnly: true })}
              </Typography>
            )}
            <EventIcon />
          </ButtonBase>
          {showDeadlineForm && (
            <DeadlineForm
              onSubmit={async ({ deadline }) => onChange(deadline)}
              onCancel={() => setShowDeadlineForm(false)}
              defaultValue={value}
              deadlineLabel={t('requestWizard.total.deadline')}
              submitLabel={t('shared.buttons.ok')}
              cancelLabel={t('shared.buttons.cancel')}
              isLoading={false}
            />
          )}
          {error && (
            <FormHelperText sx={{ textAlign: 'right' }}>{error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
});

export default DeadlineInput;
