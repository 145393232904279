import { Method, parseBody } from '@shared/services/api';
import NetworkError from '@shared/errors/NetworkError';
import httpCode from '@shared/constants/httpCode';

const binanceApi = (base: string, endpoint: string) => window
  .fetch(`${base}/${endpoint}`, {
    method: Method.Get,
  })
  .catch((e) => {
    if (e instanceof TypeError) throw new NetworkError(httpCode.BadRequest);
    throw e;
  })
  .then(async (response) => {
    const body = await parseBody(response);

    if (response.ok) {
      return body;
    }

    throw new NetworkError(response.status);
  });

export default binanceApi;
