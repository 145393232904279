import { format } from 'date-fns';

const datePattern = 'LLL dd, yyyy';
const timePattern = 'HH:mm:ss';

const formatDate = (date: Date | number, options?: { dateOnly?: boolean, timeOnly?: boolean }) => {
  let pattern: string;

  if (options?.dateOnly) {
    pattern = datePattern;
  } else if (options?.timeOnly) {
    pattern = timePattern;
  } else {
    pattern = `${datePattern} ${timePattern}`;
  }

  return format(date, pattern);
};

export default formatDate;
