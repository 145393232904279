import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ProgressProps {
  value: Progress,
}

const CircularProgressWithLabel: React.FC<CircularProgressProps & ProgressProps> = (props) => {
  const { value } = props;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <CircularProgress
        size={16}
        variant="determinate"
        sx={{ color: 'grey.700' }}
        {...props}
        value={100}
      />
      <CircularProgress
        size={16}
        variant="determinate"
        sx={{
          position: 'absolute',
          left: 0,
        }}
        {...props}
      />
      <Typography ml={0.5} component="span" color="primary.main">
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  );
};

export default CircularProgressWithLabel;
