import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@shared/components/LoadingButton';

interface LogInFormProps {
  isLoading: boolean,
  showSuccessMessage?: boolean,
  successMessage?: string,
  goToLoginLabel?: string,
  error?: string,
  title?: string,
  passwordLabel?: string,
  repeatPasswordLabel?: string,
  submitLabel?: string,
  validationTitles?: string[],
  validationRules?: ((value: string) => boolean)[],
  onSubmit: (formData: { password: string }) => void
}

export function isPasswordValid({
  validation,
  password,
  repeatPassword,
}: {
  validation?: boolean[],
  password: string,
  repeatPassword: string
}) {
  const validationPassed = validation?.reduce((prev, curr) => prev && curr);
  const repeatedCorrectly = password === repeatPassword;

  return validationPassed && repeatedCorrectly;
}

const ResetPasswordForm: React.FC<LogInFormProps> = ({
  onSubmit,
  isLoading,
  showSuccessMessage,
  successMessage,
  goToLoginLabel,
  error,
  title,
  passwordLabel,
  repeatPasswordLabel,
  submitLabel,
  validationTitles,
  validationRules,
}) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [validation, setValidation] = useState(validationRules?.map((rule) => rule(password)));
  const isValid = isPasswordValid({ validation, password, repeatPassword });
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
    setValidation(validationRules?.map((rule) => rule(value)));
  };
  const handleRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ password });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack mt={12}>
        <Typography component="h1" variant="h5" align="center">
          {title}
        </Typography>
        {showSuccessMessage ? (
          <>
            <Alert
              sx={{ my: 2 }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
            <Button
              onClick={() => navigate('/', { replace: true })}
              fullWidth
              variant="contained"
              size="large"
            >
              {goToLoginLabel}
            </Button>
          </>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            {/* Needed for password managers */}
            <TextField
              id="email"
              type="email"
              name="email"
              autoComplete="email"
              sx={{ display: 'none' }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={passwordLabel}
              type="password"
              id="password"
              autoComplete="new-password"
              onChange={handlePasswordChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="repeatPassword"
              label={repeatPasswordLabel}
              type="password"
              id="repeatPassword"
              autoComplete="new-password"
              onChange={handleRepeatPasswordChange}
            />
            <Paper sx={{ mt: 1 }}>
              <List>
                {/* eslint-disable react/no-array-index-key */}
                {validationTitles?.map((label, index) => (
                  <ListItem key={index} sx={{ py: 0.5 }}>
                    <ListItemIcon>
                      {validation?.[index] ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItem>
                ))}
              </List>
            </Paper>
            {error && <Alert sx={{ mt: 2 }} severity="error">{error}</Alert>}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ my: 2 }}
              disabled={!isValid}
              loading={isLoading}
            >
              {submitLabel}
            </LoadingButton>
          </Box>
        )}
      </Stack>
    </Container>
  );
};

ResetPasswordForm.defaultProps = {
  showSuccessMessage: false,
  successMessage: 'The password has been successfully updated.',
  goToLoginLabel: 'Go To Login Page',
  error: undefined,
  title: 'Reset Password',
  passwordLabel: 'Password',
  repeatPasswordLabel: 'Repeat Password',
  submitLabel: 'Reset',
  validationTitles: [
    'Minimum 8 characters',
    'At least 1 digit',
    'At least 1 upper case letter',
  ],
  validationRules: [
    (value: string) => value.length >= 8,
    (value: string) => !!value.match(/\d/),
    (value: string) => !!value.match(/[A-Z]/),
  ],
};

export default ResetPasswordForm;
