import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  WizardData,
  WizardState,
  WizardStep,
  getDataFromState,
  updateState,
  defaultWizardState,
} from '@client/domain/requestWizard';
import InternalError from '@shared/errors/InternalError';

type RequestsStateContextValues = {
  data: WizardData,
  setData: (step: WizardStep, newData?: WizardState[WizardStep]) => WizardData,
  step?: WizardStep,
  setStep: Dispatch<SetStateAction<WizardStep | undefined>>,
  setDefaultState: Dispatch<SetStateAction<Partial<WizardState>>>,
} | undefined;

const RequestsContext = React.createContext<RequestsStateContextValues>(undefined);

function useRequestWizardState() {
  const context = React.useContext(RequestsContext);
  if (!context) {
    throw new InternalError('useRequestWizardState must be used within a RequestWizardStateProvider');
  }
  return context;
}

interface Props {
  children: React.ReactNode;
}

const RequestWizardStateProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const [defaultState, setDefaultState] = useState<Partial<WizardState>>(defaultWizardState);
  const [step, setStep] = useState<WizardStep>();
  const [state, setState] = useState<Partial<WizardState>>({});
  const data = useMemo(() => getDataFromState(state, defaultState), [state, defaultState]);
  const setData = useCallback(
    (dataStep: WizardStep, newData?: WizardState[WizardStep]) => {
      const updatedState = updateState(state, dataStep, newData);
      setState(updatedState);
      return getDataFromState(updatedState, defaultState);
    },
    [state, defaultState, setState],
  );

  useEffect(() => {
    setStep(undefined);
  }, [location]);

  const value = useMemo(() => ({
    data,
    setData,
    step,
    setStep,
    setDefaultState,
  }), [data, setData, step, setStep, setDefaultState]);

  return (
    <RequestsContext.Provider value={value}>
      {children}
    </RequestsContext.Provider>
  );
};

export { RequestWizardStateProvider, useRequestWizardState };
