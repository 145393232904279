import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import getRandomInt from '@shared/utils/random';

export const DetailsItemSkeleton: React.FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }} py={0.5}>
    <Skeleton width={getRandomInt(100, 120)} height={28} />
    <Skeleton width={getRandomInt(30, 100)} height={28} />
  </Box>
);

interface DetailsItemProps {
  icon: React.ReactNode,
  label: React.ReactNode,
  value?: React.ReactNode,
  color?: string,
}

const DetailsItem: React.FC<DetailsItemProps> = ({
  icon,
  label,
  value,
  color,
}) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }} py={0.5}>
    <Typography variant="subtitle1" color={color} fontWeight="500" sx={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <span style={{ marginLeft: '10px' }}>
        {label}
      </span>
    </Typography>
    <Typography component="div" variant="body2" color="grey.50" fontWeight="500">
      {value}
    </Typography>
  </Box>
);

DetailsItem.defaultProps = {
  value: '',
  color: 'grey.400',
};

export default DetailsItem;
