import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import logo from '@assets/img/logo.svg';
import useTotalBalances from '@client/application/useCases/totalBalances';

const Bar: React.FC = () => {
  const { totalBalances } = useTotalBalances();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppBar position="fixed">
      <Container disableGutters maxWidth="md">
        <Toolbar disableGutters sx={{ px: 2 }}>
          <img width="32" height="32" alt="Axiomo" src={logo} />
          <Box sx={{ flexGrow: 1 }} />
          <Fade in={location.pathname !== '/balances' && !!totalBalances}>
            <Button
              sx={{ height: 32 }}
              size="small"
              variant="outlined"
              startIcon="₮"
              onClick={() => navigate('/balances')}
            >
              {totalBalances}
            </Button>
          </Fade>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Bar;
