import Theme from '@shared/domain/theme';
import Language from '@shared/domain/language';

export { default as Theme } from '@shared/domain/theme';
export { default as Language } from '@shared/domain/language';

export type Settings = {
  theme: Theme,
  language: Language,
  equivalentCurrency: Currency,
  smallBalancesEquivalent: number,
  hideSmallBalances: boolean,
};

export const defaultSettings: Settings = {
  theme: Theme.Dark,
  language: Language.EnUs,
  equivalentCurrency: 'USDT',
  smallBalancesEquivalent: 1,
  hideSmallBalances: false,
};

export function initiateSettings(settings?: Partial<Settings>): Settings {
  return { ...defaultSettings, ...settings };
}

export function changeSettings(settings: Settings, updates: Partial<Settings>): Settings {
  return { ...settings, ...updates };
}
