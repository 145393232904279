import useCurrencyStorage from '@client/adapters/store/currenciesStoreAdapters';
import { RequestSide } from '@client/domain/request';
import { ExchangeAccount } from '@shared/domain/account';
import { CurrencyType } from '@client/domain/currency';
import { CurrencyStorageService } from '../ports';

type SearchCurrenciesProps = {
  base?: Currency,
  counter?: Currency,
  common?: Currency,
  side?: RequestSide,
  type?: CurrencyType,
  exchangeAccount?: ExchangeAccount,
  triangulation?: boolean,
};

export default function useCurrencySearch(props: SearchCurrenciesProps) {
  const currencyStorage: CurrencyStorageService = useCurrencyStorage(props);

  return {
    currencies: currencyStorage.currencies,
    isLoading: currencyStorage.isLoading,
    error: currencyStorage.error,
  };
}
