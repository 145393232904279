import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller, FieldErrors } from 'react-hook-form';
import { WizardData } from '@client/domain/requestWizard';
import CurrencyTicker from '@client/components/CurrencyTicker';
import PriceInput from '../PriceInput';
import AdditionalOptions from './AdditionalOptions';

const LimitRequest: React.FC<{
  request: Partial<WizardData>,
  errors: FieldErrors<Record<'requestedPrice', any>>,
}> = ({ request, errors }) => {
  const { t } = useTranslation();
  const { setValue, watch, control } = useFormContext();
  const requestedPrice = watch('requestedPrice');

  const handleTickerInit = useCallback((ticker: number) => {
    if (!requestedPrice) {
      setValue('requestedPrice', ticker.toString(), { shouldValidate: true });
    }
  }, [requestedPrice]);

  const handleTickerClick = useCallback((ticker: number) => {
    setValue('requestedPrice', ticker.toString(), { shouldValidate: true });
  }, []);

  if (!request.baseCurrency || !request.counterCurrency || !request.exchangeAccount) return null;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      {!request.isTriangulation && (
        <AdditionalOptions />
      )}
      <Controller
        defaultValue=""
        control={control}
        name="requestedPrice"
        render={({ field }) => (
          <PriceInput
            label={t('requestWizard.total.price')}
            placeholder={t('requestWizard.total.pricePlaceholder')}
            currency={request.counterCurrency!}
            error={errors.requestedPrice}
            {...field}
          />
        )}
      />
      <CurrencyTicker
        onInitiate={handleTickerInit}
        base={request.baseCurrency}
        counter={request.counterCurrency}
        common={request.commonCurrency}
        exchangeAccount={request.exchangeAccount}
        side={request.side}
        onClick={handleTickerClick}
      />
    </>
  );
};

export default LimitRequest;
