import { useState } from 'react';
import { useAuthState } from '@shared/services/context/auth';

import { AuthStorageService } from '@client/application/ports';

export default function useAuthStorage(): AuthStorageService {
  const [isPasswordUpdated, setPasswordUpdated] = useState<boolean>(false);
  const state = useAuthState();

  return {
    token: state.token,
    saveToken: state.saveToken,
    removeToken: state.removeToken,
    isPasswordUpdated,
    setPasswordIsUpdated: () => setPasswordUpdated(true),
  };
}
