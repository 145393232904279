import * as React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

interface AuthenticatedRouteProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children, isAuthenticated }) => {
  const location = useLocation();

  return isAuthenticated ? (<>{children}</>) : (
    <Navigate
      to="/login"
      state={{ from: location }}
      replace
    />
  );
};

export default AuthenticatedRoute;
