import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import LoadingButton from '@shared/components/LoadingButton';
import DialogTitleWithClose from '@shared/components/Dialog/DialogTitleWithClose';
import { useCancelRequest } from '@client/application/useCases/request';
import { SavedRequest } from '@client/domain/request';

export interface CancelRequestProps {
  isOpen: boolean,
  onCloseModal: () => void,
  request: SavedRequest,
}

const CancelRequest = ({
  isOpen,
  onCloseModal,
  request,
}: CancelRequestProps) => {
  const { t } = useTranslation();
  const { cancelRequest, isCanceling } = useCancelRequest(request.uuid);

  const handleCancel = useCallback(() => {
    cancelRequest().then(onCloseModal);
  }, [cancelRequest, onCloseModal]);

  return (
    <Dialog open={isOpen}>
      <DialogTitleWithClose onClose={() => !isCanceling && onCloseModal()}>
        {t('cancelRequest.title', { number: request.number })}
      </DialogTitleWithClose>
      <DialogContent>
        <Typography align="center" variant="subtitle1" mb={2}>
          {t('cancelRequest.description')}
        </Typography>
        <LoadingButton
          id="cancel-request"
          variant="contained"
          color="error"
          fullWidth
          loading={isCanceling}
          onClick={handleCancel}
        >
          {t('cancelRequest.submit')}
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

export default CancelRequest;
