import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useRequestWizard from '@client/application/useCases/requestWizard';
import { RequestSide } from '@client/domain/request';
import { WizardData, WizardStep } from '@client/domain/requestWizard';
import { CurrencyType } from '@client/domain/currency';
import SelectRequestSide from './SelectRequestSide';
import SelectExchangeAccount from './SelectExchangeAccount';
import SelectCurrencyPair from './SelectCurrencyPair';
import SelectCommon from './SelectCommon';
import SelectRequestTotal from './SelectRequestTotal';

type PairInput = CurrencyType.Base | CurrencyType.Counter;

const RequestWizard = () => {
  const [selectedPairInput, setSelectedPairInput] = useState<PairInput>();
  const requestWizard = useRequestWizard();
  const { step, setStep } = requestWizard;
  const isStarted = !!step;
  const showDialog = (
    step === WizardStep.Pair || step === WizardStep.Common || step === WizardStep.Total
  );

  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const color = requestWizard.data.side === RequestSide.Buy ? 'success.light' : 'error.light';
  const ArrowIcon = requestWizard.data.side === RequestSide.Buy
    ? ArrowCircleUpIcon
    : ArrowCircleDownIcon;
  const onEditStep = useCallback((current: WizardStep, next: WizardStep) => (
    (updates: Partial<WizardData>) => {
      requestWizard.edit(current, updates);
      setStep(next);
    }
  ), [setStep, requestWizard.edit]);

  const onEditPair = useCallback((updates: Partial<WizardData>) => {
    requestWizard.edit(WizardStep.Pair, updates);
    setStep(updates.isTriangulation ? WizardStep.Common : WizardStep.Total);
  }, [setStep, requestWizard.edit]);

  const onSubmitTotal = useCallback(
    (updates: Partial<WizardData>) => requestWizard.save(WizardStep.Total, updates),
    [requestWizard.save],
  );

  useEffect(() => {
    if (isStarted) {
      setSelectedPairInput(
        requestWizard.data.baseCurrency ? CurrencyType.Counter : CurrencyType.Base,
      );
    } else {
      setSelectedPairInput(undefined);
    }
  }, [isStarted]);

  return (
    <>
      <SelectRequestSide
        isOpen={step === WizardStep.Side}
        onClose={requestWizard.stop}
        onSelect={onEditStep(WizardStep.Side, WizardStep.Account)}
      />
      <SelectExchangeAccount
        isOpen={step === WizardStep.Account}
        onClose={requestWizard.stop}
        onSelect={onEditStep(WizardStep.Account, WizardStep.Pair)}
      />
      <Dialog fullScreen={fullScreen} open={showDialog} scroll="body" PaperProps={{ elevation: 0 }}>
        <Toolbar sx={{ py: 2, pr: 1 }}>
          <ArrowIcon sx={{ color, mr: 2 }} />
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" color={color} lineHeight={1}>
              {t(`requestWizard.total.${requestWizard.data.side}.title`)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {t(`shared.exchangeAccount.${requestWizard.data.exchangeAccount}`)}
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={requestWizard.stop}
            sx={{ color: theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {(step === WizardStep.Pair || step === WizardStep.Common) && (
          <SelectCurrencyPair
            request={requestWizard.data}
            onEdit={onEditPair}
            inputToClear={step === WizardStep.Pair ? selectedPairInput : undefined}
            setInputToClear={setSelectedPairInput}
          />
        )}
        {step === WizardStep.Total && (
          <SelectRequestTotal
            request={requestWizard.data}
            onSubmit={onSubmitTotal}
            isLoading={requestWizard.isSaving}
            onGoBack={(input: CurrencyType) => {
              if (input === CurrencyType.Common) {
                setStep(WizardStep.Common);
              } else {
                setSelectedPairInput(input);
                setStep(WizardStep.Pair);
              }
            }}
          />
        )}
        <SelectCommon
          isOpen={step === WizardStep.Common}
          onClose={() => setStep(WizardStep.Pair)}
          request={requestWizard.data}
          onSelect={onEditStep(WizardStep.Common, WizardStep.Total)}
        />
      </Dialog>
    </>
  );
};

export default RequestWizard;
