import { useState } from 'react';

export default function useErrorStorage<ErrorType = any>() {
  const [error, setError] = useState<ErrorType>();

  return {
    error,
    setError,
  };
}
