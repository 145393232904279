import Decimal from 'decimal.js';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import RepeatIcon from '@mui/icons-material/Repeat';
import IconButton from '@mui/material/IconButton';
import { ExchangeAccount, spotAccounts, oppositeAccount } from '@shared/domain/account';
import { WizardData } from '@client/domain/requestWizard';
import { RequestSide, RequestCost } from '@client/domain/request';
import Transfer from '@client/components/Transfer';
import { transferAccounts } from '@client/domain/transfer';

export const CostSkeleton: React.FC = () => (
  <Skeleton sx={{ display: 'inline-block' }} width={70} height={24} />
);

const CostComponent: React.FC<{
  asset: Currency,
  exchangeAccount?: ExchangeAccount,
  value?: CurrencyAmount,
  isLoading: boolean,
  showTransfer?: boolean,
  highlightZero?: boolean,
  title: string,
  onClick?: (value: CurrencyAmount) => void,
}> = ({
  asset,
  exchangeAccount,
  value,
  isLoading,
  showTransfer,
  highlightZero,
  title,
  onClick,
}) => {
  const [openTransfer, setOpenTransfer] = useState<boolean>(false);
  const isValueEmpty = !value;
  const isValueZero = !isValueEmpty && new Decimal(value).isZero();
  const clickable = onClick && !isValueEmpty && !isValueZero;

  return (
    <>
      <Typography
        align="right"
        variant="subtitle1"
        component="div"
        color="grey.500"
        fontSize="0.875rem"
        onClick={clickable ? () => onClick(value) : undefined}
        sx={{ cursor: clickable ? 'pointer' : 'auto' }}
      >
        {showTransfer && (
          <IconButton size="small" onClick={() => setOpenTransfer(true)}>
            <RepeatIcon sx={{ color: 'grey.500' }} />
          </IconButton>
        )}
        {title}
        :&nbsp;
        {isLoading || isValueEmpty ? (
          <CostSkeleton />
        ) : (
          <Typography
            variant="inherit"
            component="span"
            color={isValueZero && highlightZero ? 'error.main' : 'grey.500'}
          >
            {value}
          </Typography>
        )}
        &nbsp;
        {asset}
      </Typography>
      {openTransfer && (
        <Transfer
          isOpen={openTransfer}
          onClose={() => setOpenTransfer(false)}
          initialData={{
            from: oppositeAccount[exchangeAccount!],
            to: exchangeAccount,
            asset,
          }}
          disabledFields={['from', 'to', 'asset']}
        />
      )}
    </>
  );
};

CostComponent.defaultProps = {
  value: undefined,
  onClick: undefined,
  exchangeAccount: undefined,
  showTransfer: false,
  highlightZero: false,
};

type BaseCostProps = {
  request: Partial<WizardData>,
  cost?: RequestCost,
  isLoading: boolean,
  onClickMax?: (value: CurrencyAmount) => void,
};

export const BaseCost: React.FC<BaseCostProps> = ({
  request,
  cost,
  isLoading,
  onClickMax,
}) => {
  const { t } = useTranslation();
  const showAvailable = (
    request.exchangeAccount
    && spotAccounts.includes(request.exchangeAccount)
    && request.side === RequestSide.Sell
  );

  return (
    <>
      {showAvailable && (
        <CostComponent
          asset={request.baseCurrency!}
          value={cost?.available}
          isLoading={isLoading}
          highlightZero
          title={t('requestWizard.total.available')}
          showTransfer={
            request.exchangeAccount
            && transferAccounts.includes(request.exchangeAccount)
          }
          exchangeAccount={request.exchangeAccount!}
        />
      )}
      <CostComponent
        asset={request.baseCurrency!}
        value={cost?.max}
        isLoading={isLoading}
        highlightZero
        title={t('requestWizard.total.max')}
        onClick={onClickMax}
      />
    </>
  );
};

BaseCost.defaultProps = {
  cost: undefined,
  onClickMax: undefined,
};

type CounterCostProps = {
  request: Partial<WizardData>,
  cost?: RequestCost,
  isLoading: boolean,
};

export const CounterCost: React.FC<CounterCostProps> = ({
  request,
  cost,
  isLoading,
}) => {
  const { t } = useTranslation();
  const showAvailable = !(
    request.exchangeAccount
    && spotAccounts.includes(request.exchangeAccount)
    && request.side === RequestSide.Sell
  );

  return (
    <>
      {showAvailable && (
        <CostComponent
          asset={request.counterCurrency!}
          value={cost?.available}
          isLoading={isLoading}
          title={t('requestWizard.total.available')}
          showTransfer={
            request.exchangeAccount
            && transferAccounts.includes(request.exchangeAccount)
          }
          highlightZero
          exchangeAccount={request.exchangeAccount!}
        />
      )}
      {request.exchangeAccount === ExchangeAccount.BinanceUSDM && (
        <CostComponent
          asset={request.counterCurrency!}
          value={cost?.cost}
          isLoading={isLoading}
          title={t('requestWizard.total.cost')}
        />
      )}
    </>
  );
};

CounterCost.defaultProps = {
  cost: undefined,
};

type CommonAvailableProps = {
  request: Partial<WizardData>,
  cost?: RequestCost,
  isLoading: boolean,
};

export const CommonAvailable: React.FC<CommonAvailableProps> = ({
  request,
  cost,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CostComponent
      asset={request.commonCurrency!}
      value={cost?.commonAvailable}
      isLoading={isLoading}
      title={t('requestWizard.total.available')}
    />
  );
};

CommonAvailable.defaultProps = {
  cost: undefined,
};
