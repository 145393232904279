import * as React from 'react';
import { RequestType, SavedRequest } from '@shared/domain/request';

interface RequestPriceProps {
  request: SavedRequest,
}

const RequestPrice: React.FC<RequestPriceProps> = ({ request }) => {
  switch (request.type) {
    case RequestType.Market:
    case RequestType.Limit:
      return <>{request.requestedPrice}</>;
    case RequestType.Scaled:
      return <>{`${request.minPrice} - ${request.maxPrice}`}</>;
    default:
      return null;
  }
};

export default RequestPrice;
