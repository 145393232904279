import {
  SubscriptionOptions,
  SubscriptionCallback,
  SubscriptionError,
  SubscriptionStream,
  ExchangeSocketsClient,
} from '@shared/services/sockets/index';
import InternalError from '@shared/errors/InternalError';
import SocketClient from '@shared/services/sockets/socketClient';
import {
  BitfinexBookOrder,
  BitfinexPair,
  BitfinexPrecision,
  BitfinexChanelId,
  BitfinexTicker,
  cutBook,
  getSnapshot,
  insertDelta,
} from '@shared/domain/bitfinex';
import { MiniBook } from '@shared/domain/orderBook';
import createThrottle from '@shared/utils/throttle';
import { fetchPair } from '@shared/adapters/client/bitfinexClientAdapters';

export enum SendEvent {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export enum ChanelName {
  Ticker = 'ticker',
  Depth = 'book',
}

export enum ReceiveEvent {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
  Error = 'error',
}

export type SendEventMessage = {
  event: SendEvent,
  channel: ChanelName,
  symbol: BitfinexPair,
  prec?: BitfinexPrecision,
};

export type SubscribedEventMessage = {
  event: ReceiveEvent.Subscribed,
  channel: ChanelName,
  chanId: BitfinexChanelId,
  symbol: BitfinexPair,
};

export type UnsubscribedEventMessage = {
  event: ReceiveEvent.Unsubscribed,
  channel: ChanelName,
  chanId: BitfinexChanelId,
  symbol: BitfinexPair,
};

export type ErrorEventMessage = {
  event: ReceiveEvent.Error,
  msg: string,
  code: number,
  channel: ChanelName,
  symbol: BitfinexPair,
};

export type TickerMessage = [BitfinexChanelId, BitfinexTicker];
export type SnapshotMessage = [BitfinexChanelId, BitfinexBookOrder[]];
export type DeltaMessage = [BitfinexChanelId, BitfinexBookOrder];

export type Message = (
  DeltaMessage
  | SnapshotMessage
  | TickerMessage
  | ErrorEventMessage
  | UnsubscribedEventMessage
  | SubscribedEventMessage
  | SendEventMessage
);

export class BitfinexSpotClient implements ExchangeSocketsClient {
  protected socket: SocketClient<Message>;

  private books: { [key: string]: MiniBook } = {};

  private intervals: { [key: string]: Function } = {};

  private chanIds: { [chanId: BitfinexChanelId]: string } = {};

  constructor({ url = 'wss://api-pub.bitfinex.com/ws/2' } = {}) {
    this.socket = new SocketClient<Message>({
      url,
      handleMessage: this.handleMessage.bind(this),
    });
  }

  private handleSubscription(message: SubscribedEventMessage) {
    this.chanIds[message.chanId] = `${message.channel}${message.symbol}`;
  }

  private handleUnsubscription(message: UnsubscribedEventMessage) {
    delete this.chanIds[message.chanId];
  }

  private handleTicker(message: TickerMessage) {
    const key = this.chanIds[message[0]];

    this.socket.subscriptions[key]?.callbacks.map(
      (subscription) => subscription(Number(message[1][6])),
    );
  }

  private handleDepthSnapshot(message: SnapshotMessage) {
    const key = this.chanIds[message[0]];

    this.books[key] = getSnapshot(message[1]);

    this.socket.subscriptions[key]?.callbacks.map(
      (subscription) => subscription(this.books[key] && cutBook(this.books[key], 20)),
    );
  }

  private handleDepthUpdate(message: DeltaMessage) {
    const key = this.chanIds[message[0]];

    this.books[key] = insertDelta(this.books[key], message[1]);

    if (!this.intervals[key]) {
      this.intervals[key] = createThrottle(() => {
        this.socket.subscriptions[key]?.callbacks.map(
          (subscription) => subscription(this.books[key] && cutBook(this.books[key], 20)),
        );
      }, 1000);
    }

    this.intervals[key]();
  }

  private handleError(message: ErrorEventMessage) {
    const key = `${message.channel}${message.symbol}`;

    this.socket.errorCallback(key)(new Error(message.msg));
  }

  private handleMessage(message: Message) {
    if (Array.isArray(message)) {
      if (Array.isArray(message[1][0])) {
        this.handleDepthSnapshot(message as SnapshotMessage);
      } else if (message[1].length === 3) {
        this.handleDepthUpdate(message as DeltaMessage);
      } else if (message[1].length === 10) {
        this.handleTicker(message as TickerMessage);
      }
      return;
    }

    switch (message.event) {
      case ReceiveEvent.Subscribed:
        this.handleSubscription(message);
        break;
      case ReceiveEvent.Unsubscribed:
        this.handleUnsubscription(message);
        break;
      case ReceiveEvent.Error:
        this.handleError(message);
        break;
      default:
    }
  }

  public subscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.subscribeToOrderBooks(callback, options, onError);
        break;
      case SubscriptionStream.Ticker:
        this.subscribeToTicker(callback, options, onError);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  public unsubscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    switch (stream) {
      case SubscriptionStream.OrderBook:
        this.unsubscribeFromOrderBooks(callback, options);
        break;
      case SubscriptionStream.Ticker:
        this.unsubscribeFromTicker(callback, options);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  private async subscribeToOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const bitfinexPair = await fetchPair(base, counter);
    const key = `${ChanelName.Depth}${bitfinexPair}`;

    this.socket.subscribe(
      key,
      {
        event: SendEvent.Subscribe,
        channel: ChanelName.Depth,
        symbol: bitfinexPair,
        prec: 'P1',
      },
      callback,
      onError,
    );
  }

  private async unsubscribeFromOrderBooks(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const bitfinexPair = await fetchPair(base, counter);
    const key = `${ChanelName.Depth}${bitfinexPair}`;

    this.socket.unsubscribe(
      key,
      {
        event: SendEvent.Unsubscribe,
        channel: ChanelName.Depth,
        symbol: bitfinexPair,
      },
      callback,
    );
  }

  private async subscribeToTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const bitfinexPair = await fetchPair(base, counter);
    const key = `${ChanelName.Ticker}${bitfinexPair}`;

    this.socket.subscribe(
      key,
      {
        event: SendEvent.Subscribe,
        channel: ChanelName.Ticker,
        symbol: bitfinexPair,
      },
      callback,
      onError,
    );
  }

  private async unsubscribeFromTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const bitfinexPair = await fetchPair(base, counter);
    const key = `${ChanelName.Ticker}${bitfinexPair}`;

    this.socket.unsubscribe(
      key,
      {
        event: SendEvent.Unsubscribe,
        channel: ChanelName.Ticker,
        symbol: bitfinexPair,
      },
      callback,
    );
  }
}
