import * as React from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';

import ScrollTop from '@shared/components/ScrollTop';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import LoginPage from '../components/LoginPage';
import DashboardPage from '../components/DashboardPage';
import RequestsPage from '../components/RequestsPage';
import BalancesPage from '../components/BalancesPage';
import BalancePage from '../components/BalancePage';
import ProfilePage from '../components/ProfilePage';
import PageWrapper from '../components/PageWrapper';
import PasswordPage from '../components/PasswordPage';
import RequestPage from '../components/RequestPage';

const Router = () => (
  <BrowserRouter>
    <ScrollTop>
      <Routes>
        <Route
          path="login"
          element={(
            <UnauthenticatedRoute>
              <LoginPage />
            </UnauthenticatedRoute>
          )}
        />
        <Route
          path="password-reset/:token"
          element={(
            <UnauthenticatedRoute>
              <PasswordPage />
            </UnauthenticatedRoute>
          )}
        />
        <Route
          path="/"
          element={(
            <AuthenticatedRoute>
              <PageWrapper>
                <Outlet />
              </PageWrapper>
            </AuthenticatedRoute>
          )}
        >
          <Route index element={<DashboardPage />} />
          <Route path="requests">
            <Route index element={<RequestsPage />} />
            <Route path=":uuid" element={<RequestPage />} />
          </Route>
          <Route path="balances">
            <Route index element={<BalancesPage />} />
            <Route path=":currency" element={<BalancePage />} />
          </Route>
          <Route path="profile" element={<ProfilePage />} />
          <Route path="*" element={404} />
        </Route>
      </Routes>
    </ScrollTop>
  </BrowserRouter>
);

export default Router;
