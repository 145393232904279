import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface CrashAlertProps {
  title?: string,
  body?: string,
  refreshLabel?: string,
}

const CrashAlert: React.FC<CrashAlertProps> = ({
  title,
  body,
  refreshLabel,
}) => (
  <Dialog
    open
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <Typography id="alert-dialog-title" color="primary" variant="h3" gutterBottom>
        {title}
      </Typography>
      <DialogContentText id="alert-dialog-description" sx={{ whiteSpace: 'pre-line' }}>
        {body}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        fullWidth
        variant="contained"
        onClick={() => window.location.reload()}
        autoFocus
      >
        {refreshLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

CrashAlert.defaultProps = {
  title: 'Oops!',
  refreshLabel: 'Refresh',
  body: undefined,
};

export default CrashAlert;
