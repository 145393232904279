import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { FiltersStateProvider } from '@shared/services/context/filters';
import useFiltersStorage from '@operator/adapters/store/filtersStoreAdapters';
import BalanceDetails from './BalanceDetails';
import Requests from './Requests';

const BalancePage = () => {
  const { setValues, filterValues } = useFiltersStorage();
  const { currency } = useParams<{ currency: Currency }>();

  return (
    <>
      <Box sx={{ backgroundColor: '#121212' }}>
        <Container disableGutters maxWidth="md" sx={{ p: 2 }}>
          <BalanceDetails
            currency={currency!}
            exchangeAccount={filterValues?.exchangeAccount}
            setExchangeAccount={(exchangeAccount) => setValues?.({ exchangeAccount })}
          />
        </Container>
      </Box>
      <Container disableGutters maxWidth="md">
        <Requests />
      </Container>
    </>
  );
};

const BalancePageWithFilters = () => {
  const { currency } = useParams<{ currency: Currency }>();

  return (
    <FiltersStateProvider initValues={{ currency, exchangeAccount: undefined }} keys={['exchangeAccount']}>
      <BalancePage />
    </FiltersStateProvider>
  );
};

export default BalancePageWithFilters;
