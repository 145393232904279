import * as React from 'react';

import { Navigate } from 'react-router-dom';

interface UnauthenticatedRouteProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  children,
  isAuthenticated,
}) => (isAuthenticated ? <Navigate to="/" replace /> : <>{children}</>);

export default UnauthenticatedRoute;
