import { useEffect, useState } from 'react';
import formatDistanceToNowStrictLocalized, { FormatDistanceToNowStrictLocalizedProps } from '@shared/i18n/date/formatDistanceToNowStrict';

const useDistanceToNow = (args: FormatDistanceToNowStrictLocalizedProps) => {
  const [distance, setDistance] = useState(formatDistanceToNowStrictLocalized(args));

  useEffect(
    () => {
      const interval = setInterval(() => {
        setDistance(formatDistanceToNowStrictLocalized(args));
      }, 1000);
      return () => clearTimeout(interval);
    },
    [args],
  );

  return distance;
};

export default useDistanceToNow;
