import * as React from 'react';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import CheckIcon from '@mui/icons-material/Check';
import WavesOutlined from '@mui/icons-material/WavesOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import SwapVert from '@mui/icons-material/SwapVert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import getRandomInt from '@shared/utils/random';
import { ExchangeAccount } from '@shared/domain/account';
import NonFlickeringLoader from '@shared/components/NonFlickeringLoader';
import ReceivingDataError from '@shared/components/ReceivingDataError';
import BackButton from '@shared/components/BackButton';
import isPositionLong from '@shared/utils/isPositionLong';
import DetailsItem, { DetailsItemSkeleton } from '@shared/components/DetailsItem';
import { showTicker } from '@client/domain/balance';
import { RequestSide } from '@client/domain/request';
import { WizardStep } from '@client/domain/requestWizard';
import AccountSelect, { AccountSelectSkeleton } from '@client/components/AccountSelect';
import { useEquivalent } from '@client/services/context/settings';
import { useBalanceData } from '@client/application/useCases/balance';
import useRequestWizard, { useWizardAccounts } from '@client/application/useCases/requestWizard';
import Ticker from './CurrencyTicker';

export const BalanceDetailsSkeleton = () => (
  <>
    <Grid container alignItems="center">
      <Grid item xs={2}>
        <BackButton />
      </Grid>
      <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
        <AccountSelectSkeleton />
      </Grid>
      <Grid item xs={2} />
    </Grid>
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} py={3}>
      <Skeleton width={25} height={22} />
      <Skeleton width={getRandomInt(30, 100)} height={42} />
    </Box>
    <DetailsItemSkeleton />
    <DetailsItemSkeleton />
    <Stack direction="row" spacing={2} mt={2}>
      <Skeleton variant="rectangular" width="100%" height={36} />
      <Skeleton variant="rectangular" width="100%" height={36} />
    </Stack>
  </>
);

interface BalanceDetailsProps {
  currency: Currency,
  exchangeAccount?: ExchangeAccount,
  setExchangeAccount: (value?: ExchangeAccount) => void,
}

const BalanceDetailsComponent: React.FC<BalanceDetailsProps> = ({
  currency,
  exchangeAccount,
  setExchangeAccount,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const fromBalances = location.state?.from?.pathname === '/balances';
  const { balance, isLoading, error } = useBalanceData(currency);
  const { accounts } = useWizardAccounts();
  const { start } = useRequestWizard();
  const selectedAccount = exchangeAccount || 'all_accounts';
  const accountData = balance?.[selectedAccount] || {
    amount: '0',
    available: '0',
    equivalents: { },
    position: undefined,
  };
  const equivalent = useEquivalent(accountData.equivalents);
  const startWizard = useCallback((side: RequestSide) => () => {
    start(
      exchangeAccount ? WizardStep.Pair : WizardStep.Account,
      {
        [WizardStep.Side]: {
          side,
        },
        [WizardStep.Account]: {
          exchangeAccount,
        },
        [WizardStep.Pair]: {
          baseCurrency: currency,
        },
      },
    );
  }, [start, exchangeAccount]);
  const wizardDisabled = exchangeAccount && !accounts?.includes(exchangeAccount);
  const buyButton = (
    <Button
      color="success"
      sx={{ flexGrow: 1, width: '100%', color: 'success.light' }}
      variant="outlined"
      startIcon={<ArrowCircleUpIcon />}
      onClick={startWizard(RequestSide.Buy)}
      disabled={wizardDisabled}
    >
      {t('shared.requestSide.buy')}
    </Button>
  );
  const sellButton = (
    <Button
      color="error"
      sx={{ flexGrow: 1, width: '100%', color: 'error.light' }}
      variant="outlined"
      startIcon={<ArrowCircleDownIcon />}
      onClick={startWizard(RequestSide.Sell)}
      disabled={wizardDisabled}
    >
      {t('shared.requestSide.sell')}
    </Button>
  );

  return (
    <NonFlickeringLoader
      isLoading={isLoading}
      loadingElement={(
        <BalanceDetailsSkeleton />
      )}
    >
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <BackButton
            to={fromBalances ? `/balances${exchangeAccount ? `?exchangeAccount=${exchangeAccount}` : ''}` : undefined}
          />
        </Grid>
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
          <AccountSelect
            value={exchangeAccount}
            onChange={setExchangeAccount}
          />
        </Grid>
        <Grid item xs={2} />
      </Grid>
      {error ? (
        <ReceivingDataError>
          {error.message}
        </ReceivingDataError>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} py={3}>
            <Typography variant="subtitle2" color="grey.400">
              {balance?.currency}
            </Typography>
            <Typography variant="h4" align="center">
              {accountData.amount}
            </Typography>
          </Box>
          <DetailsItem
            icon={<CheckIcon fontSize="small" />}
            label={t('balanceDetails.available')}
            value={accountData.available}
          />
          <DetailsItem
            icon={<WavesOutlined fontSize="small" />}
            label={t('balanceDetails.equivalent')}
            value={equivalent}
          />
          {accountData?.position && (
            isPositionLong(accountData.position) ? (
              <DetailsItem
                icon={<TrendingUpIcon fontSize="small" />}
                label={t('shared.position.full.long')}
                color="success.light"
                value={accountData.position}
              />
            ) : (
              <DetailsItem
                icon={<TrendingDownIcon fontSize="small" />}
                label={t('shared.position.full.short')}
                color="error.light"
                value={accountData.position}
              />
            )
          )}
          {
            balance?.currency
            && showTicker(balance?.currency)
            && exchangeAccount
            && (
              <DetailsItem
                icon={<SwapVert fontSize="small" />}
                label={t('balanceDetails.market')}
                value={(
                  <Ticker
                    currency={balance?.currency}
                    exchangeAccount={exchangeAccount}
                  />
                )}
              />
            )
          }
          <Stack direction="row" spacing={2} mt={2}>
            {
              wizardDisabled ? (
                <Tooltip title={t('balanceDetails.wizardDisabled')}>
                  <Box sx={{ width: '100%' }}>
                    {buyButton}
                  </Box>
                </Tooltip>
              ) : buyButton
            }
            {
              wizardDisabled ? (
                <Tooltip title={t('balanceDetails.wizardDisabled')}>
                  <Box sx={{ width: '100%' }}>
                    {sellButton}
                  </Box>
                </Tooltip>
              ) : sellButton
            }
          </Stack>
        </>
      )}
    </NonFlickeringLoader>
  );
};

BalanceDetailsComponent.defaultProps = {
  exchangeAccount: undefined,
};

export default BalanceDetailsComponent;
