import { useQuery } from '@tanstack/react-query';
import { ProfileStorageService, AccountsStorageService } from '@client/application/ports';
import { useLoadProfileClient, useLoadAccountsClient } from '@client/adapters/client/profileClientAdapters';

export function useProfileStorage(): ProfileStorageService {
  const client = useLoadProfileClient();
  const {
    isLoading,
    data,
  } = useQuery(
    ['me'],
    () => client.load(),
  );

  return {
    profile: data,
    isLoading,
  };
}

export function useAccountsStorage(): AccountsStorageService {
  const client = useLoadAccountsClient();
  const {
    isLoading,
    data,
  } = useQuery(
    ['me', 'accounts'],
    () => client.load(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    accounts: data,
    isLoading,
  };
}
