export default function isEmpty(value: any) {
  return (
    Number.isNaN(value)
    || value === undefined
    || value === null
    || value === ''
    || value === false
    || (Array.isArray(value) && value.length === 0)
    || (typeof value === 'object' && Object.keys(value).length === 0)
  );
}
