import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const CommentInput = React.forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => {
  const { t } = useTranslation();

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <FormControl sx={{ width: '100%' }} component="fieldset">
      <FormLabel htmlFor="comment">{t('requestWizard.total.comment')}</FormLabel>
      <InputBase
        id="comment"
        multiline
        placeholder={t('requestWizard.total.addComment')}
        fullWidth
        ref={ref}
        {...props}
      />
    </FormControl>
  );
});

export default CommentInput;
