import * as Sentry from '@sentry/react';
import { extraErrorDataIntegration } from '@sentry/integrations';

export default function initSentry() {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    defaultIntegrations: false,
    integrations: [extraErrorDataIntegration()],
    attachStacktrace: true,
  });
}
