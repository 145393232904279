import { BalanceStorageService, TransferClientService } from '@client/application/ports';
import { useTransferClient } from '@client/adapters/client/transferClientAdapters';
import { useBalanceStorage } from '@client/adapters/store/balancesStoreAdapters';
import { ExchangeAccount } from '@shared/domain/account';

export function useTransfer() {
  const { makeTransfer, isLoading }: TransferClientService = useTransferClient();

  return {
    makeTransfer,
    isLoading,
  };
}

export function useWithdrawableAmount({
  asset,
  exchangeAccount,
}: {
  asset: Currency,
  exchangeAccount: ExchangeAccount,
}) {
  const storage: BalanceStorageService = useBalanceStorage(asset);

  return {
    withdrawable: storage.balance?.[exchangeAccount]?.withdrawable || '0',
    isLoading: storage.isLoading,
    error: storage.error,
  };
}
