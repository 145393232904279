import { MiniBook } from '@shared/domain/orderBook';
import { cutBook, coinbaseItemToBookItem, getCoinbasePair } from '@shared/domain/coinbase';
import { Method, parseBody } from '@shared/services/api';
import NetworkError from '@shared/errors/NetworkError';

const coinbaseApi = (endpoint: string, base: string = 'https://api.exchange.coinbase.com') => window
  .fetch(`${base}/${endpoint}`, {
    method: Method.Get,
  })
  .then(async (response) => {
    const body = await parseBody(response);

    if (response.ok) {
      return body;
    }

    throw new NetworkError(response.status, body?.errors);
  });

export type TickerResponse = {
  symbol: CurrencyPair,
  price: CurrencyAmount,
};

export const getTicker = (
  base: Currency,
  counter: Currency,
): Promise<TickerResponse> => (
  coinbaseApi(`products/${getCoinbasePair(base, counter)}/ticker`)
);

export const getOrderBook = ({
  base,
  counter,
}: {
  base: Currency,
  counter: Currency,
}): Promise<MiniBook> => (
  coinbaseApi(`products/${getCoinbasePair(base, counter)}/book?level=2`)
    .then((book) => (
      cutBook({
        asks: book.asks.map(coinbaseItemToBookItem),
        bids: book.bids.map(coinbaseItemToBookItem),
      }, 20)
    ))
);
