import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import EditIcon from '@mui/icons-material/Edit';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { ChipStatus } from '@shared/components/RequestStatus';
import { sideColors } from '@shared/components/RequestSide';
import Currency from '@shared/components/Currency';
import Type from '@shared/components/RequestType';
import Progress from '@shared/components/Progress';
import RequestPrice from '@shared/components/RequestPrice';
import ExchangeAccountLabel from '@shared/components/ExchangeAccount';
import BackButton from '@shared/components/BackButton';
import DateComponent from '@shared/components/Date';
import format from '@shared/i18n/date/format';
import getRandomInt from '@shared/utils/random';
import DeadlineForm from '@shared/components/DeadlineForm';
import CommentForm from '@shared/components/CommentForm';
import DeadlineComponent, { showDeadlineComponent } from '@shared/components/Deadline';
import DistanceToNow from '@shared/components/DistanceToNow';
import DetailsItem from '@shared/components/DetailsItem';
import { cancelAllowed, RequestStatus, SavedRequest } from '@shared/domain/request';
import { useAddRequestComment, useEditRequestDeadline } from '@client/application/useCases/request';

interface PaperItemProps {
  primary: React.ReactNode,
  secondary: React.ReactNode,
}

export const PaperItem: React.FC<PaperItemProps & TypographyProps> = ({
  primary,
  secondary,
  ...props
}) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <>
    <Typography
      variant="caption"
      color="text.secondary"
      {...props}
    >
      {secondary}
    </Typography>
    {primary}
  </>
);

export const RequestDetailsSkeleton = () => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <BackButton />
      <Skeleton sx={{ ml: 'auto' }} width={80} />
    </Box>
    <Box sx={{ mt: 1 }}>
      <Typography variant="h5">
        <Skeleton width={getRandomInt(150, 250)} />
      </Typography>
      <Typography variant="h5">
        <Skeleton width={getRandomInt(150, 250)} />
      </Typography>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
      <Skeleton variant="rectangular" width={getRandomInt(50, 90)} height={24} sx={{ mr: 1 }} />
      <Typography
        variant="caption"
        color="text.secondary"
      >
        <Skeleton width={160} />
      </Typography>
    </Box>
    <Paper sx={{
      display: 'flex',
      p: 2,
      mt: 3,
    }}
    >
      <Box flexGrow="1">
        <PaperItem
          secondary={<Skeleton width={105} />}
          primary={<Skeleton width={getRandomInt(30, 50)} />}
        />
      </Box>
      <Box flexGrow="1">
        <PaperItem
          secondary={<Skeleton width={27} />}
          primary={<Skeleton width={getRandomInt(30, 50)} />}
        />
      </Box>
    </Paper>
    <Box mt={2}>
      <DetailsItem
        icon={<Skeleton variant="circular" width={20} height={20} />}
        label={<Skeleton width={getRandomInt(90, 120)} />}
        value={<Skeleton width={150} />}
      />
      <DetailsItem
        icon={<Skeleton variant="circular" width={20} height={20} />}
        label={<Skeleton width={getRandomInt(90, 120)} />}
        value={<Skeleton width={getRandomInt(70, 120)} />}
      />
      <DetailsItem
        icon={<Skeleton variant="circular" width={20} height={20} />}
        label={<Skeleton width={getRandomInt(90, 120)} />}
        value={<Skeleton width={getRandomInt(70, 120)} />}
      />
    </Box>
  </>
);

interface RequestDetailsProps {
  request: SavedRequest,
  cancelRequest: () => void,
}

const RequestDetails: React.FC<RequestDetailsProps> = ({ request, cancelRequest }) => {
  const { t } = useTranslation();
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [showDeadlineForm, setShowDeadlineForm] = useState(false);
  const { addComment, isLoading: commentLoading } = useAddRequestComment(request.uuid);
  const { editDeadline, isLoading: deadlineLoading } = useEditRequestDeadline(request.uuid);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <BackButton />
        <Typography
          variant="overline"
          sx={{
            textTransform: 'uppercase',
            color: 'grey.400',
            ml: 'auto',
          }}
        >
          {t('request.title', { number: request.number })}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography
          variant="h5"
        >
          <Typography
            variant="inherit"
            component="span"
            mr={1}
            color={sideColors[request.side]}
          >
            {t(`shared.requestSide.${request.side}`)}
          </Typography>
          {request.requestedBase}
          &nbsp;
          {request.baseCurrency}
        </Typography>
        <Typography
          variant="h5"
          color="grey.400"
        >
          @&nbsp;
          <RequestPrice request={request} />
          &nbsp;
          {request.counterCurrency}
        </Typography>
        {
          request.commonCurrency && (
            <Typography
              variant="h5"
              color="grey.400"
            >
              via&nbsp;
              {request.commonCurrency}
            </Typography>
          )
        }
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
        <ChipStatus value={request.status} sx={{ mr: 2 }} />
        {request.status === RequestStatus.InProgress ? (
          <Progress value={request.progress} sx={{ width: 80 }} />
        ) : (
          <Typography
            variant="caption"
            color="text.secondary"
          >
            {format(new Date(request.updatedAt), { dateOnly: true })}
            &nbsp;(
            <DistanceToNow date={new Date(request.updatedAt)} options={{ addSuffix: true }} />
            )
          </Typography>
        )}
        {cancelAllowed(request) && (
          <Button
            sx={{ ml: 'auto', p: '0 8px' }}
            variant="outlined"
            color="error"
            onClick={cancelRequest}
            size="small"
            id="open-cancel-request-modal"
          >
            {t('shared.buttons.cancel')}
          </Button>
        )}
      </Box>
      <Paper sx={{
        display: 'flex',
        p: 2,
        mt: 3,
      }}
      >
        <Box flexGrow="1">
          <PaperItem
            secondary={t('shared.exchangeAccount.title')}
            primary={<ExchangeAccountLabel value={request.exchangeAccount} variant="body1" />}
          />
        </Box>
        <Box flexGrow="1">
          <PaperItem
            secondary={t('request.type')}
            primary={<Type request={request} />}
          />
        </Box>
      </Paper>
      <Box my={2}>
        <DetailsItem
          icon={<EventAvailableIcon fontSize="small" />}
          label={t('request.created')}
          value={<DateComponent date={request.createdAt} align="right" />}
        />
        <DetailsItem
          icon={<CurrencyBitcoinIcon fontSize="small" />}
          label={t('request.baseAmount')}
          value={<Currency amount={request.requestedBase} currency={request.baseCurrency} />}
        />
        <DetailsItem
          icon={<LocalOfferIcon fontSize="small" />}
          label={t('request.requestedPrice')}
          value={(
            <Currency
              amount={<RequestPrice request={request} />}
              currency={request.counterCurrency}
            />
          )}
        />
        {showDeadlineComponent(request) && (
          <DetailsItem
            icon={<AccessAlarmIcon fontSize="small" />}
            label={t('request.deadline')}
            value={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DeadlineComponent request={request} onEdit={() => setShowDeadlineForm(true)} />
                {showDeadlineForm && (
                  <DeadlineForm
                    onSubmit={editDeadline}
                    onCancel={() => setShowDeadlineForm(false)}
                    isLoading={deadlineLoading}
                    defaultValue={request.deadline}
                    deadlineLabel={t('request.deadline')}
                    submitLabel={t('request.editDeadline.submit')}
                    cancelLabel={t('request.editDeadline.cancel')}
                  />
                )}
              </Box>
            )}
          />
        )}
        {
          request.commonCurrency && (
            <DetailsItem
              icon={<ChangeHistoryIcon fontSize="small" />}
              label={t('request.commonCurrency')}
              value={(
                <Typography color="grey.400" variant="body2">
                  {request.commonCurrency}
                </Typography>
              )}
            />
          )
        }
        {request.status !== RequestStatus.New && (
          <>
            <Divider sx={{ my: 1 }} />
            <DetailsItem
              icon={<EventRepeatIcon fontSize="small" />}
              label={t('request.updated')}
              value={<DateComponent date={request.updatedAt} align="right" />}
            />
            <DetailsItem
              icon={<CheckCircleOutlineIcon fontSize="small" />}
              label={t('request.executedBase')}
              value={<Currency amount={request.executedBase} currency={request.baseCurrency} />}
            />
            <DetailsItem
              icon={<AttachMoneyIcon fontSize="small" />}
              label={t('request.executedCounter')}
              value={(
                <Currency
                  amount={request.executedCounter}
                  currency={request.counterCurrency}
                />
              )}
            />
          </>
        )}
        {request.averagePrice && (
          <DetailsItem
            icon={<LocalOfferIcon fontSize="small" />}
            label={t('request.averagePrice')}
            value={<Currency amount={request.averagePrice} currency={request.counterCurrency} />}
          />
        )}
      </Box>
      {request.warning && (
        <Alert variant="outlined" severity="warning">
          {request.warning}
        </Alert>
      )}
      {showCommentForm ? (
        <Card sx={{ p: 2, pt: 1 }}>
          <CommentForm
            onSubmit={addComment}
            onCancel={() => setShowCommentForm(false)}
            isLoading={commentLoading}
            defaultValue={request.comment?.text}
            commentLabel={t('request.comment')}
            submitLabel={t('request.editComment.submit')}
            cancelLabel={t('request.editComment.cancel')}
          />
        </Card>
      ) : (
        <Card>
          <CardHeader
            action={(
              <IconButton size="small" onClick={() => setShowCommentForm(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            title={t('request.comment')}
            titleTypographyProps={{
              variant: 'caption',
              color: 'text.secondary',
            }}
          />
          <Box p={2} pt={0}>
            {request.comment?.text && (
              <Typography whiteSpace="pre-wrap">{request.comment.text}</Typography>
            )}
            {request.comment?.editedBy && request.comment?.lastUpdated && (
              <Typography variant="caption" color="text.secondary">
                {t('request.edited', {
                  name: request.comment.editedBy.name,
                  date: format(new Date(request.comment.lastUpdated)),
                })}
              </Typography>
            )}
          </Box>
        </Card>
      )}
    </>
  );
};

export default RequestDetails;
