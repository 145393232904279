import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import LockIcon from '@mui/icons-material/LockOutlined';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import Box from '@mui/material/Box';
import { RequestType } from '@client/domain/request';
import { useCost, useCounterValue } from '@client/application/useCases/requestWizard';
import { typeIsDisabled, WizardData } from '@client/domain/requestWizard';
import { CurrencyType } from '@client/domain/currency';
import CurrencyAmountInput from './CurrencyAmountInput';
import { BaseCost, CounterCost, CommonAvailable } from './Cost';

const requestTypeIcons = {
  [RequestType.Limit]: <LockIcon />,
  [RequestType.Scaled]: <FormatLineSpacingIcon />,
  [RequestType.Market]: <LocalGroceryStoreIcon />,
};

const SelectRequestTotal: React.FC<{
  request: Partial<WizardData>,
  onGoBack: (input: CurrencyType) => void,
}> = ({
  request,
  onGoBack,
}) => {
  const {
    control,
    formState,
    watch,
    setValue,
    trigger,
  } = useFormContext<WizardData>();
  const formValues = watch();
  const { errors } = formState;
  const { t } = useTranslation();
  const { cost, isLoading } = useCost(useMemo(() => ({
    ...request,
    ...formValues,
  }), [request, formValues]));
  const counterValue = useCounterValue(useMemo(() => ({
    ...request,
    ...formValues,
  }), [request, formValues]));
  const setBase = (value: CurrencyAmount) => {
    setValue('requestedBase', value);
    trigger('requestedBase');
  };

  if (!request.counterCurrency) return null;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      <Controller
        defaultValue=""
        control={control}
        name="requestedBase"
        render={({ field }) => (
          <CurrencyAmountInput
            autoFocus
            label="Base"
            currency={request.baseCurrency!}
            selectCurrency={() => onGoBack(CurrencyType.Base)}
            error={errors.requestedBase}
            placeholder={t('requestWizard.total.enterAmount')}
            {...field}
          />
        )}
      />
      <BaseCost request={request} cost={cost} isLoading={isLoading} onClickMax={setBase} />
      <CurrencyAmountInput
        calculated
        name="requestedCounter"
        label="Counter"
        currency={request.counterCurrency}
        value={counterValue || ''}
        selectCurrency={() => onGoBack(CurrencyType.Counter)}
        error={errors.requestedCounter}
        placeholder="0"
      />
      <CounterCost request={request} cost={cost} isLoading={isLoading} />
      {request.commonCurrency && (
        <CurrencyAmountInput
          label="Common"
          currency={request.commonCurrency}
          selectCurrency={() => onGoBack(CurrencyType.Common)}
          error={errors.commonCurrency}
        >
          <CommonAvailable request={request} cost={cost} isLoading={isLoading} />
        </CurrencyAmountInput>
      )}
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, onBlur, value } }) => (
          <FormControl sx={{ width: '100%' }} component="fieldset" onBlur={onBlur}>
            <FormLabel>{t('requestWizard.total.type')}</FormLabel>
            <Box sx={{ display: 'flex', mx: -1, my: 2 }}>
              {[RequestType.Limit, RequestType.Scaled, RequestType.Market].map((type) => (
                <Chip
                  key={type}
                  clickable
                  sx={{ flexGrow: 1, mx: 1 }}
                  variant="outlined"
                  icon={requestTypeIcons[type]}
                  label={t(`shared.requestType.${type}`)}
                  color={value === type ? 'primary' : 'default'}
                  onClick={() => onChange(type)}
                  disabled={typeIsDisabled(type, request)}
                />
              ))}
            </Box>
          </FormControl>
        )}
      />
    </>
  );
};

export default SelectRequestTotal;
