import format from '@shared/i18n/date/format';
import formatDistanceToNowStrict from '@shared/i18n/date/formatDistanceToNowStrict';
import { DeadlineUrgency, getDeadlineUrgency, Request } from '@shared/domain/request';

const deadline = (request: Request) => {
  const date = request.deadline;
  const urgency = getDeadlineUrgency(request);
  const showDate = urgency === DeadlineUrgency.Regular;

  if (!date) return undefined;

  return showDate
    ? format(new Date(date), { dateOnly: true })
    : formatDistanceToNowStrict({
      date: new Date(date),
      options: { addSuffix: true, unit: 'hour' },
    });
};

export default deadline;
