import { UseFormSetError } from 'react-hook-form';
import AuthorizationError from '@shared/errors/AuthorizationError';
import ValidationError from '@shared/errors/ValidationError';

const parseRequestError = ({
  error,
  setError,
  catchAuth,
}: {
  error?: any,
  setError: UseFormSetError<any>,
  catchAuth?: boolean,
}) => {
  if (error instanceof ValidationError || (catchAuth && error instanceof AuthorizationError)) {
    if (error.reasons) {
      error.reasons.forEach((reason) => setError(
        reason.property || 'general',
        { type: reason.code, message: reason.message },
      ));
    } else {
      setError(
        'general',
        { message: error.message },
      );
    }
  } else if (error) {
    throw error;
  }
};

export default parseRequestError;
