import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReceivingDataError from '@shared/components/ReceivingDataError';
import { FiltersStateProvider } from '@shared/services/context/filters';
import useDashboardData from '@client/application/useCases/dashboardData';
import useTotalBalances from '@client/application/useCases/totalBalances';
import useRequestWizard from '@client/application/useCases/requestWizard';
import { RequestStatus } from '@client/domain/request';
import InfiniteRequestsTable from '@shared/components/InfiniteRequestsTable';
import BalancesTable from './BalancesTable';

const DashboardPageWithoutState = () => {
  const { t } = useTranslation();

  const {
    balances,
    balancesLoading,
    balancesError,
    requests,
    requestsLoading,
    requestsLoadMore,
    requestsLoadingMore,
    requestsError,
  } = useDashboardData();
  const { totalBalances } = useTotalBalances();
  const { start } = useRequestWizard();

  return (
    <Container disableGutters maxWidth="md">
      <Box py={2}>
        <Box sx={{ display: 'flex' }} mb={2} px={2}>
          <Typography mr={1}>
            {t('dashboard.balances')}
          </Typography>
          <Typography color="text.secondary">
            {balances?.total}
          </Typography>
        </Box>
        {balancesError ? (
          <Box px={2}>
            <ReceivingDataError>
              {balancesError.message}
            </ReceivingDataError>
          </Box>
        ) : (
          <BalancesTable balances={balances} isLoading={balancesLoading} />
        )}
      </Box>
      <Box sx={{ display: 'flex' }} p={2}>
        <Typography mr={1}>
          {t('dashboard.openRequests')}
        </Typography>
        <Typography color="text.secondary">
          {requests?.total}
        </Typography>
      </Box>
      {requestsError ? (
        <ReceivingDataError>
          {requestsError.message}
        </ReceivingDataError>
      ) : (
        <InfiniteRequestsTable
          requests={requests}
          balances={totalBalances}
          isLoading={requestsLoading}
          loadMore={requestsLoadMore}
          startWizard={start}
          isLoadingMore={requestsLoadingMore}
          noRequestsTitle={t('dashboard.requests.noData.title')}
          noBalancesTitle={t('dashboard.requests.noData.description')}
          createNewRequestTitle={t('dashboard.requests.noData.action')}
        />
      )}
    </Container>
  );
};

const DashboardPage = () => (
  <FiltersStateProvider initValues={{ statuses: [RequestStatus.New, RequestStatus.InProgress] }}>
    <DashboardPageWithoutState />
  </FiltersStateProvider>
);

export default DashboardPage;
