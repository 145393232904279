import * as React from 'react';
import Box from '@mui/material/Box';

import useBalances from '@client/application/useCases/balances';
import useFiltersStorage from '@client/adapters/store/filtersStoreAdapters';
import { useSettingsState } from '@client/services/context/settings';
import SearchInput from './SearchInput';
import BalancesList from './BalancesList';
import HideSmallBalances from './HideSmallBalances';

const Balances: React.FC = () => {
  const { setValues, filterValues } = useFiltersStorage();
  const { balances, isLoading } = useBalances();
  const { settings, changeHideSmallBalances } = useSettingsState();

  return (
    <>
      <SearchInput
        value={filterValues?.search || ''}
        onChange={(value) => setValues?.({ search: value })}
        total={balances?.total}
      />
      <Box ml={1} mt={1} px={2} py={1}>
        <HideSmallBalances value={settings.hideSmallBalances} onChange={changeHideSmallBalances} />
      </Box>
      <BalancesList
        balances={balances}
        isLoading={isLoading}
        isSearching={!!filterValues?.search}
      />
    </>
  );
};

Balances.defaultProps = {
  balances: undefined,
};

export default Balances;
