import { MiniBookOrder, MiniBook } from '@shared/domain/orderBook';
import withPrecision from '@shared/utils/withPrecision';

export interface MiniBookOrderWithTotal extends MiniBookOrder {
  total: number,
}

export const calculateOrdersTotal = (orders: MiniBookOrder[]) => (
  orders.reduce((arrayWithTotal, item, index) => {
    const prev = arrayWithTotal[index - 1];
    const prevTotal = prev ? Number(prev.total) : 0;

    arrayWithTotal.push({
      ...item,
      amount: withPrecision(item.amount, 8),
      total: withPrecision(prevTotal + item.amount, 8),
    });
    return arrayWithTotal;
  }, [] as MiniBookOrderWithTotal[])
);

export const calculateOrdersFill = (orders: MiniBookOrderWithTotal[], maxTotal: number) => (
  orders.map((order) => ({
    ...order,
    fill: ((Number(order.total) / maxTotal) * 100).toFixed(1),
  }))
);

export const calculateBookTotal = (book: MiniBook) => {
  const asksWithTotal = calculateOrdersTotal(book.asks);
  const bidsWithTotal = calculateOrdersTotal(book.bids);

  const lastAsk = asksWithTotal[asksWithTotal.length - 1];
  const asksTotal = lastAsk ? Number(lastAsk.total) : 0;

  const lastBids = bidsWithTotal[bidsWithTotal.length - 1];
  const bidsTotal = lastBids ? Number(lastBids.total) : 0;

  const maxTotal = Math.max(bidsTotal, asksTotal);

  return {
    asks: calculateOrdersFill(asksWithTotal, maxTotal),
    bids: calculateOrdersFill(bidsWithTotal, maxTotal),
  };
};
