import { ExchangeAccount } from '@shared/domain/account';
import useTotalBalancesStorage from '@client/adapters/store/totalBalancesStoreAdapters';
import { useSettingsState as useSettingsStorage } from '@client/services/context/settings';
import useFiltersStorage from '@client/adapters/store/filtersStoreAdapters';
import { TotalBalancesStorageService, SettingsStorageService, FiltersStorageService } from '../ports';

export default function useTotalBalances() {
  const { filterValues }: FiltersStorageService = useFiltersStorage();
  const totalBalancesStorage: TotalBalancesStorageService = useTotalBalancesStorage();
  const { settings } : SettingsStorageService = useSettingsStorage();
  const exchangeAccount = filterValues?.exchangeAccount as ExchangeAccount || 'all_accounts';
  let totalBalances;

  // If loading is finished then set some value, otherwise undefined
  if (!totalBalancesStorage.isLoading) {
    totalBalances = totalBalancesStorage.total?.[exchangeAccount]?.[settings.equivalentCurrency] || '0';
  }

  return {
    totalBalances,
  };
}
