import { ExchangeAccount } from '@shared/domain/account';

export const transferAccounts = [ExchangeAccount.BinanceUSDM, ExchangeAccount.BinanceSpot];

export type Transfer = {
  asset: Currency,
  amount: CurrencyAmount,
  from: ExchangeAccount,
  to: ExchangeAccount,
};
