import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import usePrevious from '@shared/hooks/usePrevious';

interface TickerProps extends Omit<TypographyProps<'div'>, 'onClick'> {
  title?: string,
  ticker?: number,
  counter?: Currency,
  isLoading?: boolean,
  error?: any,
  onClick?: (value: number) => void,
}

export const TickerSkeleton: React.FC = () => (
  <Skeleton sx={{ display: 'inline-block' }} width={70} height={24} />
);

export const Ticker: React.FC<TickerProps> = ({
  title, ticker, counter, isLoading, onClick, error, ...props
}) => {
  const previousTicker = usePrevious<number | undefined>(ticker);
  const theme = useTheme();

  let color;

  if (!previousTicker || !ticker || previousTicker === ticker) {
    color = theme.palette.grey['500'];
  } else if (previousTicker > ticker) {
    color = theme.palette.error.light;
  } else {
    color = theme.palette.success.light;
  }

  if (error) {
    return (
      <Typography
        align="right"
        color="error.light"
      >
        {error}
      </Typography>
    );
  }

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Typography
      onClick={ticker && onClick ? () => onClick(ticker) : undefined}
      variant="subtitle1"
      component="div"
      color="grey.500"
      fontSize="0.875rem"
      {...props}
    >
      {title}
      {isLoading ? (
        <TickerSkeleton />
      ) : (
        <span style={{ color }} id="ticker">
          {ticker}
        </span>
      )}
      &nbsp;
      {counter}
    </Typography>
  );
};

Ticker.defaultProps = {
  title: undefined,
  ticker: undefined,
  counter: undefined,
  isLoading: undefined,
  error: undefined,
  onClick: undefined,
};

export default React.memo(Ticker);
