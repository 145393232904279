import { CurrencyClientService } from '@client/application/ports';
import { getCurrencies } from '@client/services/api/currencies';

export default function useCurrenciesClient(): CurrencyClientService {
  return {
    async search(props) {
      const { data } = await getCurrencies(props);
      const currencies = data?.map((currency: Currency) => ({ currency })) || [];

      return {
        total: currencies.length,
        data: currencies,
      };
    },
  };
}
