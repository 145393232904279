import { useRequestsStorage } from '@client/adapters/store/requestsStoreAdapters';
import { useBalancesStorage } from '@client/adapters/store/balancesStoreAdapters';
import { useSettingsState } from '@client/services/context/settings';
import { RequestsStorageService, BalancesStorageService } from '../ports';

export default function useDashboardData() {
  const requestsStorage: RequestsStorageService = useRequestsStorage();
  const { settings } = useSettingsState();
  const balancesStorage: BalancesStorageService = useBalancesStorage({
    hideSmallBalances: true,
    smallBalancesEquivalent: settings.smallBalancesEquivalent,
    equivalentCurrency: settings.equivalentCurrency,
  });

  return {
    balances: balancesStorage.balances,
    balancesLoading: balancesStorage.isLoading,
    balancesError: balancesStorage.error,
    requests: requestsStorage.requests,
    requestsLoading: requestsStorage.isLoading,
    requestsLoadMore: requestsStorage.loadMore,
    requestsLoadingMore: requestsStorage.isLoadingMore,
    requestsError: requestsStorage.error,
  };
}
