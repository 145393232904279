import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { logError, logWarning } from '@shared/services/logs';
import AuthorizationError from '@shared/errors/AuthorizationError';
import InternalError from '@shared/errors/InternalError';
import NetworkError from '@shared/errors/NetworkError';

interface EventErrorBoundaryProps {
  children: React.ReactNode;
  getMessage: (error: Error) => string,
  logOut: () => void;
}

const EventErrorBoundary: React.FC<EventErrorBoundaryProps> = ({
  children,
  getMessage,
  logOut,
}) => {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const hideError = React.useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  const processError = React.useCallback((error: any) => {
    if (error instanceof AuthorizationError) {
      logOut();
    } else if (error instanceof NetworkError) {
      logError(error);
      setErrorMessage(`${getMessage(error)} ${error.reasons?.map(({ message }) => message).join(', ') || error.message}`);
    } else if (error instanceof InternalError) {
      logError(error);
      setErrorMessage(`${getMessage(error)} ${error.message}`);
    } else {
      logWarning(error);
    }
  }, []);

  const onUnhandledRejection = React.useCallback(
    ({ reason }: PromiseRejectionEvent) => processError(reason),
    [],
  );

  const onUnhandledError = React.useCallback(
    ({ error }: ErrorEvent) => processError(error),
    [],
  );

  // TODO: find a way to initiate it outside of render function
  // seEffect doesn't work as it executes after useQuery's initialization
  queryClient.setDefaultOptions({
    queries: {
      onError: processError,
      retry: (failureCount, error) => {
        // Don't retry if auth error, otherwise try 2 times
        if (error instanceof AuthorizationError) return false;
        if (failureCount === 2) return false;
        return true;
      },
      staleTime: 1000,
    },
  });

  React.useEffect(() => {
    // Handle all uncached promises;
    window.addEventListener('unhandledrejection', onUnhandledRejection);
    // Handle all uncached errors;
    window.addEventListener('error', onUnhandledError);

    return () => {
      window.removeEventListener('unhandledrejection', onUnhandledRejection);
      window.removeEventListener('error', onUnhandledError);
    };
  }, []);

  return (
    <>
      <Snackbar
        open={!!errorMessage}
        onClose={hideError}
      >
        <Alert onClose={hideError} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      {children}
    </>
  );
};

export default EventErrorBoundary;
