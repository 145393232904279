import { setLocale } from 'yup';
import i18n from 'i18next';

setLocale({
  mixed: {
    // @ts-ignore
    notOneOf: ({ path, resolved }) => i18n.t('invalid.notOneOf', { property: path, options: resolved }),
    // @ts-ignore
    oneOf: ({ path, resolved }) => i18n.t('invalid.oneOf', { property: path, options: resolved }),
    required: ({ path }) => i18n.t('invalid.required', { property: path }),
    notType: ({ path, type }) => i18n.t('invalid.type', { property: path, options: type }),
  },
  string: {
    min: ({ min, path }) => i18n.t('invalid.min', { property: path, options: min }),
    max: ({ max, path }) => i18n.t('invalid.max', { property: path, options: max }),
  },
  number: {
    moreThan: ({ more, path }) => i18n.t('invalid.gt', { property: path, options: more }),
    lessThan: ({ less, path }) => i18n.t('invalid.lt', { property: path, options: less }),
    min: ({ min, path }) => i18n.t('invalid.gte', { property: path, options: min }),
    max: ({ max, path }) => i18n.t('invalid.lte', { property: path, options: max }),
  },
});
