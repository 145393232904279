import * as React from 'react';
import { logFatal } from '@shared/services/logs';
import CrashAlert from './CrashAlert';

interface Props {
  children: React.ReactNode;
  title?: string,
  getMessage: (errorId?: string) => string,
  refreshLabel?: string,
}

interface State {
  error: any;
  errorId?: string;
}

// Error catching possible only in React Class component
class RenderingErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: any;

  constructor(props: React.ComponentProps<any>) {
    super(props);
    this.state = {
      error: undefined,
      errorId: undefined,
    };
  }

  // Set rendering error to state
  public static getDerivedStateFromError(error: any) {
    return { error };
  }

  // Log rendering error and save errorId
  componentDidCatch(error: any, info: React.ErrorInfo) {
    const errorWithStack = error instanceof Error ? error : new Error();
    errorWithStack.stack = info.componentStack;
    this.setState({ errorId: logFatal(errorWithStack) });
  }

  render() {
    const { error, errorId } = this.state;
    const {
      children,
      title,
      getMessage,
      refreshLabel,
    } = this.props;

    // Don't render children if render error happened
    return error ? (
      <CrashAlert
        title={title}
        refreshLabel={refreshLabel}
        body={getMessage(errorId)}
      />
    ) : children;
  }
}

RenderingErrorBoundary.defaultProps = {
  refreshLabel: undefined,
  title: undefined,
};

export default RenderingErrorBoundary;
