import { useMutation, useQueryClient } from '@tanstack/react-query';
import getDecimalString from '@shared/services/getDecimalString';
import {
  CancelRequestClientService,
  LoadRequestClientService,
  RequestsClientService,
  CostClientService,
  AddRequestCommentClientService,
  EditRequestDeadlineClientService,
} from '@client/application/ports';
import {
  cancelRequest,
  getRequest,
  getRequests,
  saveRequest,
  getRequestCost,
  addRequestComment,
  editRequestDeadline,
} from '@client/services/api/requests';
import { SavedRequest } from '@client/domain/request';
import { WizardData } from '@client/domain/requestWizard';

export function useRequestsClient(): RequestsClientService {
  const queryClient = useQueryClient();

  const saveRequestMutation = useMutation(
    (request: Partial<WizardData>) => saveRequest({
      ...request,
      requestedBase: getDecimalString(request.requestedBase),
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['requests']);
        queryClient.invalidateQueries(['balances']);
      },
    },
  );

  return {
    save: saveRequestMutation.mutateAsync,
    isSaving: saveRequestMutation.isLoading,
    load: ({ filters, offset = 0, limit } = {}) => getRequests({ filters, offset, limit }),
  };
}

export function useLoadRequestClient(): LoadRequestClientService {
  return {
    load: getRequest,
  };
}

export function useCancelRequestClient(): CancelRequestClientService {
  const queryClient = useQueryClient();

  const cancelRequestMutation = useMutation(cancelRequest, {
    onSuccess: (request: SavedRequest) => {
      queryClient.setQueryData(['request', request.uuid], request);
      queryClient.invalidateQueries(['requests']);
    },
  });

  return {
    cancel: cancelRequestMutation.mutateAsync,
    isLoading: cancelRequestMutation.isLoading,
  };
}

export function useAddRequestCommentClient(): AddRequestCommentClientService {
  const queryClient = useQueryClient();

  const addCommentMutation = useMutation(addRequestComment, {
    onSuccess: (request: SavedRequest) => {
      queryClient.setQueryData(['request', request.uuid], request);
      queryClient.invalidateQueries(['requests']);
    },
  });

  return {
    addComment: addCommentMutation.mutateAsync,
    isLoading: addCommentMutation.isLoading,
  };
}

export function useEditRequestDeadlineClient(): EditRequestDeadlineClientService {
  const queryClient = useQueryClient();

  const editDeadlineMutation = useMutation(editRequestDeadline, {
    onSuccess: (request: SavedRequest) => {
      queryClient.setQueryData(['request', request.uuid], request);
      queryClient.invalidateQueries(['requests']);
    },
  });

  return {
    editDeadline: editDeadlineMutation.mutateAsync,
    isLoading: editDeadlineMutation.isLoading,
  };
}

export function useCostClient(): CostClientService {
  return {
    async load(props) {
      const request = {
        exchange_account: props.exchangeAccount,
        side: props.side,
        base: props.baseCurrency,
        counter: props.counterCurrency,
        common: props.commonCurrency,
        price: props.price,
        requested: getDecimalString(props.requestedBase || 0),
      };
      return getRequestCost(request);
    },
  };
}
