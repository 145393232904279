import * as yup from 'yup';
import { RequestType } from '@client/domain/request';

const validation = yup.object({
  requestedBase: yup.number()
    .moreThan(0)
    .max(yup.ref('$maxBase'))
    .required(),
  type: yup.mixed<RequestType>()
    .oneOf([RequestType.Market, RequestType.Limit, RequestType.Scaled])
    .required(),
  requestedPrice: yup.number()
    .when('type', {
      is: RequestType.Limit,
      then: (schema) => schema.moreThan(0).required(),
    }),
  minPrice: yup.number()
    .when('type', {
      is: RequestType.Scaled,
      then: (schema) => schema.moreThan(0).required(),
    }),
  maxPrice: yup.number()
    .when('type', {
      is: RequestType.Scaled,
      then: (schema) => schema.moreThan(0).min(yup.ref('minPrice')).required(),
    }),
});

export default validation;
